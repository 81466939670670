import { Mail_01 } from '@recruitrobin/robin-theme/web-icons';
import { Badge, Row } from 'components/ui/atoms';
import { useCallback, useContext, useState } from 'react';
import { SidebarOverlayFixedContext } from 'shared/contexts/SidebarOverlayFixedContext';
import { useGetCampaignNewRepliesQuery } from 'shared/hooks/useGetCampaignNewRepliesQuery';
import { useStyles } from './NewMessageReplies.styles';
import { InboxConversationPreview } from 'components/ui/organisms/CentralInbox/InboxConversationPreview';
import { useFeatureToggles } from 'shared/hooks';

export const NewMessageReplies = () => {
  const { data: newReplies } = useGetCampaignNewRepliesQuery();
  const { setIsOpen } = useContext(SidebarOverlayFixedContext);
  const classes = useStyles();
  const { isFeatureToggleEnabled } = useFeatureToggles();
  const isCentralInboxEnabled = isFeatureToggleEnabled((t) => t.CENTRAL_INBOX);
  const [isPreviewOpened, setIsPreviewOpened] = useState(false);

  const handleNotificationsClickEvent = useCallback(() => {
    if (!isCentralInboxEnabled) setIsOpen(true);
  }, [isCentralInboxEnabled]);

  return (
    <div
      onMouseOver={() => (isCentralInboxEnabled ? setIsPreviewOpened(true) : null)}
      onMouseLeave={() => (isCentralInboxEnabled ? setIsPreviewOpened(false) : null)}
      css={classes.root}>
      <Row
        alignCenter
        onClick={handleNotificationsClickEvent}
        data-tutorial-id="notifications-bell"
        css={classes.content}>
        <Badge label={newReplies} showLabel={newReplies > 0} variant="highlight" alignment="top right">
          <Mail_01 color={(c) => c.neutral.variant[70]} size={18} />
        </Badge>
      </Row>
      {isCentralInboxEnabled && isPreviewOpened && (
        <div css={classes.floatingPanel}>
          <InboxConversationPreview />
        </div>
      )}
    </div>
  );
};
