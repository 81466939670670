import {
  CandidateOutreachSendMethod,
  type CandidateActivityLogResponse,
} from 'clients/CampaignsClient/CampaignsClient.types';
import type { default as Candidate } from 'model/Candidate/Candidate';
import type { SupportedLanguage } from 'shared/contexts/LocalizationContext/types';
import type { CandidateActivityLog, MessageReceivedSources } from './useCandidateActivityLogQuery.types';

import { useQuery } from '@tanstack/react-query';
import { campaignsClient } from 'clients/CampaignsClient/CampaignsClient';
import { useContext } from 'react';
import { CandidateHistoryAction } from 'services/candidates/types';
import { extractLastRefresh } from 'shared/contexts/CandidateContext/searchv3/extractLastRefresh';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { QueryKey } from 'types/query';

export const useCandidateActivityLogQuery = (candidate: Candidate) => {
  const { language } = useContext(LocalizationContext);

  return useQuery<CandidateActivityLog[]>(
    [QueryKey.useCandidateActivityLogQuery, { candidateEsPersonId: candidate.es_person_id, status: candidate.status }],
    async () => {
      const { data } = await campaignsClient.getCandidateActivityLog({
        candidate_id: candidate.es_person_id,
      });

      return data.results
        .map((activityLogResponse) => extractActivityLogFromResponse(activityLogResponse, language))
        .filter(Boolean) as CandidateActivityLog[];
    },
    { enabled: !!candidate, initialData: [], initialDataUpdatedAt: 0 },
  );
};

export const extractActivityLogFromResponse = (
  {
    id,
    action,
    user,
    campaign,
    created_at,
    is_new_message,
    candidate_notification,
    metadata,
    source,
  }: CandidateActivityLogResponse,
  language: SupportedLanguage,
): CandidateActivityLog | undefined => {
  if (
    [CandidateHistoryAction.Invisible, CandidateHistoryAction.SentToAts, CandidateHistoryAction.Unlocked].includes(
      action,
    )
  ) {
    return;
  }

  return {
    id: id,
    by: {
      id: user.id,
      name: user.first_name,
    },
    action: action,
    campaign: {
      name: campaign.name,
    },
    createdAt: new Date(created_at),
    timeAgo: extractLastRefresh(created_at, language),
    metadata,
    message: {
      isNew: is_new_message,
      source:
        action === CandidateHistoryAction.MessageReceived && isMessageReceivedSources(source)
          ? source
          : candidate_notification?.send_method,
    },
    user: { id: user.id },
  };
};

const isMessageReceivedSources = (str?: string): str is MessageReceivedSources => {
  const options: MessageReceivedSources[] = [
    CandidateOutreachSendMethod.Indeed,
    CandidateOutreachSendMethod.Linkedin,
    CandidateOutreachSendMethod.Email,
  ];

  return options.some((o) => o === str);
};
