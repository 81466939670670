import { CandidateOutreachSendMethod } from 'clients/CampaignsClient/CampaignsClient.types';
import { CandidateHistoryAction } from 'services/candidates/types';
import { CampaignJobboard } from 'shared/contexts/SelectedCampaignContext/SelectedCampaignContext.types';
import { Ats } from 'types/ats';

export type Conversation = {
  id: number;
  status: string;
  user: {
    firstName: string;
    lastName: string;
  };
  campaign: {
    name: string;
  };
  unreadCount: number;
  lastMessageDate: string;
  lastMessageFromRecruiter: boolean;
  candidateName: string;
  esPersonId: string;
  source: CampaignJobboard;
  conversationSource: CandidateOutreachSendMethod;
};

export type ConversationResponseResult = {
  id: number;
  status: string;
  user: {
    first_name: string;
    last_name: string;
  };
  campaign: {
    name: string;
  };
  unread_count: number;
  last_message_date: string;
  last_message_from_recruiter: boolean;
  candidate_name: string;
  es_person_id: string;
  source: CampaignJobboard;
  conversation_source: CandidateOutreachSendMethod;
};

export type ConversationCacheKey = {
  cacheKey: string;
};

export type ConversationResponse = {
  count: number;
  next: string | null;
  previous: string | null;
  results: ConversationResponseResult[];
};

export type ConversationPreview = {
  results: Conversation[];
  count: number;
};

export interface InboxConversationListProps {
  showFilters?: boolean;
  showTeamView?: boolean;
  conversations: Conversation[];
}

export interface InboxConversationListHeaderProps {
  showTeamView?: boolean;
}

export interface InboxConversationListBodyProps {
  conversations: Conversation[];
}

export type InboxConversationItemData = {
  id: number;
  candidateName: string;
  author: string;
  campaign: string;
  action: CandidateHistoryAction;
  atsName?: Ats;
  unreadCount: number;
};

export interface InboxConversationItemProps {
  conversation: Conversation;
}

export enum InboxFilters {
  ALL = 'all',
  UNREAD = 'unread',
  SENT = 'sent',
}
