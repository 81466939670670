import { create } from 'zustand';
import { InboxFilters } from 'components/ui/organisms/CentralInbox/CentralInbox.types';
import type { InboxStore } from './inbox.store.types';

export const useInboxStore = create<InboxStore>((set) => ({
  isTeamViewEnabled: false,
  toggleTeamView: () => set((state) => ({ isTeamViewEnabled: !state.isTeamViewEnabled })),

  selectedFilter: InboxFilters.ALL,
  setSelectedFilter: (filter: InboxFilters) => set(() => ({ selectedFilter: filter })),
}));
