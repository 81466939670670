import type { SendLinkedinMessageParams, SendLinkedinMessageProps, SendLinkedinMessageResponse } from './types';

import { useMutation } from '@tanstack/react-query';
import { browserExtensionClient } from 'clients/BrowserExtensionClient/BrowserExtensionClient';
import { getStringBinaryUUID } from 'helpers/generateBinaryUUID';
import { useLinkedinConfig } from './useLinkedinConfig';

export const useSendLinkedinMessageMutation = () => {
  const linkedinConfig = useLinkedinConfig();

  return useMutation(
    async ({ text, candidateConversation }: SendLinkedinMessageProps): Promise<SendLinkedinMessageResponse> => {
      const binaryString = getStringBinaryUUID();

      if (!linkedinConfig.isLoggedIn) {
        throw new Error('Error while trying to get linkedin user, please try again in a few seconds');
      }

      const linkedinId = linkedinConfig.linkedinId;

      const body: SendLinkedinMessageParams = {
        ...(candidateConversation.isFirstConversation && {
          hostRecipientUrns: [`urn:li:fsd_profile:${candidateConversation.candidateId}`],
        }),
        message: {
          body: {
            text,
          },
          renderContentUnions: [],
          ...(!candidateConversation.isFirstConversation && {
            conversationUrn: `urn:li:msg_conversation:(urn:li:fsd_profile:${linkedinId},${candidateConversation.threadId})`,
          }),
        },
        mailboxUrn: `urn:li:fsd_profile:${linkedinId}`,
        trackingId: binaryString,
        dedupeByClientGeneratedToken: false,
      };

      return browserExtensionClient.sendMessage('proxyHttpRequest', {
        url: 'https://www.linkedin.com/voyager/api/voyagerMessagingDashMessengerMessages?action=createMessage',
        method: 'POST',
        body: JSON.stringify(body),
        addHeadersFrom: 'linkedInClassic',
        headers: {
          accept: 'application/json',
          'accept-encoding': 'gzip, deflate, br, zstd',
        },
      });
    },
  );
};
