import type { FeatureToggle } from 'clients/UserClient/types';

import { useQuery } from '@tanstack/react-query';
import { userClient } from 'clients/UserClient/UserClient';
import { useCallback } from 'react';
import { QueryKey } from 'types/query';
import { useCurrentUserQuery } from './useCurrentUserQuery';

export enum FeatureToggleType {
  FILTER_INDEED_CANDIDATES_BASED_ON_USER_LICENSE = 'FILTER_INDEED_CANDIDATES_BASED_ON_USER_LICENSE',
  CENTRAL_INBOX = 'CENTRAL_INBOX',
}

export type UseFeatureTogglesHook = () => {
  isFeatureToggleEnabled: (feature: string | ((ft_type: typeof FeatureToggleType) => FeatureToggleType)) => boolean;
  isFeatureToggleEnabledByPrefix: (feature: string) => boolean;
  isLoading: boolean;
};

const useFeatureToggles: UseFeatureTogglesHook = () => {
  const { data: currentUser } = useCurrentUserQuery();

  const { data: featureToggles, isFetching: featureTogglesIsLoading } = useQuery(
    [QueryKey.useFeatureTogglesQuery],
    async () => {
      const { data: featureToggles } = await userClient.getFeatureToggles();

      return featureToggles;
    },
    { enabled: Boolean(currentUser), initialData: [], initialDataUpdatedAt: 0 },
  );

  const isFeatureToggleEnabled = useCallback<ReturnType<UseFeatureTogglesHook>['isFeatureToggleEnabled']>(
    (feature) => {
      const name = typeof feature === 'string' ? feature : feature(FeatureToggleType);

      const isFeatureToggleEnabled = featureToggles.some((feature) => feature.name === name && feature.enabled);

      if (name === 'search_templates') {
        return __legacy_validateSearchTemplatesToggle(featureToggles);
      }

      return isFeatureToggleEnabled;
    },
    [featureToggles, currentUser?.tenant?.id],
  );

  const isFeatureToggleEnabledByPrefix = useCallback<
    ReturnType<UseFeatureTogglesHook>['isFeatureToggleEnabledByPrefix']
  >(
    (feature) =>
      featureToggles.some((featureToggle) => featureToggle.name.startsWith(feature) && featureToggle.enabled),
    [featureToggles, currentUser?.tenant?.id],
  );

  return { isFeatureToggleEnabled, isFeatureToggleEnabledByPrefix, isLoading: featureTogglesIsLoading };
};

export default useFeatureToggles;

//#region Legacy feature toggles
// Needs to be studied if they can be removed. Theorically, they're useless because the feature(s) is/are already released

const __legacy_validateSearchTemplatesToggle = (featureToggles: FeatureToggle[]) => {
  const searchTemplateFeatureToggleSet = featureToggles.find((feature) => feature.name === 'search_templates');
  const isFeatureToggleEnabled = Boolean(searchTemplateFeatureToggleSet?.enabled);
  const searchTemplatesReleaseToggleEnabled = process.env.REACT_APP_SEARCH_TEMPLATES_RELEASE_TOGGLE === 'on';

  console.debug('[useFeatureToggles] release toggle "search_templates": ', {
    isFeatureToggleEnabled,
    isReleaseToggleEnabled: searchTemplatesReleaseToggleEnabled,
  });

  return (
    searchTemplateFeatureToggleSet?.enabled ||
    (searchTemplateFeatureToggleSet?.enabled !== false && searchTemplatesReleaseToggleEnabled)
  );
};
