import { ThemeProvider } from '@emotion/react';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { theme } from '@recruitrobin/robin-theme/web-styles';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { useStyles } from 'App.styles';
import ErrorBoundary from 'components/ui/molecules/ErrorBoundary';
import {
  persistedQueryClient,
  persistedQueryClientContext,
  persistedQueryClientPersister,
} from 'config/persistedQueryClient';
import { queryClient } from 'config/queryClient';
import { memoStorage } from 'hooks/shared/memoStorage';
import { merge } from 'lodash';
import { useEffect } from 'react';
import { default as ReactModal } from 'react-modal';
import { BrowserRouter } from 'react-router-dom';
import { AuthContextProvider } from 'shared/contexts/AuthContext';
import { ConfigContextProvider } from 'shared/contexts/ConfigContext';
import { CustomGTMContextProvider } from 'shared/contexts/CustomGTMContext';
import { DialogContextProvider } from 'shared/contexts/DialogContext';
import { GlobalModalsContextProvider } from 'shared/contexts/GlobalModalsContext/GlobalModalsContextProvider';
import { LayoutContextProvider } from 'shared/contexts/LayoutContext/LayoutContext';
import { LocalizationContextProvider } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { NotificationsContextProvider } from 'shared/contexts/NotificationsContext/NotificationsContext';
import { ResetPasswordContextProvider } from 'shared/contexts/ResetPasswordContext';
import { SnackbarContextProvider } from 'shared/contexts/SnackbarContext/SnackbarContext';
import { UserContextProvider } from 'shared/contexts/UserContext/UserContext';
import { VersionContextProvider } from 'shared/contexts/VersionContext';
import { muiTheme, themeOld } from 'styles/theme';
import { ATSIntegrationContextProvider } from 'views/LoggedIn/components/ATSIntegrations/contexts/ATSIntegrationContext';
import { default as Routes } from 'views/Routes';

ReactModal.setAppElement('body');

const App = () => {
  const classes = useStyles();

  useEffect(() => {
    if (!memoStorage.isLoaded) {
      memoStorage.loadInitialData();
    }
  }, []);

  return (
    <ConfigContextProvider>
      <CustomGTMContextProvider>
        <ErrorBoundary>
          <PersistQueryClientProvider
            context={persistedQueryClientContext}
            client={persistedQueryClient}
            persistOptions={{ persister: persistedQueryClientPersister }}>
            <QueryClientProvider client={queryClient}>
              <BrowserRouter>
                <AuthContextProvider>
                  <LayoutContextProvider>
                    <LocalizationContextProvider>
                      <MuiThemeProvider theme={muiTheme}>
                        <ThemeProvider theme={merge(themeOld, theme)}>
                          <UserContextProvider>
                            <ATSIntegrationContextProvider>
                              <NotificationsContextProvider>
                                <ResetPasswordContextProvider>
                                  <DialogContextProvider>
                                    <SnackbarContextProvider>
                                      <VersionContextProvider>
                                        <GlobalModalsContextProvider>
                                          <div css={classes.root}>
                                            <Routes />

                                            {process.env.REACT_APP_ENVIRONMENT === 'development' && (
                                              <>
                                                <ReactQueryDevtools initialIsOpen={false} />
                                                <ReactQueryDevtools
                                                  initialIsOpen={false}
                                                  context={persistedQueryClientContext}
                                                  position="top-left"
                                                />
                                              </>
                                            )}
                                          </div>
                                        </GlobalModalsContextProvider>
                                      </VersionContextProvider>
                                    </SnackbarContextProvider>
                                  </DialogContextProvider>
                                </ResetPasswordContextProvider>
                              </NotificationsContextProvider>
                            </ATSIntegrationContextProvider>
                          </UserContextProvider>
                        </ThemeProvider>
                      </MuiThemeProvider>
                    </LocalizationContextProvider>
                  </LayoutContextProvider>
                </AuthContextProvider>
              </BrowserRouter>
            </QueryClientProvider>
          </PersistQueryClientProvider>
        </ErrorBoundary>
      </CustomGTMContextProvider>
    </ConfigContextProvider>
  );
};

export default App;
