import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { atsIntegrationsClient } from 'clients/ATSIntegrationsClient/ATSIntegrationsClient';
import { UserContext } from 'shared/contexts/UserContext/UserContext';
import { ATSIntegrationContextProps, ATSIntegrationContextProviderProps } from './types';
import { paramBind } from 'helpers/useQueryHelpers';
import {
  ValidateAtsConnectionResponseError,
  ValidateAtsConnectionResponseOk,
} from 'clients/ATSIntegrationsClient/types';
import { QueryKey } from 'types/query';
import { isAxiosError } from 'helpers/clientHelpers';
import { AxiosError } from 'axios';

const defaultContext: ATSIntegrationContextProps = {
  myConnections: [],
  teamConnections: [],
  activeATS: undefined,
  hasActiveATS: undefined,
  hasValidATSConnected: undefined,
  isLoading: undefined,
  activeAtsName: undefined,
};

export const ATSIntegrationContext = createContext(defaultContext);

export const ATSIntegrationContextProvider = ({ children }: ATSIntegrationContextProviderProps) => {
  const [activeAtsName, setActiveAtsName] = useState(true);

  const { isLoggedIn } = useContext(UserContext);

  const {
    isLoading,
    data: { data: { my_connections: _myConnections = [], team_connections: _teamConnections = [] } = {} } = {},
  } = useQuery(['getAllATS'], paramBind(atsIntegrationsClient.getAll), { enabled: isLoggedIn });

  const validateATSConnection = useQuery<ValidateAtsConnectionResponseOk, ValidateAtsConnectionResponseError>(
    [QueryKey.validateATSConnection, activeAtsName],
    async () => {
      try {
        return (await atsIntegrationsClient.hasValidATSConnected()).data;
      } catch (e) {
        if (isAxiosError(e)) {
          const response = (e as AxiosError).response?.data;
          if (typeof response === 'object' && response !== null && 'error_code' in response) {
            throw response as ValidateAtsConnectionResponseError;
          }
        }
        throw {
          error_code: 'Failed to reach ATS connection',
          reason: 'Failed to reach ATS connection',
        } as ValidateAtsConnectionResponseError;
      }
    },
    { enabled: isLoggedIn, retry: false },
  );
  const hasValidATSConnected = validateATSConnection.data?.success === true;

  const activeATS = useMemo(() => {
    const [activeATS] = [..._teamConnections, ..._myConnections].filter(({ is_active }) => is_active === true);
    return activeATS;
  }, [_teamConnections, _myConnections]);

  useEffect(() => {
    if (activeATS) {
      const atsName = activeATS.name;
      setActiveAtsName(!!atsName);
    }
  }, [hasValidATSConnected, activeATS, setActiveAtsName]);

  const hasActiveATS = useMemo(() => !!activeATS?.id, [activeATS]);

  const myConnections = _myConnections.map((connection) => ({
    ...connection,
    is_active: true,
    shouldConfirmChange: false,
  }));

  const teamConnections = useMemo(
    () =>
      _teamConnections
        .map((connection) => ({
          ...connection,
          is_active: false,
          shouldConfirmChange: true,
        }))
        .toSorted(
          (a, b) =>
            (b.connected_users.length - a.connected_users.length) * 1000 +
            (a.ats_name?.localeCompare(b.ats_name ?? '') ?? 0) * 100 +
            (a.name?.localeCompare(b.name ?? '') ?? 0) * 10 +
            (a.id - b.id),
        ),
    [_teamConnections],
  );

  return (
    <ATSIntegrationContext.Provider
      value={{
        myConnections,
        teamConnections,
        activeATS,
        hasActiveATS,
        hasValidATSConnected,
        isLoading,
        activeAtsName,
        validateATSConnection,
      }}>
      {children}
    </ATSIntegrationContext.Provider>
  );
};
