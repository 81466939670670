import Column from 'components/ui/atoms/Column/Column';
import Skeleton from 'components/ui/molecules/Skeleton/Skeleton';
import { useStyles } from './InboxConversationListSkeleton.styles';

export const InboxConversationListSkeleton = () => {
  const classes = useStyles();

  return (
    <div css={classes.root}>
      {Array.from({ length: 6 }).map((_, index) => (
        <Column key={index} gap={0}>
          <Skeleton variant="paragraph" rows={1} width={104} textVariant="body.short" />
          <Skeleton variant="paragraph" rows={1} width={79} textVariant="supporting.helper" />
        </Column>
      ))}
    </div>
  );
};

export default InboxConversationListSkeleton;
