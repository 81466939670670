import { useIndeedConfig } from './useIndeedConfig';
import { useMutation } from '@tanstack/react-query';
import { fetchIndeedGraphQL } from './utils';

type ReplyMessageData = {
  jobSeekerKey: string;
  conversationId: string;
  message: string;
};

export const useIndeedReplyMessageMutation = () => {
  const indeedConfig = useIndeedConfig();

  return useMutation(async (data: ReplyMessageData) => {
    return await fetchIndeedGraphQL(
      addMessageEventToConversationGQLMutation({
        jobSeekerKey: data.jobSeekerKey,
        conversationId: data.conversationId,
        message: data.message,
      }),
      indeedConfig,
    );
  });
};

const addMessageEventToConversationGQLMutation = ({ jobSeekerKey, conversationId, message }: ReplyMessageData) => ({
  extensions: {},
  operationName: 'AddMessageEventToConversation',
  query:
    'mutation AddMessageEventToConversation($attachments: [ConversationAttachmentInput!], $clientName: String!, $conversationId: ID, $context: ConversationContextAndScopeInput, $eventId: String, $messageBody: String!, $payload: [ConversationEventMetadataInput!], $reminders: ConversationEventRemindersInput) {\n  sendConversationEvent(\n    input: {attachments: $attachments, conversation: {id: $conversationId, contextAndScope: $context}, eventId: $eventId, messageBody: $messageBody, messageContentFormat: "PLAIN", source: $clientName, type: MESSAGE, payload: $payload, reminders: $reminders}\n  ) {\n    conversationId\n    event {\n      ...ConversationEventListItem\n      __typename\n    }\n    __typename\n  }\n}\n\nfragment ConversationEventListItem on ConversationEvent {\n  id\n  author {\n    accountKey\n    externalParticipantId\n    role\n    __typename\n  }\n  cleanedMessageBody\n  type\n  messageBody\n  messageContentFormat\n  messagePreview\n  metadata {\n    key\n    value\n    __typename\n  }\n  publicationDateTime\n  payload {\n    key\n    value\n    __typename\n  }\n  attachments {\n    contentHash\n    fileSid\n    fileName\n    fileExtension\n    __typename\n  }\n  subType\n  __typename\n}\n',
  variables: {
    attachments: [],
    clientName: 'employer-messaging',
    context: {
      context: 'RESUME',
      scope: {
        preOrPostApply: {
          jobseekerAccountKey: jobSeekerKey,
        },
      },
    },
    conversationId: conversationId,
    eventId: generateUUIDv4(),
    messageBody: message,
    reminders: {
      enabled: true,
    },
  },
});

function generateUUIDv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
