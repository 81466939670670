import { notificationsClient } from 'clients/NotificationsClient/NotificationsClient';
import { useGetNewIndeedMessages } from './indeed.hooks';
import { useEffect } from 'react';
import { fetchIndeedGraphQL } from './utils';
import { getContactSummaryPayload } from './indeed.payloads';
import { useIndeedConfig } from './useIndeedConfig';
import { useLocalStorage } from 'hooks/shared';
import { searchClient } from 'clients/SearchClient/SearchClient';

const PROCESSED_INDEED_MESSAGES_NOTIFICATION = 'processedIndeedMessagesNotification';

export const useUnreadIndeedMessages = () => {
  const indeedConfig = useIndeedConfig();
  const { newIndeedMessages } = useGetNewIndeedMessages();
  const { value: getProcessedMessages, setValue: setProcessedMessages } = useLocalStorage<string[]>(
    PROCESSED_INDEED_MESSAGES_NOTIFICATION,
    [],
  );

  useEffect(() => {
    const messagesToProcess = newIndeedMessages.filter((m) => !getProcessedMessages.includes(m.lastEvent.id));

    if (messagesToProcess.length > 0) {
      notificationsClient
        .saveIndeedMessagesNotifications({ messages: messagesToProcess })
        .then((response) => {
          try {
            if (response?.data?.es_person_ids.length === 0) {
              setProcessedMessages(newIndeedMessages.map((i) => i.lastEvent.id));
              return;
            }

            response?.data?.es_person_ids.map((esPersonId) => {
              const candidateId = esPersonId.split('-')[1];
              fetchIndeedGraphQL(getContactSummaryPayload(candidateId), indeedConfig)
                .then((contactSummaryResponse) => {
                  const candidateInformation = contactSummaryResponse.data?.contactRecord?.candidate;
                  searchClient
                    .saveCandidateContactInfo({
                      esPersonId: esPersonId,
                      phoneNumber: candidateInformation.phoneNumber,
                      email: candidateInformation.email,
                    })
                    .then(() => {
                      setProcessedMessages(newIndeedMessages.map((i) => i.lastEvent.id));
                    });
                })
                .catch((err) => {
                  console.error(err);
                });
            });
          } catch (err) {
            console.error(err);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [newIndeedMessages]);
};
