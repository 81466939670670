import type { InboxConversationItemProps } from '../../CentralInbox.types';

import { useStyles } from './InboxConversationItem.styles';
import { ConversationNotificationPill } from 'components/ui/molecules/ConversationNotificationPill/ConversationNotificationPill';
import { Typography } from '@recruitrobin/robin-theme/web-components';
import { DotsVertical } from '@recruitrobin/robin-theme/web-icons';
import { Tooltip } from 'shared/components/Tooltip';
import { useContext, useState } from 'react';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { IconSizeEnum } from 'components/ui/molecules/HistoryTab/HistoryCard/HistoryCard.types';

import useVariant from 'components/ui/molecules/HistoryTab/HistoryCard/HistoryCard.useVariant';
import { CandidateHistoryAction } from 'services/candidates/types';

export const InboxConversationItem = ({ conversation }: InboxConversationItemProps) => {
  const classes = useStyles();
  const { dictionary } = useContext(LocalizationContext);
  const [isHovered, setIsHovered] = useState(false);
  const { icon } = useVariant({
    action: conversation.lastMessageFromRecruiter
      ? CandidateHistoryAction.Contacted
      : CandidateHistoryAction.MessageReceived,
    source: conversation.conversationSource,
    atsName: undefined,
    iconSize: IconSizeEnum.SMALL,
  });

  return (
    <div css={classes.root} onMouseOver={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <div>
        <div css={classes.title}>
          <Typography ellipsis variant={(v) => v.heading[5]} color={(v) => v.neutral.gray[80]}>
            {conversation.candidateName}
          </Typography>
        </div>
        <div css={classes.subtitle}>
          <div css={classes.icon}>{icon}</div>
          <Typography ellipsis variant={(v) => v.supporting['label-tiny']} color={(v) => v.neutral.gray[70]}>
            {conversation.user.firstName} / {conversation.campaign.name}
          </Typography>
        </div>
      </div>
      <div>
        {isHovered ? (
          <Tooltip title={dictionary.more}>
            <div css={classes.dots}>
              <DotsVertical size={18} />
            </div>
          </Tooltip>
        ) : (
          <ConversationNotificationPill unreadCount={conversation.unreadCount} />
        )}
      </div>
    </div>
  );
};

export default InboxConversationItem;
