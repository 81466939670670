import { ChangeEvent, useContext, useRef, useState } from 'react';
import { EditTenantUsersProps, RoleOptionsType } from './types';
import { useStyles } from './EditTenantUsers.styles';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { EditTenantUsersListUser } from '../EditTenantUsersList/types';
import { useQuery } from '@tanstack/react-query';
import { tenantClient } from 'clients/Admin';
import { paramBind } from 'helpers/useQueryHelpers';
import { Column } from 'components/ui/atoms';
import { SingleSelect } from 'components/ui/molecules';
import { Checkbox } from 'shared/components/CheckboxGroup';
import { default as InputLabelWrapper } from 'shared/components/InputLabelWrapper';
import { UserRoles } from 'clients/UserClient/types';
import { Input, InputWrapper } from '@recruitrobin/robin-theme/web-components';

const EditTenantUsers = ({ user, onChangeUser }: EditTenantUsersProps) => {
  const classes = useStyles();
  const { dictionary } = useContext(LocalizationContext);
  const [localUser, setLocalUser] = useState(user);
  const checkboxId = useRef<string>(Math.floor(Math.random() * 10000 * Date.now()).toString(16));

  const { data: { data: atsCredentials = [] } = {} } = useQuery(
    [`atsCredentialsByTenantId-${user.tenant_id}`],
    paramBind(() => tenantClient.getAtsCredentials(user.tenant_id)),
    { cacheTime: 5 * 60 * 1000, staleTime: 5 * 60 * 1000 },
  );

  const { data: { data: teams = [] } = {} } = useQuery(
    [`teamsByTenantId-${user.tenant_id}`],
    paramBind(() => tenantClient.getTeams(user.tenant_id)),
    { cacheTime: 5 * 60 * 1000, staleTime: 5 * 60 * 1000 },
  );

  const handleChange = (field: keyof EditTenantUsersListUser) => {
    return (text: string) => {
      onChangeUser?.({ ...localUser, [field]: text });
      setLocalUser({ ...localUser, [field]: text });
    };
  };

  const handleChangeTutorial = (e: ChangeEvent<HTMLInputElement>) => {
    onChangeUser?.({ ...localUser, has_tutorial: e.target.checked });
    setLocalUser({ ...localUser, has_tutorial: e.target.checked });
  };

  const handleChangeRole = ({ value }: RoleOptionsType) => {
    onChangeUser?.({ ...localUser, role: value });
    setLocalUser({ ...localUser, role: value });
  };

  const handleChangeAtsCredential = ({ value }: any) => {
    onChangeUser?.({ ...localUser, ats_credential_id: value });
    setLocalUser({ ...localUser, ats_credential_id: value });
  };

  const handleChangeTeam = ({ value }: any) => {
    onChangeUser?.({ ...localUser, team_id: value });
    setLocalUser({ ...localUser, team_id: value });
  };

  const roleOptions: RoleOptionsType[] = [
    { key: UserRoles.Recruiter, label: 'Recruiter', value: UserRoles.Recruiter },
    { key: UserRoles.Admin, label: 'Admin', value: UserRoles.Admin },
  ];
  const selectedRoleOption = roleOptions.find(({ value: v }) => v === localUser.role) || roleOptions[0];

  const atsCredentialsOptions = [
    { key: `${-1}`, label: 'None', value: -1 },
    ...atsCredentials.map(({ id, name }) => ({ key: `${id}`, label: name, value: id })),
  ];
  const selectedAtsCredentialOption =
    atsCredentialsOptions.find(({ value: v }) => v === localUser.ats_credential_id) || atsCredentialsOptions[0];

  const teamsOptions = [
    { key: `${-1}`, label: 'None', value: -1 },
    ...teams.map(({ id, name }) => ({ key: `${id}`, label: name, value: id })),
  ];
  const selectedTeamOption = teamsOptions.find(({ value: v }) => v === localUser.team_id) || teamsOptions[0];

  return (
    <div css={classes.root}>
      <InputWrapper label={dictionary.firstName} contained>
        <Input
          onChange={(e) => handleChange('first_name')(e.target.value)}
          value={localUser.first_name}
          type="text"
          variant="gray"
          preventShrink
          autoComplete="off"
          required
        />
      </InputWrapper>
      <InputWrapper label={dictionary.lastName} contained>
        <Input
          onChange={(e) => handleChange('last_name')(e.target.value)}
          value={localUser.last_name}
          type="text"
          variant="gray"
          preventShrink
          autoComplete="off"
          required
        />
      </InputWrapper>
      <InputWrapper label={dictionary.emailAddress} contained>
        <Input
          onChange={(e) => handleChange('email')(e.target.value)}
          value={localUser.email}
          type="email"
          variant="gray"
          preventShrink
          autoComplete="off"
          required
        />
      </InputWrapper>
      <div>
        <div css={classes.checkbox}>
          <InputLabelWrapper label={dictionary.tutorial} htmlFor={checkboxId.current} />
          <Checkbox id={checkboxId.current} onChange={handleChangeTutorial} checked={localUser.has_tutorial} />
        </div>
      </div>
      <Column gap={0}>
        <InputLabelWrapper label="Role" />
        <SingleSelect text={selectedRoleOption.label} onChange={handleChangeRole} options={roleOptions} contained />
      </Column>
      <Column gap={0}>
        <InputLabelWrapper label="ATS Credential" />
        <SingleSelect
          text={selectedAtsCredentialOption.label}
          onChange={handleChangeAtsCredential}
          options={atsCredentialsOptions}
          contained
        />
      </Column>
      <Column gap={0}>
        <InputLabelWrapper label="Team" />
        <SingleSelect text={selectedTeamOption.label} onChange={handleChangeTeam} options={teamsOptions} contained />
      </Column>
    </div>
  );
};

export default EditTenantUsers;
