import type { LinkedinRecruiterMessagingResponse } from './types';

import { useQuery } from '@tanstack/react-query';
import { browserExtensionClient } from 'clients/BrowserExtensionClient/BrowserExtensionClient';
import { crawlerClient } from 'clients/CrawlerClient/CrawlerClient';
import { notificationsClient } from 'clients/NotificationsClient/NotificationsClient';
import { useEffect, useRef, useState } from 'react';
import { useLinkedinInMailConfig } from './linkedinInMail/useLinkedinInMailConfig';
import { encodeBase64 } from '../../../helpers/base64';
import { useCurrentUserQuery } from '../useCurrentUserQuery';

const REFETCH_INTERVAL = 600 * 1000; // 10 minutes
const GET_UNREAD_LINKEDIN_RECRUITER_MESSAGES =
  'https://www.linkedin.com/talent/api/talentMailboxSummary?decoration=%28numUnseenMessages%2CinboxHomeUrl%2CrecentMessages*%28read%2CcreatedAt%2Csubject%2Cbody%2Curl%2CsenderFirstName%2CsenderLastName%2CsenderProfile~%28entityUrn%2CfirstName%2ClastName%2Cheadline%2CprofilePicture%2CvectorProfilePicture%2CpublicProfileUrl%2CfollowerCount%2CnetworkDistance%2CautomatedActionProfile%29%29%29';

export function useUnreadLinkedInRecruiterMessages() {
  const { data: currentUser } = useCurrentUserQuery();
  const { status: linkedinInMailStatus } = useLinkedinInMailConfig();

  const invalidationCountRef = useRef(0);
  const [shouldRunCountUnreadQuery, setShouldRunCountUnreadQuery] = useState(true);

  useQuery(
    ['countUnreadLinkedinRecruiterMessages'],
    async () => {
      const response = await browserExtensionClient.sendMessage('proxyHttpRequest', {
        url: GET_UNREAD_LINKEDIN_RECRUITER_MESSAGES,
        method: 'GET',
        addHeadersFrom: 'linkedInClassic',
        headers: {
          accept: 'application/json',
          'Accept-Encoding': 'gzip, deflate, br, zstd',
        },
      });

      return response;
    },
    {
      enabled: !!currentUser && linkedinInMailStatus === 'Logged In' && shouldRunCountUnreadQuery,
      refetchInterval: REFETCH_INTERVAL,
      refetchIntervalInBackground: true,
      onSuccess: async (data) => {
        if (currentUser) {
          const jsonData: LinkedinRecruiterMessagingResponse = JSON.parse(data.body);

          if (jsonData.status === 403 || jsonData.status === 400 || jsonData.status === 401) {
            if (invalidationCountRef.current < 3) {
              invalidationCountRef.current += 1;
            } else {
              setShouldRunCountUnreadQuery(false);
            }
            throw new Error(`HTTP ${jsonData.status} Error`);
          }

          const messages = await Promise.all(
            jsonData.recentMessages.map(async (message) => {
              const linkedinCandidateId = message.senderProfile.split(':ts_profile:')[1];

              try {
                const candidate = await crawlerClient.getLinkedinRecruiterCandidate(linkedinCandidateId);
                const id = candidate?.data.candidate?.linkedInMemberProfileUrnResolutionResult?.referenceUrn
                  ?.split(':')
                  .pop();
                const oldCandidateId = candidate.data.candidate?.candidate?.positionGroupView?.profileId;
                const profileId = oldCandidateId ? oldCandidateId : id;
                const esPersonId = `cvt-${profileId}`;

                return {
                  es_person_id: esPersonId,
                  message_id: String(message.createdAt),
                  thread_id: encodeBase64(`${currentUser?.id}-${esPersonId}`),
                };
              } catch (error) {
                return null;
              }
            }),
          );

          const filteredMessages = messages.filter((message) => message !== null);

          notificationsClient.saveLinkedinRecruiterMessagesNotifications({ messages: filteredMessages });
        }
      },
    },
  );

  useEffect(() => {
    if (currentUser) {
      invalidationCountRef.current = 0;
      setShouldRunCountUnreadQuery(true);
    }
  }, [currentUser, linkedinInMailStatus]);
}
