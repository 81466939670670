import type { LinkedinInMailConfig, LinkedinInMailConfigUnknown } from './types';

import { useQuery } from '@tanstack/react-query';
import { browserExtensionClient } from 'clients/BrowserExtensionClient/BrowserExtensionClient';
import { logger } from 'config/logger';
import { persistedQueryClient, persistedQueryClientContext } from 'config/persistedQueryClient';
import {
  BrowserExtensionInfoStatus,
  useBrowserExtensionStatusQuery,
} from 'hooks/shared/useBrowserExtensionStatusQuery';
import { PersistedQueryKey } from 'types/persistedQuery';
import { useLinkedinConfig } from '../linkedin/useLinkedinConfig';
import { useEffect } from 'react';

const unknownResponse: LinkedinInMailConfigUnknown = {
  isLoggedIn: false,
  status: 'Unknown',
};

export const useLinkedinInMailConfig = () => {
  const browserExtensionStatusQuery = useBrowserExtensionStatusQuery();
  const linkedinConfig = useLinkedinConfig();

  const { data: linkedinInMailConfig, isFetching } = useQuery<LinkedinInMailConfig>(
    [PersistedQueryKey.useLinkedinInMailConfig],
    async () => {
      if (!linkedinConfig.isLoggedIn) return unknownResponse;

      const isLinkedinInMailUser = await getIsLinkedinInMailUser();

      if (!isLinkedinInMailUser) {
        return { isLoggedIn: false, status: 'Logged Out' };
      }

      return { isLoggedIn: true, status: 'Logged In' };
    },
    {
      enabled:
        browserExtensionStatusQuery.data.status === BrowserExtensionInfoStatus.Active && linkedinConfig.isLoggedIn,
      context: persistedQueryClientContext,
      initialData: unknownResponse,
      initialDataUpdatedAt: 0,
    },
  );

  useEffect(() => {
    if (!isFetching && !linkedinConfig.isLoggedIn && linkedinInMailConfig.isLoggedIn) {
      persistedQueryClient.removeQueries([PersistedQueryKey.useLinkedinInMailConfig]);
    }
  }, [linkedinConfig.isLoggedIn, linkedinInMailConfig.isLoggedIn, isFetching]);

  return linkedinInMailConfig;
};

const getIsLinkedinInMailUser = async () => {
  try {
    const response = await browserExtensionClient.sendMessage('proxyHttpRequest', {
      url: 'https://www.linkedin.com/talent/api/talentContractOptions?decoration=%28applicationInstance%2CenterpriseProfile%2Ccontract%2CcontractName%2Cdescription%2Ccategory%2CssoEnabled%29',
      addHeadersFrom: 'linkedInClassic',
      headers: {
        'x-li-pem-metadata': 'Hiring Platform - Contract Chooser=hiring-platform-contract-chooser',
        accept: 'application/json',
      },
    });

    return response.status === 200;
  } catch (e) {
    console.error(e);
    logger.error({
      error: e,
      customMessage: 'Error when verifying linkedin inmail logged in',
    });

    return false;
  }
};
