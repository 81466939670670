import { Typography } from '@recruitrobin/robin-theme/web-components';
import { MailArrowDown } from '@recruitrobin/robin-theme/web-icons';
import { useStyles } from './ConversationNotificationPill.styles';
import { ConversationNotificationPillProps } from './ConversationNotificationPill.types';

export const ConversationNotificationPill = ({ unreadCount = 0 }: ConversationNotificationPillProps) => {
  const classes = useStyles();

  if (!unreadCount) return;

  return (
    <div css={classes.root}>
      <div css={classes.icon}>
        <MailArrowDown size={14} />
        <Typography variant={(v) => v.supporting['label-tiny']}>{unreadCount}</Typography>
      </div>
    </div>
  );
};
