import type { InboxConversationListHeaderProps } from '../../CentralInbox.types';

import { useContext, useState } from 'react';
import { useStyles } from './InboxConversationListHeader.styles';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { Switch } from 'components/ui/atoms';
import useConversationsQuery from 'shared/hooks/useConversationsQuery';
import { QueryKey } from 'types/query';

export const InboxConversationListHeader = ({ showTeamView = true }: InboxConversationListHeaderProps) => {
  const classes = useStyles();
  const { dictionary } = useContext(LocalizationContext);
  const [teamView, setTeamView] = useState(true);
  const {
    data: { count: unreadConversationsAmount },
  } = useConversationsQuery({ cacheKey: QueryKey.getConversationsPreview });

  return (
    <div css={classes.root}>
      <div css={classes.labels}>
        <span css={classes.label}>{dictionary.inbox}</span>
        <span css={classes.unreadConversationsAmount}>{unreadConversationsAmount}</span>
      </div>
      {showTeamView && (
        <Switch
          onChange={() => setTeamView(!teamView)}
          checked={teamView}
          label={{ off: dictionary.teamView.toUpperCase() }}
          labelVariant={(v) => v.supporting['label-tiny']}
          variant="smaller"
        />
      )}
    </div>
  );
};

export default InboxConversationListHeader;
