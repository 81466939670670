import { useQueryClient } from '@tanstack/react-query';
import localForage from 'localforage';
import { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  getTokenFromBrowserExtension,
  getIsLoggedInFromBrowserExtension,
} from 'services/browser-extension/getTokenFromBrowserExtension';
import { QueryKey } from 'types/query';
import { AuthContextProps, AuthContextProviderProps, GetActiveTokenStaticallyHandler, TokenType } from './types';

const USER_TOKEN_KEY = 'token';
const IMPERSONATED_TOKEN_KEY = 'impersonate-user';

const initialState: AuthContextProps = {
  activeToken: null,
  activeTokenType: 'default',
  disposeAllTokens: () => undefined,
  disposeImpersonatedToken: () => undefined,
  disposeUserToken: () => undefined,
  setImpersonateToken: () => undefined,
  setUserToken: () => undefined,
  userEmail: { current: '' },
  isFirstTokenLoads: true,
};

export const AuthContext = createContext<AuthContextProps>(initialState);

export const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
  const queryClient = useQueryClient();
  const [activeToken, setActiveToken] = useState<string | null>(null);
  const [activeTokenType, setActiveTokenType] = useState<TokenType>('default');
  const [isFirstTokenLoads, setIsFirstTokenLoads] = useState(initialState.isFirstTokenLoads);
  const userEmail = useRef<string>('');

  const refreshTokenFromLocalStorage = useCallback(async () => {
    const [token, type] = await getActiveTokenStatically();

    setActiveToken(token);
    setActiveTokenType(type);
  }, []);

  useEffect(() => {
    refreshTokenFromLocalStorage().then(() => {
      setIsFirstTokenLoads(false);
    });
  }, [refreshTokenFromLocalStorage]);

  const setDataInIndexedDB = async (key: string, value: any) => {
    try {
      await localForage.setItem(key, value);
    } catch (error) {
      console.error('Failed to save data in IndexedDB', error);
    }
  };

  const setUserToken = useCallback((token: string) => {
    localStorage.setItem(USER_TOKEN_KEY, token);
    setDataInIndexedDB(USER_TOKEN_KEY, token);

    setActiveToken(token);
    setActiveTokenType('default');
  }, []);

  const setImpersonateToken = useCallback((token: string) => {
    localStorage.setItem(IMPERSONATED_TOKEN_KEY, token);
    setDataInIndexedDB(IMPERSONATED_TOKEN_KEY, token);

    setActiveToken(token);
    setActiveTokenType('impersonate');
  }, []);

  const disposeUserToken = useCallback(() => {
    localStorage.removeItem(USER_TOKEN_KEY);
    localForage.removeItem(USER_TOKEN_KEY);
    queryClient.removeQueries([QueryKey.useCurrentUserQuery], { exact: false });

    refreshTokenFromLocalStorage();
  }, [refreshTokenFromLocalStorage, queryClient]);

  const disposeImpersonatedToken = useCallback(() => {
    localStorage.removeItem(IMPERSONATED_TOKEN_KEY);
    localForage.removeItem(IMPERSONATED_TOKEN_KEY);
    queryClient.removeQueries([QueryKey.useCurrentUserQuery], { exact: false });
    queryClient.removeQueries([QueryKey.campaignManagerContextUseCampaigns]);

    refreshTokenFromLocalStorage();
  }, [refreshTokenFromLocalStorage, queryClient]);

  const disposeAllTokens = useCallback(() => {
    disposeUserToken();
    disposeImpersonatedToken();
  }, [disposeImpersonatedToken, disposeUserToken]);

  const propsMemoized = useMemo(() => {
    return {
      userEmail,
      activeToken,
      activeTokenType,
      setUserToken,
      setImpersonateToken,
      disposeUserToken,
      disposeImpersonatedToken,
      disposeAllTokens,
      isFirstTokenLoads,
    };
  }, [
    activeToken,
    activeTokenType,
    setUserToken,
    setImpersonateToken,
    disposeUserToken,
    disposeImpersonatedToken,
    disposeAllTokens,
    isFirstTokenLoads,
  ]);

  return <AuthContext.Provider value={propsMemoized}>{children}</AuthContext.Provider>;
};

export const getActiveTokenStatically: GetActiveTokenStaticallyHandler = async () => {
  const userTokenIndexDB = await localForage.getItem<string | null>(USER_TOKEN_KEY);
  const impersonatedTokenIndexDB = await localForage.getItem<string | null>(IMPERSONATED_TOKEN_KEY);

  if (impersonatedTokenIndexDB) {
    return [impersonatedTokenIndexDB, 'impersonate'];
  }

  if (userTokenIndexDB) {
    return [userTokenIndexDB, 'default'];
  }

  const userToken = localStorage.getItem(USER_TOKEN_KEY);
  const impersonatedToken = localStorage.getItem(IMPERSONATED_TOKEN_KEY);

  if (impersonatedToken) {
    return [impersonatedToken, 'impersonate'];
  }

  if (userToken) {
    return [userToken, 'default'];
  }

  const extensionUserIsLoggedIn = await getIsLoggedInFromBrowserExtension();

  if (extensionUserIsLoggedIn === '1') {
    const extensionToken = await getTokenFromBrowserExtension();
    if (extensionToken) {
      try {
        localStorage.setItem(USER_TOKEN_KEY, extensionToken);
        await localForage.setItem(USER_TOKEN_KEY, extensionToken);
      } catch (error) {
        console.error('Failed to save data in IndexedDB', error);
      }

      return [extensionToken, 'default'];
    }
  }

  return [null, 'default'];
};
