import { useQuery } from '@tanstack/react-query';
import { conversationsClient } from 'clients/ConversationsClient/ConversationsClient';
import { GetConversationsParams } from 'clients/ConversationsClient/ConversationsClient.types';
import { ConversationCacheKey, InboxFilters } from 'components/ui/organisms/CentralInbox/CentralInbox.types';

const useConversationsQuery = ({
  limit = 5,
  offset = 0,
  conversationScope = InboxFilters.ALL,
  teamView = true,
  cacheKey,
}: GetConversationsParams & ConversationCacheKey) => {
  return useQuery(
    [cacheKey],
    async () => {
      const response = await conversationsClient.getConversations({ limit, offset, conversationScope, teamView });
      return {
        ...response.data,
        results: response.data.results.map((result) => ({
          ...result,
          user: {
            firstName: result.user.first_name,
            lastName: result.user.last_name,
          },
          unreadCount: result.unread_count,
          lastMessageDate: result.last_message_date,
          lastMessageFromRecruiter: result.last_message_from_recruiter,
          candidateName: result.candidate_name,
          esPersonId: result.es_person_id,
          conversationSource: result.conversation_source,
        })),
      };
    },
    {
      initialData: {
        count: 0,
        next: null,
        previous: null,
        results: [],
      },
      initialDataUpdatedAt: 0,
    },
  );
};

export default useConversationsQuery;
