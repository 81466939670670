import type { CvSource } from 'clients/Admin/TenantClient/types';
import type {
  LoginHandler,
  UpdateCurrentUserHandler,
  UserCredit,
  UserResponse,
  ValidateEmailAndPasswordHandler,
  ValidateMfaCodeHandler,
} from 'clients/UserClient/types';

export type EmailSettingsProps = {
  enabled: boolean;
  frequency: number;
};

export type JobBoardSettingsType = {
  id?: number;
  never_ask_before_spending_monster_inventory_credit: boolean;

  /// TODO WERKZOEKEN: Remove this after release new jobboard
  // never_ask_before_spending_werkzoeken_credit: boolean;
};

export type UserContextProps = {
  isLoggedIn: boolean;
  login?: LoginHandler;
  validateEmailAndPassword?: ValidateEmailAndPasswordHandler;
  validateMfaCode?: ValidateMfaCodeHandler;
  logout: () => void;
  impersonate: (token: string) => Promise<void>;
  currentUser?: UserResponse;
  tenant?: any; //FIXME
  updateCurrentUser?: UpdateCurrentUserHandler;
  userCredits?: UserCredit;
  hasMonsterboardV2License?: boolean;

  /// TODO WERKZOEKEN: Remove this after release new jobboard
  // hasWerkzoekenLicense?: boolean;
  hasWerknlBoardLicense?: boolean;
  usedCredits?: number;
  availableCredits?: number;
  userEmailSettings?: any;
  updateUserEmailSettingsProfile?: any;
  userJobBoardSettings?: JobBoardSettingsType;
  updateUserJobBoardSettingsProfile?: (values: JobBoardSettingsType, id?: number) => Promise<boolean>;
  askBeforeSpendingMonsterInventoryCredit?: boolean;

  /// TODO WERKZOEKEN: Remove this after release new jobboard
  // askBeforeSpendingWerkzoekenCredit?: boolean;
  availableJobBoardsIsLoading: boolean;
  tenantCVSources: CvSource[];
  tenantCvSourcesIsLoading: boolean;
  mailSignOff: { fullName?: string; phoneNumber?: string };
  isFirstUserLoads: boolean;
  isInvalidToken: boolean;
};

export type UserContextProviderProps = {
  children: React.ReactNode;
};

export const WERKNL_ACTIONS = 'WERKNL_ACTIONS';
export const WERKNL_LAYOUT_CHOSEN = 'WERKNL_LAYOUT_CHOSEN';
