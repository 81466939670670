import { useQuery } from '@tanstack/react-query';
import { browserExtensionClient } from 'clients/BrowserExtensionClient/BrowserExtensionClient';
import { logger } from 'config/logger';
import { queryClient } from 'config/queryClient';
import {
  BrowserExtensionInfoStatus,
  useBrowserExtensionStatusQuery,
} from 'hooks/shared/useBrowserExtensionStatusQuery';
import { useMemo } from 'react';
import useFeatureToggles from 'shared/hooks/useFeatureToggles';
import { QueryKey } from 'types/query';

type CommonModel = {
  csrfParam: string;
  csrfToken: string;
  ctk: string;
  emailAddress: string | null;
  loggedIn: boolean;
  subscriptionInfo: {
    admin: boolean;
    subscriptionAssigned: boolean;
    subscriptionKey: string;
    contactsRemaining: number;
    assignedSubscriptions: { subscriptionKey: string; status: 'ACTIVE' | 'EXPIRED' }[] | null;
  };
};

type BaseConfig = {
  creditBalance: number;
};

type IndeedConfig = BaseConfig &
  (
    | { status: 'Unknown'; isLoggedIn: false; email: null }
    | { status: 'Logged Out'; isLoggedIn: false; email: null }
    | {
        status: 'Logged In';
        isLoggedIn: true;
        headers: Record<string, string>;
        csrfToken: string;
        csrfParam: string;
        email: string;
        hasExpiredSubscription: boolean;
        hasActiveSubscription: boolean;
      }
  );

type ExtraConfig = {
  isInitialLoading: boolean;
  getCurrentValue: () => IndeedConfig;
};

const INDEED_API_KEY = '0f2b0de1b8ff96890172eeeba0816aaab662605e3efebbc0450745798c4b35ae';

const UNKNOWN_STATE: IndeedConfig = {
  isLoggedIn: false,
  status: 'Unknown',
  creditBalance: 0,
  email: null,
};

export const useIndeedConfig = (): IndeedConfig & ExtraConfig => {
  const browserExtensionStatusQuery = useBrowserExtensionStatusQuery();
  const { isFeatureToggleEnabled, isLoading } = useFeatureToggles();

  /// TODO: --START-- REMOVE AFTER INDEED LICENSE RELEASED
  const filterIndeedCandidatesBasedOnUserLicense = isFeatureToggleEnabled(
    (t) => t.FILTER_INDEED_CANDIDATES_BASED_ON_USER_LICENSE,
  );
  /// TODO: -- END -- REMOVE AFTER INDEED LICENSE RELEASED

  const { data, isInitialLoading, dataUpdatedAt } = useQuery<IndeedConfig>(
    [QueryKey.useIndeedCommonModelQuery, { filterIndeedCandidatesBasedOnUserLicense }],
    async () => {
      try {
        const commonModel = await getCommonModel();

        const headers: Record<string, string> = {
          'content-type': 'application/json',
          'indeed-ctk': commonModel.ctk,
          'indeed-api-key': INDEED_API_KEY,
          'indeed-client-sub-app': 'rezemp-discovery',
          'indeed-client-sub-app-component': './Root',
          referer: 'https://resumes.indeed.com/',
        };

        if (!commonModel) return UNKNOWN_STATE;
        if (!commonModel.loggedIn)
          return {
            isLoggedIn: false,
            status: 'Logged Out',
            creditBalance: 0,
            email: null,
          } satisfies IndeedConfig;

        const assignedSubscriptions = commonModel.subscriptionInfo.assignedSubscriptions ?? [];
        const hasActiveSubscription = assignedSubscriptions.some((s) => s.status === 'ACTIVE');
        const hasExpiredSubscription = assignedSubscriptions.some((s) => s.status === 'EXPIRED');
        // const hasActiveSubscription = true;
        // const hasExpiredSubscription = false;

        if (!hasActiveSubscription && !filterIndeedCandidatesBasedOnUserLicense) {
          return {
            isLoggedIn: false,
            status: 'Logged Out',
            creditBalance: 0,
            email: null,
          } satisfies IndeedConfig;
        }

        if (!commonModel.emailAddress) {
          logger.error('Indeed user is logged in but doesn’t have an email.');
        }

        return {
          isLoggedIn: true,
          headers,
          status: 'Logged In',
          email: commonModel.emailAddress ?? '',
          csrfParam: commonModel.csrfParam,
          csrfToken: commonModel.csrfToken,
          creditBalance: commonModel.subscriptionInfo.contactsRemaining,
          hasExpiredSubscription,
          hasActiveSubscription,
        } satisfies IndeedConfig;
      } catch (e) {
        logger.error(e);

        return UNKNOWN_STATE;
      }
    },
    {
      enabled: browserExtensionStatusQuery.data.status === BrowserExtensionInfoStatus.Active && !isLoading,
      initialData: UNKNOWN_STATE,
      initialDataUpdatedAt: 0,
      retry: 0,
    },
  );

  const _isInitialLoading =
    browserExtensionStatusQuery.isInitialLoading ||
    (browserExtensionStatusQuery.data.status === BrowserExtensionInfoStatus.Active &&
      (isInitialLoading || dataUpdatedAt === 0));

  return useMemo(() => {
    return {
      ...data,
      isInitialLoading: _isInitialLoading,
      getCurrentValue: () =>
        queryClient.getQueryData<IndeedConfig>(
          [QueryKey.useIndeedCommonModelQuery, { filterIndeedCandidatesBasedOnUserLicense }],
          { exact: false },
        ) as IndeedConfig,
    };
  }, [data, _isInitialLoading, filterIndeedCandidatesBasedOnUserLicense]);
};

const getCommonModel = async (): Promise<CommonModel> => {
  const response = await browserExtensionClient.sendMessage('proxyHttpRequest', {
    url: 'https://resumes.indeed.com/api/common-model',
    method: 'GET',
  });

  // FIXME: Error: "undefined" is not valid JSON https://onenr.io/0ZQW8kKoewW
  return JSON.parse(response.body);
};
