import { browserExtensionClient } from 'clients/BrowserExtensionClient/BrowserExtensionClient';
import { useContext, useEffect } from 'react';
import {
  getTokenFromBrowserExtension,
  getIsLoggedInFromBrowserExtension,
} from 'services/browser-extension/getTokenFromBrowserExtension';
import { AuthContext } from 'shared/contexts/AuthContext';
import { useCurrentUserQuery } from 'shared/hooks/useCurrentUserQuery';
import { BrowserExtensionInfoStatus, useBrowserExtensionStatusQuery } from './useBrowserExtensionStatusQuery';

export const useBrowserExtensionSync = async () => {
  const { activeToken } = useContext(AuthContext);
  const { data: currentUser } = useCurrentUserQuery();
  const browserExtensionStatusQuery = useBrowserExtensionStatusQuery();

  const updateUserStateChange = async () => {
    browserExtensionClient.sendMessage('userStateChanged', {
      user: currentUser,
      isLoggedIn: true,
      token: activeToken,
    });
  };

  useEffect(() => {
    if (activeToken && currentUser && browserExtensionStatusQuery.data.status === BrowserExtensionInfoStatus.Active) {
      getTokenFromBrowserExtension().then((token) => {
        if (!token || token !== activeToken) {
          updateUserStateChange();
        } else {
          getIsLoggedInFromBrowserExtension().then((isLoggedIn) => {
            if (isLoggedIn !== '1') {
              updateUserStateChange();
            }
          });
        }
      });
    }
  }, [activeToken, currentUser, browserExtensionStatusQuery.data.status]);

  return {
    updateUserStateChange,
  };
};
