export const removeQueryParam = (param: string) => {
  const params = new URLSearchParams(window.location.search);
  params.delete(param);
  const newSearch = params.toString();
  window.history.replaceState({}, '', newSearch ? '?' + newSearch : window.location.pathname);
};

export const formatQueryParams = (params: Record<string, any>): string => {
  const queryString = Object.entries(params)
    .filter((obj) => obj[1] !== undefined && obj[1] !== null)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');

  return queryString ? `?${queryString}` : '';
};
