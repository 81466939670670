import { Styles, withStyles, css } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: css`
    padding: 8px;
    display: flex;
    gap: 10px;
  `,
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
