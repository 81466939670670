import { HistoryCardVariantProps, IconSize, IconSizeEnum } from './HistoryCard.types';

import {
  Bookmark,
  Database_01,
  File_05,
  IndeedReceived,
  IndeedSent,
  Linkedin,
  LinkedinFill,
  Mail_01,
  MailArrowDown,
  Phone,
  RobinIcon,
  Tag_01,
  UserCheck_01,
  Werknl,
  Whatsapp,
  XClose,
} from '@recruitrobin/robin-theme/web-icons';
import { CandidateOutreachSendMethod } from 'clients/CampaignsClient/CampaignsClient.types';
import { lazy, Suspense, useContext, useMemo } from 'react';
import { CandidateHistoryAction } from 'services/candidates/types';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { Ats } from 'types/ats';

const LazyAtsIcon = lazy(() =>
  import('components/ui/atoms/AtsIcon/AtsIcon').then((module) => ({ default: module.AtsIcon })),
);
function AtsIcon({ ats, iconSize = IconSizeEnum.DEFAULT }: { ats?: Ats; iconSize: IconSize }) {
  return (
    <Suspense fallback={<Database_01 size={24} />}>
      <LazyAtsIcon ats={ats} size={iconSize === IconSizeEnum.DEFAULT ? 24 : 15} />
    </Suspense>
  );
}

const useVariant = ({
  action,
  source,
  atsName,
  iconSize = IconSizeEnum.DEFAULT,
}: {
  action: CandidateHistoryAction;
  source: CandidateOutreachSendMethod | undefined;
  atsName: Ats | undefined;
  iconSize?: IconSize;
}): HistoryCardVariantProps => {
  const { dictionary } = useContext(LocalizationContext);

  const commonVariantColors: Pick<HistoryCardVariantProps, 'variantColorDiv' | 'variantColorIcon'> = {
    variantColorDiv: (theme) => theme.secondary.emerald[10],
    variantColorIcon: (theme) => theme.secondary.emerald[50],
  };

  const sourceMapping: Record<CandidateOutreachSendMethod, Partial<HistoryCardVariantProps>> = useMemo(
    () => ({
      [CandidateOutreachSendMethod.Call]: {
        title: dictionary.contacted,
        icon: <Phone size={iconSize === IconSizeEnum.DEFAULT ? 24 : 15} />,
      },
      [CandidateOutreachSendMethod.Email]: {
        title: dictionary.emailSent,
        icon: <Mail_01 size={iconSize === IconSizeEnum.DEFAULT ? 20 : 15} />,
      },
      [CandidateOutreachSendMethod.InMail]: {
        title: dictionary.linkedinInmailSent,
        icon: <LinkedinFill size={iconSize === IconSizeEnum.DEFAULT ? 24 : 15} />,
      },
      [CandidateOutreachSendMethod.Indeed]: {
        title: dictionary.indeedSent,
        icon: <IndeedSent size={iconSize === IconSizeEnum.DEFAULT ? 29 : 15} />,
      },
      [CandidateOutreachSendMethod.Linkedin]: {
        title: dictionary.linkedIn,
        icon: <Linkedin size={iconSize === IconSizeEnum.DEFAULT ? 18 : 13} />,
      },
      [CandidateOutreachSendMethod.Werknl]: {
        title: dictionary.Werknl,
        icon: <Werknl size={iconSize === IconSizeEnum.DEFAULT ? 18 : 15} />,
      },
      [CandidateOutreachSendMethod.Whatsapp]: {
        title: dictionary.whatsappSent,
        icon: <Whatsapp size={iconSize === IconSizeEnum.DEFAULT ? 19 : 15} />,
      },
    }),
    [dictionary],
  );

  const actionMapping: Record<CandidateHistoryAction, Partial<HistoryCardVariantProps>> = useMemo(
    () => ({
      [CandidateHistoryAction.Contacted]: {
        title: dictionary.contacted,
        icon: <Phone size={iconSize === IconSizeEnum.DEFAULT ? 24 : 15} />,
      },
      [CandidateHistoryAction.Hidden]: {
        title: dictionary.hidden,
        icon: <XClose size={iconSize === IconSizeEnum.DEFAULT ? 24 : 15} />,
        variantColorDiv: (theme) => theme.tertiary.lime[10],
        variantColorIcon: (theme) => theme.tertiary.lime[50],
      },
      [CandidateHistoryAction.Invisible]: {
        title: dictionary.hidden,
        icon: <RobinIcon size={iconSize === IconSizeEnum.DEFAULT ? 24 : 15} />,
        variantColorDiv: (theme) => theme.neutral.variant[50],
        variantColorIcon: (theme) => theme.secondary.sky[50],
      },
      [CandidateHistoryAction.LabelChanged]: {
        title: dictionary.labelsChanged,
        icon: <Tag_01 size={iconSize === IconSizeEnum.DEFAULT ? 24 : 15} />,
        variantColorDiv: (theme) => theme.neutral.variant[5],
        variantColorIcon: (theme) => theme.secondary.sky[50],
      },
      [CandidateHistoryAction.Matched]: {
        title: dictionary.matched,
        icon: <UserCheck_01 size={iconSize === IconSizeEnum.DEFAULT ? 24 : 15} />,
        variantColorDiv: (theme) => theme.primary.fuchsia[10],
        variantColorIcon: (theme) => theme.primary.fuchsia[50],
      },
      [CandidateHistoryAction.Note]: {
        title: dictionary.noteAdded,
        icon: <File_05 size={iconSize === IconSizeEnum.DEFAULT ? 24 : 15} />,
        variantColorDiv: (theme) => theme.neutral.variant[5],
        variantColorIcon: (theme) => theme.neutral.variant[90],
      },
      [CandidateHistoryAction.SentToAts]: {
        title: dictionary.sentToAts,
        icon: <RobinIcon size={iconSize === IconSizeEnum.DEFAULT ? 24 : 15} />,
        variantColorDiv: (theme) => theme.neutral.variant[50],
        variantColorIcon: (theme) => theme.secondary.sky[50],
      },
      [CandidateHistoryAction.Shortlisted]: {
        title: dictionary.shortlisted,
        icon: <Bookmark size={iconSize === IconSizeEnum.DEFAULT ? 24 : 15} />,
        variantColorDiv: (theme) => theme.secondary.sky[10],
        variantColorIcon: (theme) => theme.secondary.sky[50],
      },
      [CandidateHistoryAction.Unlocked]: {
        title: dictionary.unlocked,
        icon: <RobinIcon size={iconSize === IconSizeEnum.DEFAULT ? 24 : 15} />,
        variantColorDiv: (theme) => theme.neutral.variant[50],
        variantColorIcon: (theme) => theme.secondary.sky[50],
      },

      [CandidateHistoryAction.EmailSent]: sourceMapping[CandidateOutreachSendMethod.Email],
      [CandidateHistoryAction.EmailReceived]: {
        title: dictionary.emailReceived,
        icon: <MailArrowDown size={iconSize === IconSizeEnum.DEFAULT ? 24 : 15} />,
        variantColorDiv: (theme) => theme.neutral.variant[5],
      },
      [CandidateHistoryAction.MessageReceived]: {
        title: dictionary.emailReceived,
        icon: <MailArrowDown size={iconSize === IconSizeEnum.DEFAULT ? 24 : 15} />,
        variantColorDiv: (theme) => theme.neutral.variant[5],
      },

      [CandidateHistoryAction.LinkedinSent]: sourceMapping[CandidateOutreachSendMethod.Linkedin],
      [CandidateHistoryAction.LinkedinReceived]: {
        title: dictionary.linkedIn,
        icon: <Linkedin size={iconSize === IconSizeEnum.DEFAULT ? 18 : 13} />,
        variantColorDiv: (theme) => theme.neutral.variant[5],
      },
      [CandidateHistoryAction.IndeedSent]: sourceMapping[CandidateOutreachSendMethod.Indeed],
      [CandidateHistoryAction.IndeedReceived]: {
        title: dictionary.indeedReceived,
        icon: <IndeedReceived size={iconSize === IconSizeEnum.DEFAULT ? 29 : 15} />,
        variantColorDiv: (theme) => theme.neutral.variant[5],
      },
      [CandidateHistoryAction.InMailSent]: sourceMapping[CandidateOutreachSendMethod.InMail],
      [CandidateHistoryAction.InMailReceived]: {
        title: dictionary.linkedInInMail,
        icon: <LinkedinFill size={iconSize === IconSizeEnum.DEFAULT ? 18 : 15} />,
        variantColorDiv: (theme) => theme.neutral.variant[5],
      },
      [CandidateHistoryAction.AtsCandidateContact]: {
        title: dictionary.atsEmailSent,
        icon: <AtsIcon ats={atsName} iconSize={iconSize} />,
        variantColorDiv: (theme) => theme.neutral.variant[10],
        variantColorIcon: (theme) => theme.neutral.variant[90],
      },
      [CandidateHistoryAction.AtsNoteCreated]: {
        title: dictionary.atsNoteAdded,
        icon: <AtsIcon ats={atsName} iconSize={iconSize} />,
        variantColorDiv: (theme) => theme.neutral.variant[10],
        variantColorIcon: (theme) => theme.neutral.variant[90],
      },
      [CandidateHistoryAction.AtsStatusChanged]: {
        title: dictionary.atsCandidateStatusChanged,
        icon: <AtsIcon ats={atsName} iconSize={iconSize} />,
        variantColorDiv: (theme) => theme.neutral.variant[10],
        variantColorIcon: (theme) => theme.neutral.variant[90],
      },
    }),
    [dictionary],
  );

  const variant = (source && sourceMapping[source]) || actionMapping[action];

  if (variant) {
    return {
      ...variant,
      variantColorDiv: variant.variantColorDiv || commonVariantColors.variantColorDiv,
      variantColorIcon: variant.variantColorIcon || commonVariantColors.variantColorIcon,
    } as HistoryCardVariantProps;
  }

  return {
    ...commonVariantColors,
    icon: null,
    title: '',
  };
};

export default useVariant;
