import { Styles, withStyles, css } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 8px 0px;
  `,
  labels: css`
    display: flex;
    align-items: center;
    gap: 8px;
  `,
  label: ({ theme }) => css`
    font-size: ${theme.fonts.heading[5].fontSize}px;
    font-weight: ${theme.fonts.heading[5].fontWeight};
  `,
  unreadConversationsAmount: css`
    color: white;
    background-color: #2f3e4c;
    padding: 2px 8px;
    border-radius: 8px;
  `,
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
