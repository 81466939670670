import { Styles, withStyles, css } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 12px;
    border-radius: 8px;
    overflow: hidden;
    background-color: ${theme.colors.neutral.gray[0]};
    cursor: pointer;

    &:hover {
      background-color: ${theme.colors.neutral.gray[10]};
    }

    &:active {
      background-color: ${theme.colors.neutral.gray[20]};
    }
  `,
  title: css`
    margin-bottom: 4px;
    max-width: 250px;
  `,
  subtitle: css`
    display: flex;
    align-items: center;
    max-width: 250px;
    gap: 6px;
    text-transform: uppercase;
  `,
  icon: ({ theme }) => css`
    color: ${theme.colors.neutral.gray[70]};
    height: 15px;
  `,
  dots: css`
    cursor: pointer;
    border-radius: 8px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: white;
    }
  `,
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
