import { Styles, withStyles, css } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: css`
    display: flex;
    width: 351px;
    border-radius: 16px;
    flex-direction: column;
    background-color: white;
    padding: 6px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow:
        0px 2px 3px 0px rgba(96, 113, 128, 0.1),
        0px 6px 6px 0px rgba(96, 113, 128, 0.03);
      border-radius: 16px;
      z-index: -2;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 100%;
      transform: translateY(50%) rotate(45deg);
      right: 40px;
      background-color: white;
      height: 17px;
      width: 17px;
      box-shadow:
        0px 2px 3px 0px rgba(96, 113, 128, 0.1),
        0px 6px 6px 0px rgba(96, 113, 128, 0.03);
      z-index: -1;
    }
  `,
  divider: css`
    display: block;
    margin: 12px 0px 4px;
    height: 1px;
    width: 100%;
    background-color: #dde3e7;
  `,
  flexAlignment: css`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  `,
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
