import type { ThemeColorCallback } from '@recruitrobin/robin-theme/web-styles';
import type { CandidateOutreachSendMethod } from 'clients/CampaignsClient/CampaignsClient.types';
import type { CandidateHistoryAction } from 'services/candidates/types';
import { Ats } from 'types/ats';

export type HistoryCardProps = {
  candidate: {
    name: string;
  };
  author: { name: string; campaignName?: string; from?: boolean; to?: string };
  content?: {
    subject?: string;
    message?: string;
  };
  historyId: number;
  action: CandidateHistoryAction;
  source: CandidateOutreachSendMethod | undefined;
  historyCreatedAt: Date;
  onReply: () => void;
  atsName?: Ats;
  metadata?: Record<string, any>;
};

export type IconSize = 'default' | 'small';

export enum IconSizeEnum {
  DEFAULT = 'default',
  SMALL = 'small',
}

export type HistoryCardVariantProps = {
  title: string;
  icon: React.ReactNode;
  variantColorDiv: ThemeColorCallback;
  variantColorIcon: ThemeColorCallback;
};
