import type { InboxConversationListProps } from '../CentralInbox.types';

import { useContext } from 'react';
import { InboxConversationListSkeleton } from './InboxConversationListSkeleton';
import { useStyles } from './InboxConversationList.styles';
import { InboxConversationListFilters } from './InboxConversationListFilters';
import { InboxConversationListHeader } from './InboxConversationListHeader';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { Typography } from '@recruitrobin/robin-theme/web-components';
import { InboxConversationListBody } from './InboxConversationListBody';
import useConversationsQuery from 'shared/hooks/useConversationsQuery';
import { QueryKey } from 'types/query';

export const InboxConversationList = ({
  showTeamView = true,
  showFilters = true,
  conversations = [],
}: InboxConversationListProps) => {
  const classes = useStyles();
  const { dictionary } = useContext(LocalizationContext);
  const { isLoading } = useConversationsQuery({ cacheKey: QueryKey.getConversationsPreview });

  return (
    <div css={classes.root}>
      <InboxConversationListHeader showTeamView={showTeamView} />
      <div css={classes.divider} />
      {showFilters && <InboxConversationListFilters />}
      {isLoading ? (
        <InboxConversationListSkeleton />
      ) : conversations.length > 0 ? (
        <InboxConversationListBody conversations={conversations} />
      ) : (
        <div css={classes.flexAlignment}>
          <Typography variant={(v) => v.heading[5]} color={(v) => v.neutral.gray[50]}>
            {dictionary.emptyConversations}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default InboxConversationList;
