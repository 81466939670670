import { BaseClient } from 'clients/BaseClient/BaseClient';

import { hosts } from 'config';

import {
  AtsCredentialsOverviewHandler,
  AtsDropdownHandler,
  AtsFieldsHandler,
  AtsPhasesHandler,
  CreateAtsCredentialsHandler,
  DeleteAtsCredentialsHandler,
  GetDepartmentsHandler,
  OAuth2AtsCredentialHandler,
  PullStatusesHandler,
  ToggleAtsCredentialsHandler,
  UpdateAtsCredentialsHandler,
  ValidateAtsConnectionResponseOk,
  type GetAvailableAtsToPullStatuses,
  type GetStatusesHandler,
  type SaveStatusesHandler,
} from './types';

export class ATSIntegrationsClient extends BaseClient {
  constructor() {
    super();
  }

  getAll: AtsCredentialsOverviewHandler = () => {
    return this.request({
      url: `${hosts.atsGatewayApi()}/ats_credentials/overview/`,
      method: 'GET',
    });
  };

  getFields: AtsFieldsHandler = (params) => {
    return this.request({
      url: `${hosts.atsGatewayApi()}/ats/fields`,
      method: 'GET',
      params,
    });
  };

  getPhases: AtsPhasesHandler = () => {
    return this.request({
      url: `${hosts.atsGatewayApi()}/ats/phases`,
      method: 'GET',
    });
  };

  getDropdown: AtsDropdownHandler = () => {
    return this.request({
      url: `${hosts.atsGatewayApi()}/ats/`,
      method: 'GET',
    });
  };

  patch: UpdateAtsCredentialsHandler = ({ id, ...data }) => {
    return this.request({
      url: `${hosts.atsGatewayApi()}/ats_credentials/${id}/`,
      method: 'PATCH',
      data,
    });
  };

  post: CreateAtsCredentialsHandler = (data) => {
    return this.request({
      url: `${hosts.atsGatewayApi()}/ats_credentials/`,
      method: 'POST',
      data,
    });
  };

  hasValidATSConnected = async () =>
    this.request<ValidateAtsConnectionResponseOk>({
      url: `${hosts.atsGatewayApi()}/users/validate_ats_connection`,
      method: 'GET',
    });

  delete: DeleteAtsCredentialsHandler = (id) => {
    return this.request({
      url: `${hosts.atsGatewayApi()}/ats_credentials/${id}/`,
      method: 'DELETE',
    });
  };

  toggle: ToggleAtsCredentialsHandler = ({ id, is_active }) => {
    return this.request({
      url: `${hosts.atsGatewayApi()}/ats_credential_user/`,
      method: 'POST',
      data: {
        ats_credential_id: id.toString(),
        is_active,
      },
    });
  };

  oauth2: OAuth2AtsCredentialHandler = ({ code, atsCredential, atsName }) => {
    return this.request({
      url: `${hosts.atsGatewayApi()}/ats_credentials/oauth2/connect/${atsName}/`,
      method: 'POST',
      data: {
        code,
        ats_credential: atsCredential,
      },
    });
  };

  getDepartments: GetDepartmentsHandler = (email) => {
    return this.request({
      url: `${hosts.atsGatewayApi()}/users/${email}/departments/`,
      method: 'GET',
    });
  };

  getStatuses: GetStatusesHandler = (tenantId, atsName) => {
    return this.request({
      url: `${hosts.atsGatewayApi()}/ats_statuses/${tenantId}/?ats=${atsName}`,
      method: 'GET',
    });
  };

  saveStatuses: SaveStatusesHandler = (tenantId, statuses) => {
    return this.request({
      url: `${hosts.atsGatewayApi()}/ats_statuses/${tenantId}/`,
      method: 'POST',
      data: statuses,
    });
  };

  pullStatuses: PullStatusesHandler = (tenantId, atsName) => {
    return this.request({
      url: `${hosts.atsGatewayApi()}/ats_statuses/pull`,
      method: 'POST',
      data: {
        tenant_id: tenantId,
        ats_name: atsName,
      },
    });
  };

  getAvailableAtsToPullStatuses: GetAvailableAtsToPullStatuses = () => {
    return this.request({
      url: `${hosts.atsGatewayApi()}/ats/robin_resume/`,
      method: 'GET',
    });
  };
}

export const atsIntegrationsClient = new ATSIntegrationsClient();
