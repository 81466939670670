import useConversationsQuery from 'shared/hooks/useConversationsQuery';
import { InboxConversationList } from '../InboxConversationList';
import { QueryKey } from 'types/query';

export const InboxConversationPreview = () => {
  const {
    data: { results: conversations },
  } = useConversationsQuery({ cacheKey: QueryKey.getConversationsPreview });

  return <InboxConversationList showTeamView={false} showFilters={false} conversations={conversations} />;
};

export default InboxConversationPreview;
