import { Styles, withStyles, css } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: css`
    display: flex;
    flex-direction: column;
    gap: 12px;
  `,
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
