import { FocusEvent, FormEvent, forwardRef, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Eye, EyeOff } from '@recruitrobin/robin-theme/web-icons';

import { InputAdornment as MuiInputAdornment, OutlinedInput as MuiTextField } from '@material-ui/core';
import { useStyles } from './TextField.styles';
import { Props, TextFieldTypes } from './types';
import { validateEmail, validatePhone } from './validations';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import KeypressClassToggler from 'helpers/keypressClassToggler';
import { formatPhoneDutch } from './formatters';
import { default as InputLabelWrapper } from 'shared/components/InputLabelWrapper';
import { generateDtiAttribute } from 'helpers/helpers';
import { Tooltip } from 'shared/components/Tooltip';
import { default as FormHelperError } from 'shared/components/FormHelperError';

type DependenciesType = object & { init?: any; dispose?: () => void };

type TextFieldKeyObject<T> = { [key in TextFieldTypes]: T };

const initDepsObj: TextFieldKeyObject<{ [key: string]: DependenciesType }> & { initialized: Array<TextFieldTypes> } = {
  email: {},
  password: {},
  textarea: {},
  textfield: {},
  number: {},
  phonenumber: {},
  initialized: [],
};
const TextFieldWithoutRef = (
  {
    label,
    tooltipLabel,
    name,
    onChange,
    onBlur,
    defaultValue,
    value: parentValue,
    setError: setErrorFromParent,
    hasError: errorFromParent,
    errorMessage: errorMessageFromParent,
    maxLength,
    contained = false,
    placeholder = '',
    type = 'textfield',
    required = false,
    disabled = false,
    autoFocus = false,
    onEnter,
    startAdornment,
    onFocus,
    'data-tutorial-id': dataTutorialId,
  }: Props,
  ref: React.ForwardedRef<HTMLElement | null>,
) => {
  const classes = useStyles({ disabled, contained });
  const textFieldRef = useRef<HTMLElement | null>(null);
  const [textFieldId, setTextFieldId] = useState('');
  const [_value, setValue] = useState(parentValue || defaultValue || '');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [shouldValidate, setShouldValidate] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const passwordButton = useRef(null);
  const initDepsRefs = useRef(initDepsObj);
  const { dictionary } = useContext(LocalizationContext);

  const value = typeof parentValue !== 'undefined' ? parentValue : _value;

  useEffect(() => {
    const id = Math.floor(Math.random() * 10000 * Date.now()).toString(16);
    setTextFieldId(`text-field-${id}`);

    return () => {
      disposeDeps();
    };
  }, []);

  useEffect(() => {
    const tf = textFieldRef.current;

    if (tf) {
      const preventSpacePropagation: HTMLElementEventHandler<'keydown'> = (e) => {
        if ([' '].includes(e.key)) {
          e.stopPropagation();
        }
      };

      tf.addEventListener('keydown', preventSpacePropagation);

      return () => tf.removeEventListener('keydown', preventSpacePropagation);
    }
  }, []);

  const onTextFieldRefChange = useCallback((node: HTMLElement | null) => {
    textFieldRef.current = node;

    if (typeof ref === 'function') {
      return ref(node);
    }

    if (ref && typeof ref === 'object') {
      return (ref.current = node);
    }
  }, []);

  /** Init dependencies for each type of input */
  const initDeps = useCallback(() => {
    const checkIfTypeAlreadyLoaded = (t: TextFieldTypes) => type === t && !initDepsRefs.current.initialized.includes(t);

    if (checkIfTypeAlreadyLoaded('password')) {
      initDepsRefs.current['password'] = {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        passwordTogglerButtonKeypress: new KeypressClassToggler(passwordButton.current!, {
          Enter: { onKeyUp: (el) => el.click() },
        }).init(),
      };

      initDepsRefs.current.initialized.push('password');
    }
  }, [type]);

  /** Dispose dependencies for each type of input */
  const disposeDeps = () => {
    const types = Object.entries(initDepsRefs.current)
      .filter(([key]) => key !== 'initialized')
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map(([_, value]) => value);

    types.forEach((typeDeps) => {
      Object.values(typeDeps).forEach((value: DependenciesType) => {
        value?.dispose?.();
      });
    });

    initDepsRefs.current = initDepsObj;
  };

  useEffect(() => {
    initDeps();
  }, [initDeps, type]);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const validate = (newValue: string = value) => {
    const validations: Array<{ hasError: boolean; message: string }> = [
      {
        hasError: type === 'email' && newValue !== '' && !validateEmail(newValue),
        message: dictionary.invalidEmailAddress,
      },
      {
        hasError: type === 'phonenumber' && newValue.length > 0 && !validatePhone(newValue),
        message: dictionary.invalidPhoneNumber,
      },
    ];
    const { hasError = false, message = '' } = validations.find(({ hasError }) => hasError) || {};

    if (error !== hasError) {
      setError(hasError);
      setErrorMessage(message);
      setErrorFromParent?.(message);
    }
  };

  const validateValueChange = (value = '') => {
    if (type === 'phonenumber') {
      return formatPhoneDutch(value).slice(0, 15);
    }
    const newValue = maxLength ? value.slice(0, maxLength) : value;

    return newValue;
  };

  const handleOnInput = (e: FormEvent<HTMLInputElement> & { target: HTMLInputElement }) => {
    const newValue = validateValueChange(e.target.value);

    if (shouldValidate) {
      validate(newValue || '');
    }

    setValue(newValue);
    onChange?.(newValue);
  };

  const handleOnBlur = (e: FocusEvent<HTMLInputElement> & { target: HTMLInputElement }) => {
    const newValue = validateValueChange(e.target.value);
    setShouldValidate(true);
    validate(newValue);

    setValue(newValue);
    onBlur?.();
  };

  const handleOnKeyUp: React.KeyboardEventHandler = (e) => {
    if (e.key === 'Enter') {
      onEnter?.();
    }
  };

  const handleOnFocus: React.FocusEventHandler = () => {
    onFocus?.();
  };

  return (
    <div css={classes.root} {...generateDtiAttribute(dataTutorialId, 'text-field')}>
      <InputLabelWrapper label={label} tooltipLabel={tooltipLabel} htmlFor={textFieldId} disabled={disabled} />
      <MuiTextField
        ref={onTextFieldRefChange}
        id={textFieldId}
        name={name}
        value={parentValue || value}
        multiline={type === 'textarea'}
        type={type === 'password' && !showPassword ? 'password' : 'text'}
        placeholder={placeholder}
        disabled={disabled}
        required={required}
        onInput={handleOnInput}
        onBlur={handleOnBlur}
        onKeyUp={handleOnKeyUp}
        onFocus={handleOnFocus}
        aria-disabled={disabled}
        aria-required={required}
        autoFocus={autoFocus}
        startAdornment={
          startAdornment && (
            <MuiInputAdornment component="div" position="start">
              {startAdornment}
            </MuiInputAdornment>
          )
        }
        endAdornment={
          type === 'password' && (
            <Tooltip title={showPassword ? dictionary.hidePassword : dictionary.showPassword} disabled={disabled}>
              <MuiInputAdornment
                ref={passwordButton}
                position="end"
                component="button"
                onClick={(!disabled && toggleShowPassword) || undefined}
                css={classes.passwordToggler}
                type="button"
                {...generateDtiAttribute('adornment')}>
                {showPassword ? <EyeOff size={18} /> : <Eye size={18} />}
              </MuiInputAdornment>
            </Tooltip>
          )
        }
        aria-describedby={`error-${textFieldId}`}
        error={error || errorFromParent}
        {...generateDtiAttribute('input')}
      />
      {(error || errorFromParent) && (
        <FormHelperError id={`error-${textFieldId}`} message={errorMessage || errorMessageFromParent} />
      )}
    </div>
  );
};

/**
 * @deprecated use Input from '@recruitrobin/robin-theme/web-components';
 */
const TextField = forwardRef<HTMLElement, Props>(TextFieldWithoutRef);

export default TextField;
