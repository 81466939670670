import { browserExtensionClient } from 'clients/BrowserExtensionClient/BrowserExtensionClient';

export const getTokenFromBrowserExtension = async (): Promise<string | null> => {
  const response = await browserExtensionClient.sendMessage('readLocalStorage', {
    key: 'token',
  });

  if (Object.keys(response).length === 0) {
    return null;
  }

  return response.value;
};

export const getIsLoggedInFromBrowserExtension = async (): Promise<string | null> => {
  const response = await browserExtensionClient.sendMessage('readLocalStorage', {
    key: 'isLoggedIn',
  });

  if (Object.keys(response).length === 0) {
    return null;
  }

  return response.value;
};
