import { hosts } from 'config';
import { BaseClient } from 'clients/BaseClient/BaseClient';
import { type GetConversationsHandler } from './ConversationsClient.types';
import { convertKeysToSnakeCase, formatQueryParams } from 'utils/shared';

export class ConversationsClient extends BaseClient {
  getConversations: GetConversationsHandler = (params) => {
    const formattedParams = formatQueryParams(convertKeysToSnakeCase(params));
    return this.request({
      url: `${hosts.notificationsApi()}/conversations/${formattedParams}`,
      method: 'GET',
    });
  };
}

export const conversationsClient = new ConversationsClient();
