import type { InviteSummaryResponse, LinkedinConfig } from './types';

import { useQuery } from '@tanstack/react-query';
import { browserExtensionClient } from 'clients/BrowserExtensionClient/BrowserExtensionClient';
import { logger } from 'config/logger';
import {
  BrowserExtensionInfoStatus,
  useBrowserExtensionStatusQuery,
} from 'hooks/shared/useBrowserExtensionStatusQuery';
import { QueryKey } from 'types/query';

export const useLinkedinConfig = () => {
  const browserExtensionStatusQuery = useBrowserExtensionStatusQuery();

  const { data } = useQuery<LinkedinConfig>(
    [QueryKey.useLinkedinConfig],
    async () => {
      const linkedinId = await getLinkedinUserId();

      if (!linkedinId) {
        return { isLoggedIn: false, status: 'Logged Out' };
      }

      return { isLoggedIn: true, status: 'Logged In', linkedinId };
    },
    {
      enabled: browserExtensionStatusQuery.data.status === BrowserExtensionInfoStatus.Active,
      initialData: { isLoggedIn: false, status: 'Unknown' },
      initialDataUpdatedAt: 0,
      retry: 0,
    },
  );

  return data;
};

const getLinkedinUserId = async () => {
  try {
    const response = await browserExtensionClient.sendMessage('proxyHttpRequest', {
      url: 'https://www.linkedin.com/voyager/api/relationships/invitationsSummary',
      addHeadersFrom: 'linkedInClassic',
      headers: {
        Accept: 'application/vnd.linkedin.normalized+json+2.1',
        'accept-encoding': 'gzip, deflate, br, zstd',
      },
    });

    if (response.status !== 200) return null;

    const body = JSON.parse(response.body) as InviteSummaryResponse;
    const linkedinId = body.data.entityUrn.split(':').at(-1);

    return linkedinId;
  } catch (e) {
    console.error(e);
    logger.error({
      error: e,
      customMessage: 'Error when verifying linkedin logged in',
    });

    return null;
  }
};
