import type { ExistsLinkedinConversationWithCandidateResult } from './linkedin/types';
import type {
  ExistsLinkedinConversationWithCandidateResponse,
  LinkedinCreditsResponse,
  SendLinkedinConnectionRequestParams,
  SendLinkedinProMessageParams,
  UseBrowserExtension,
} from './types';

import { useQuery } from '@tanstack/react-query';
import { browserExtensionClient } from 'clients/BrowserExtensionClient/BrowserExtensionClient';
import { crawlerClient } from 'clients/CrawlerClient/CrawlerClient';
import { useGTM } from 'hooks/gtm';
import {
  BrowserExtensionInfoStatus,
  useBrowserExtensionStatusQuery,
} from 'hooks/shared/useBrowserExtensionStatusQuery';
import { useEffect } from 'react';
import { useLinkedinConfig } from './linkedin/useLinkedinConfig';

export const testUrls = {
  linkedinPro: 'https://www.linkedin.com/talent/api/talentPageContent/account_overview_highlight',
};

export const creditUrls = {
  linkedin:
    'https://www.linkedin.com/voyager/api/graphql?variables=()&queryId=voyagerRelationshipsDashCustomInviteComposeView.853d72a59b13b61229e9a90d2a42317b',
  linkedinPro:
    'https://www.linkedin.com/talent/api/talentMessageComposeInfo?decoration=%28inMailCreditInfo%28creditBalance%2CnextRefreshDate%29%29',
  indeed: 'https://resumes.indeed.com/api/common-model',
};

let statusSent = false;

export const useBrowserExtension = (): UseBrowserExtension => {
  const gtm = useGTM();
  const { data: info } = useBrowserExtensionStatusQuery();

  useEffect(() => {
    if (statusSent === false && info?.status === BrowserExtensionInfoStatus.Active) {
      statusSent = true;
      gtm.browserExtensionActivated();
    }
  }, [info?.status]);

  useLinkedinConfig();

  const { data: { linkedinCreditBalance } = {} } = useQuery(
    ['userPlatformCredits'],
    async () => {
      const creditBalance = await getLinkedInCredits();

      return { linkedinCreditBalance: creditBalance };
    },
    { enabled: info?.status === BrowserExtensionInfoStatus.Active },
  );

  const sendLinkedinConnectionRequest = async (data: SendLinkedinConnectionRequestParams): Promise<any> => {
    return browserExtensionClient.sendMessage('proxyHttpRequest', {
      url: 'https://www.linkedin.com/voyager/api/voyagerRelationshipsDashMemberRelationships?action=verifyQuotaAndCreate',
      method: 'POST',
      body: JSON.stringify(data),
      addHeadersFrom: 'linkedInClassic',
      headers: {
        accept: 'application/vnd.linkedin.normalized+json+2.1',
        'accept-encoding': 'gzip, deflate, br, zstd',
      },
    });
  };

  const existsLinkedinConversationWithCandidate = async (
    candidateId: string,
  ): Promise<ExistsLinkedinConversationWithCandidateResult | undefined> => {
    const response = await browserExtensionClient.sendMessage('proxyHttpRequest', {
      url: `https://www.linkedin.com/voyager/api/voyagerMessagingDashComposeOptions/urn%3Ali%3Afsd_composeOption%3A(${candidateId}%2CNONE%2CEMPTY_CONTEXT_ENTITY_URN)`,
      method: 'GET',
      addHeadersFrom: 'linkedInClassic',
      headers: {
        accept: 'application/vnd.linkedin.normalized+json+2.1',
        'accept-encoding': 'gzip, deflate, br, zstd',
      },
    });

    if (!response.body) {
      return undefined;
    }

    const result: ExistsLinkedinConversationWithCandidateResponse = JSON.parse(response.body);

    if (result.data.composeNavigationContext?.existingConversationUrn) {
      return {
        threadId: result.data.composeNavigationContext.existingConversationUrn.split(':fsd_conversation:')[1],
        isFirstConversation: false,
      };
    }

    if (result.data.composeOptionType === 'CONNECTION_MESSAGE') {
      return { candidateId, isFirstConversation: true };
    }

    return undefined;
  };

  const existsLinkedinInmailConversationWithCandidate = async (candidateId: string): Promise<string | undefined> => {
    // Fetch candidate information from LinkedIn recruiter
    const candidateData = await crawlerClient.getLinkedinCandidate(candidateId);
    const candidateRecruiterId = candidateData?.data?.candidate?.id;

    if (!candidateRecruiterId) {
      return undefined;
    }

    // Build the recruiting profile URL
    const recruitingProfileUrl = buildRecruitingProfileUrl(candidateRecruiterId);

    // Send GET request to the recruiting profile URL
    const profileResponse = await sendProxyHttpRequest(recruitingProfileUrl);
    if (!profileResponse) {
      return undefined;
    }

    // Filter accepted messages
    const acceptedMessages = filterAcceptedMessages(profileResponse.candidateMessageThreads);
    if (acceptedMessages.length === 0) {
      return undefined;
    }

    // Extract and encode the candidate message thread ID
    const candidateMessageThreadId = extractThreadId(acceptedMessages[0].entityUrn);
    if (!candidateMessageThreadId) {
      return undefined;
    }
    const encodedThreadId = encodeThreadId(candidateMessageThreadId);

    // Build the GraphQL URL
    const graphqlUrl = buildGraphqlUrl(encodedThreadId);

    // Send GET request to the GraphQL URL
    const threadResponse = await sendProxyHttpRequest(graphqlUrl);
    if (!threadResponse) {
      return undefined;
    }

    // Extract the thread ID from the response
    const threadId = extractConversationThreadId(threadResponse);
    return threadId;
  };

  // Helper function to build the recruiting profile URL
  const buildRecruitingProfileUrl = (candidateRecruiterId: string): string => {
    return `https://www.linkedin.com/talent/api/talentRecruitingProfiles/urn%3Ali%3Ats_hiring_recruiting_profile%3A(urn%3Ali%3Ats_contract%3A320744933%2Curn%3Ali%3Ats_profile%3A${candidateRecruiterId})?altkey=urn&decoration=%28entityUrn%2CcandidateMessageThreads*%28subject%2Ccandidate%2CentityUrn%2Cpreview%2ClastInboxSentTime%2CinboxType%2Cvisibility%2CmessageState%2Ccreated%28time%2Cactor~%28entityUrn%2Cprofile~%28entityUrn%2CfirstName%2ClastName%2Cheadline%2CprofilePicture%2CvectorProfilePicture%2CpublicProfileUrl%2CfollowerCount%2CnetworkDistance%2CautomatedActionProfile%29%29%29%29%29`;
  };

  // Helper function to send a proxy HTTP GET request
  const sendProxyHttpRequest = async (url: string): Promise<any> => {
    const response = await browserExtensionClient.sendMessage('proxyHttpRequest', {
      url,
      method: 'GET',
      addHeadersFrom: 'linkedInClassic',
      headers: {
        accept: 'application/json',
        'accept-encoding': 'gzip, deflate, br, zstd',
      },
    });
    if (response.status !== 200) {
      return undefined;
    }
    return JSON.parse(response.body);
  };

  // Helper function to filter accepted messages
  const filterAcceptedMessages = (threads: any[]): any[] => {
    return threads.filter((message) => message.messageState === 'ACCEPTED');
  };

  // Helper function to extract the thread ID from the entity URN
  const extractThreadId = (entityUrn: string): string | undefined => {
    const parts = entityUrn.split(':ts_candidate_message_thread:');
    return parts[1];
  };

  // Helper function to encode the thread ID
  const encodeThreadId = (id: string): string => {
    return encodeURIComponent(id).replace(/\(/g, '%28').replace(/\)/g, '%29');
  };

  // Helper function to build the GraphQL URL
  const buildGraphqlUrl = (encodedThreadId: string): string => {
    return `https://www.linkedin.com/talent/api/graphql?variables=(candidate:urn%3Ali%3Ats_hire_identity%3A466509561,thread:urn%3Ali%3Ats_candidate_message_thread%3A${encodedThreadId},start:0,count:50)&queryId=talentCandidateMessages.ecf52263fcb49f6588e4e1110836e741`;
  };

  // Helper function to extract the conversation thread ID from the response
  const extractConversationThreadId = (response: any): string | undefined => {
    const inmailThreadUrl = response?.data?.candidateMessagesByThread?.elements?.[0]?.inmailThreadUrl;
    return inmailThreadUrl?.split('/conversation/')[1];
  };

  const sendLinkedinProMessage = ({
    subject,
    body,
    signature,
    candidate_id,
    mailThread,
  }: SendLinkedinProMessageParams): Promise<any> => {
    let data = {};

    if (mailThread) {
      data = {
        subject,
        body,
        mailThread: `urn:li:ts_mail_thread:${mailThread}`,
        postOption: {
          value: {
            'com.linkedin.talent.message.InMailPost': {
              recipient: `urn:li:ts_profile:${candidate_id}`,
            },
          },
        },
      };
    } else {
      data = {
        elements: [
          {
            subject,
            body,
            signature,
            postOption: {
              value: {
                'com.linkedin.talent.message.InMailPost': {
                  recipient: `urn:li:ts_profile:${candidate_id}`,
                },
              },
            },
          },
        ],
      };
    }

    return browserExtensionClient.sendMessage('proxyHttpRequest', {
      url: 'https://www.linkedin.com/talent/api/talentMessagePosts',
      method: 'POST',
      body: JSON.stringify(data),
      addHeadersFrom: 'linkedInClassic',
      headers: {
        accept: 'application/vnd.linkedin.normalized+json+2.1',
        'accept-encoding': 'gzip, deflate, br, zstd',
        ...(!mailThread && { 'x-restli-method': 'BATCH_CREATE' }),
      },
    });
  };

  const getLinkedInCredits = async () => {
    const response = await browserExtensionClient.sendMessage('proxyHttpRequest', {
      url: creditUrls.linkedinPro,
      method: 'GET',
      addHeadersFrom: 'linkedInClassic',
      headers: {
        accept: 'application/vnd.linkedin.normalized+json+2.1',
        'accept-encoding': 'gzip, deflate, br, zstd',
        'x-li-pem-metadata':
          'Hiring Platform - Account Settings - Messaging settings=fetch-message-compose-info-navigation-permissions',
      },
    });

    const linkedinCreditsResponse: LinkedinCreditsResponse = JSON.parse(response.body);
    const creditBalance = linkedinCreditsResponse?.data?.inMailCreditInfo?.creditBalance ?? 0;

    return creditBalance;
  };

  return {
    sendLinkedinConnectionRequest,
    existsLinkedinConversationWithCandidate,
    existsLinkedinInmailConversationWithCandidate,
    sendLinkedinProMessage,
    linkedinCreditBalance,
  };
};
