import { useQuery } from '@tanstack/react-query';
import { browserExtensionClient } from 'clients/BrowserExtensionClient/BrowserExtensionClient';
import { QueryKey } from 'types/query';

export enum BrowserExtensionInfoStatus {
  Active = 'Active',
  'Not Installed' = 'Not Installed',
}

type BrowserExtensionInfo = {
  manifest?: object;
  version?: string;
  status?: BrowserExtensionInfoStatus | string;
};

const unknownData: BrowserExtensionInfo = {
  status: BrowserExtensionInfoStatus['Not Installed'],
};

export const useBrowserExtensionStatusQuery = () => {
  return useQuery(
    [QueryKey.useBrowserExtensionStatusQuery],
    async () => {
      const response = await getExtensionStatus();

      return response;
    },
    { initialData: unknownData, initialDataUpdatedAt: 0 },
  );
};

export const getExtensionStatus = async () => {
  const response = await browserExtensionClient.sendMessage<Partial<BrowserExtensionInfo>>('getExtensionStatus');

  if (Object.keys(response).length === 0) {
    return unknownData;
  }

  return response;
};
