import { ExtensionNotInstalledException, StopExecution, UnsupportedBrowserException } from 'shared/exceptions';
import { fancyLog, LOG_LEVEL, logger } from './logger';
import { LinkedinCSRFTokenCheckFailedException } from 'shared/exceptions/LinkedinException';
import { IndeedServiceResultNullException } from 'shared/exceptions/IndeedException';

export const injectScripts = () => {
  const getNewRelicEnv = () => {
    const nrEnv = process.env.REACT_APP_NEW_RELIC_ENV as 'local' | 'beta' | 'acceptance' | 'prod' | undefined;

    if (nrEnv !== 'local' && nrEnv !== 'beta' && nrEnv !== 'acceptance' && nrEnv !== 'prod') {
      throw 'Newrelic env is missing!';
    }

    return nrEnv;
  };

  const SCRIPT_PATHS: Array<{ path: string; onload?: GlobalEventHandlers['onload'] }> = [
    { path: '/static/hcb.js' },
    {
      path: `/static/newrelic.${getNewRelicEnv()}.js`,
      onload: () => {
        newrelic.setCustomAttribute('version', __COMMIT_HASH__);
        fancyLog({ level: LOG_LEVEL.INFO, prefix: 'App version' }, __COMMIT_HASH__);
        newrelic.addRelease(`[${__COMMIT_MESSAGE__}]`, `${__COMMIT_HASH__}`);
        newrelic.setErrorHandler((err) => {
          const shouldShowInConsole =
            process.env.REACT_APP_ENVIRONMENT !== 'prod' || localStorage.getItem('REACT_APP_LOGGER_DEBUG') === '1';
          const shouldIgnoreError = IGNORED_EXCEPTIONS.some((exception) => err instanceof exception);

          if (shouldShowInConsole) {
            fancyLog(
              shouldIgnoreError
                ? { level: LOG_LEVEL.INFO, prefix: 'IGNORED - Error' }
                : { level: LOG_LEVEL.ERROR, prefix: 'SENT - Error' },
              err,
            );
          }

          return shouldIgnoreError;
        });

        logger.init();
      },
    },
  ];

  SCRIPT_PATHS.forEach(({ path, onload }) => {
    const script = document.createElement('script');
    if (onload) {
      script.onload = onload;
    }

    script.src = path;
    document.head.appendChild(script);
  });
};

const IGNORED_EXCEPTIONS = [
  UnsupportedBrowserException,
  ExtensionNotInstalledException,
  LinkedinCSRFTokenCheckFailedException,
  IndeedServiceResultNullException,
  StopExecution,
] as const;
