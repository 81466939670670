import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryClient } from '@tanstack/react-query';
import { createContext } from 'react';

const PERSISTED_QUERY_CLIENT_CACHE_TIME_24_HOURS = 1000 * 60 * 60 * 24;

export const persistedQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: PERSISTED_QUERY_CLIENT_CACHE_TIME_24_HOURS,
      staleTime: Infinity,
    },
  },
});

export const persistedQueryClientContext = createContext<QueryClient | undefined>(persistedQueryClient);

export const persistedQueryClientPersister = createSyncStoragePersister({
  storage: localStorage,
});
