import type { EmailSettingsProps, JobBoardSettingsType } from 'shared/contexts/UserContext/types';
import type {
  AssociateIndeedLicenseHandler,
  ChangeCurrentUserPasswordHandler,
  CreateFeatureTogglesHandler,
  CreateUserEmailSignatureHandler,
  CreateUserHandler,
  DeleteFeatureTogglesHandler,
  DeleteUserEmailSignatureHandler,
  DeleteUserHandler,
  GetCreditsHandler,
  GetCurrentUserHandler,
  GetFeatureTogglesHandler,
  GetIndeedLicenseAuditHandler,
  GetIndeedUserLicenseHandler,
  GetTenantCurrentUserHandler,
  GetUserEmailSignatureHandler,
  GetUserHandler,
  IndexUserHandler,
  LoginHandler,
  LoginTenantHandler,
  NotifyUserIndeedLicenseStatusChangedHandler,
  ResetPasswordHandler,
  SendPasswordResetCodeHandler,
  UpdateCurrentUserHandler,
  UpdatedIndeedLicenseList,
  UpdateFeatureTogglesHandler,
  UpdateIndeedLicenseStatus,
  UpdateUserEmailSignatureHandler,
  UpdateUserHandler,
  UpdateUserWithTokenHandler,
  ValidateEmailAndPasswordHandler,
  ValidateMfaCodeHandler,
  ValidatePasswordResetCodeHandler,
} from './types';

import { BaseClient } from 'clients/BaseClient/BaseClient';
import { hosts } from 'config';

export class UserClient extends BaseClient {
  constructor() {
    super();
    this.index = this.index.bind(this);
    this.create = this.create.bind(this);
    this.update = this.update.bind(this);
    this.updateWithToken = this.updateWithToken.bind(this);
    this.delete = this.delete.bind(this);
    this.get = this.get.bind(this);
    this.getCurrent = this.getCurrent.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.sendPasswordResetCode = this.sendPasswordResetCode.bind(this);
    this.validatePasswordResetCode = this.validatePasswordResetCode.bind(this);
    this.login = this.login.bind(this);
    this.getCredits = this.getCredits.bind(this);
    this.updateCurrentUser = this.updateCurrentUser.bind(this);
    this.changeCurrentUserPassword = this.changeCurrentUserPassword.bind(this);
    this.getTenantCurrentUser = this.getTenantCurrentUser.bind(this);

    this.loginTenant = this.loginTenant.bind(this);
    this.getEmailSettings = this.getEmailSettings.bind(this);
    this.createEmailSettings = this.createEmailSettings.bind(this);
    this.updateEmailSettings = this.updateEmailSettings.bind(this);
    this.getJobBoardSettings = this.getJobBoardSettings.bind(this);
    this.createJobBoardSettings = this.createJobBoardSettings.bind(this);
    this.updateJobBoardSettings = this.updateJobBoardSettings.bind(this);
  }

  index: IndexUserHandler = ({ search = '', limit = 10, offset = 0, is_active = false, ordering }) => {
    return this.request({
      url: `${hosts.userApi()}/users/`,
      method: 'GET',
      params: { search, limit, offset, ...(is_active && { is_active }), ...(ordering && { ordering }) },
    });
  };

  create: CreateUserHandler = (data) => {
    return this.request({
      url: `${hosts.userApi()}/users/`,
      method: 'POST',
      data,
    });
  };

  update: UpdateUserHandler = ({ id, ...data }) => {
    return this.request({
      url: `${hosts.userApi()}/users/${id}/`,
      method: 'PATCH',
      data,
    });
  };

  updateWithToken: UpdateUserWithTokenHandler = ({ id, ...data }, token) => {
    return this.request({
      url: `${hosts.userApi()}/users/${id}/`,
      method: 'PATCH',
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  delete: DeleteUserHandler = (id) => {
    return this.request({
      url: `${hosts.userApi()}/users/${id}/`,
      method: 'DELETE',
    });
  };

  get: GetUserHandler = (id) => {
    return this.request({
      url: `${hosts.userApi()}/users/${id}/`,
      method: 'GET',
    });
  };

  getCurrent: GetCurrentUserHandler = () => {
    return this.request({
      url: `${hosts.userApi()}/users/profile/`,
      method: 'GET',
    });
  };

  getIndeedLicense: GetIndeedUserLicenseHandler = (id) => {
    return this.request({
      url: `${hosts.userApi()}/indeed_license/${id ? `${id}/` : ''}`,
      method: 'GET',
    });
  };

  updateIndeedLicenseList: UpdatedIndeedLicenseList = (licenses) => {
    return this.request({
      url: `${hosts.userApi()}/indeed_license/batch_create_update_or_soft_delete/`,
      method: 'PATCH',
      data: { license_ids: licenses },
    });
  };

  updateIndeedLicenseStatus: UpdateIndeedLicenseStatus = (license_id, status) => {
    return this.request({
      url: `${hosts.userApi()}/indeed_license/${license_id}/update_status/`,
      method: 'PATCH',
      data: { status },
    });
  };

  associateIndeedLicense: AssociateIndeedLicenseHandler = ({ id, user_id }) => {
    return this.request({
      url: `${hosts.userApi()}/indeed_license/${id}/`,
      method: 'PATCH',
      data: { user_id },
    });
  };

  getIndeedLicenseAudit: GetIndeedLicenseAuditHandler = (user_id) => {
    return this.request({
      url: `${hosts.userApi()}/indeed_license/audit/`,
      method: 'GET',
      params: { user_id },
    });
  };

  notifyUserIndeedLicenseStatusChanged: NotifyUserIndeedLicenseStatusChangedHandler = ({ user_id, email_type }) => {
    return this.request({
      url: `${hosts.userApi()}/indeed_license/notify_user/`,
      method: 'POST',
      data: { user_id, email_type },
    });
  };

  resetPassword: ResetPasswordHandler = (data) => {
    return this.request({
      url: `${hosts.userApi()}/users/password/reset/`,
      method: 'POST',
      data,
    });
  };

  sendPasswordResetCode: SendPasswordResetCodeHandler = (data) => {
    return this.request({
      url: `${hosts.userApi()}/users/password/send_reset_code/`,
      method: 'POST',
      data,
    });
  };

  validatePasswordResetCode: ValidatePasswordResetCodeHandler = (data) => {
    return this.request({
      url: `${hosts.userApi()}/users/password/validate/`,
      method: 'POST',
      data,
    });
  };

  login: LoginHandler = (data) => {
    return this.request({
      url: `${hosts.userApi()}/auth/login/`,
      method: 'POST',
      data,
    });
  };

  logout = () => {
    return this.request({
      url: `${hosts.userApi()}/auth/logout/`,
      method: 'POST',
    });
  };

  validateEmailAndPassword: ValidateEmailAndPasswordHandler = (data) => {
    return this.request({
      url: `${hosts.userApi()}/auth/validate_email_and_password/`,
      method: 'POST',
      data,
    });
  };

  validateMfaCode: ValidateMfaCodeHandler = (data) => {
    return this.request({
      url: `${hosts.userApi()}/auth/validate_mfa_code/`,
      method: 'POST',
      data,
    });
  };

  loginTenant: LoginTenantHandler = (data) => {
    return this.request({
      url: `${hosts.userApi()}/auth/login/login_secret/`,
      method: 'POST',
      data,
    });
  };

  getCredits: GetCreditsHandler = () => {
    return this.request({
      url: `${hosts.userApi()}/users/credits/`,
      method: 'GET',
    });
  };

  updateCurrentUser: UpdateCurrentUserHandler = (data) => {
    return this.request({
      url: `${hosts.userApi()}/users/profile/`,
      method: 'PATCH',
      data,
    });
  };

  changeCurrentUserPassword: ChangeCurrentUserPasswordHandler = (data) => {
    return this.request({
      url: `${hosts.userApi()}/users/profile/reset_password/`,
      method: 'PATCH',
      data,
    });
  };

  getTenantCurrentUser: GetTenantCurrentUserHandler = () => {
    return this.request({
      url: `${hosts.userApi()}/users/profile/get_tenant/`,
      method: 'GET',
    });
  };

  async getEmailSettings() {
    return this.request({
      url: `${hosts.userApi()}/user_email_settings/`,
      method: 'GET',
    });
  }

  async createEmailSettings(data: EmailSettingsProps) {
    return this.request({
      url: `${hosts.userApi()}/user_email_settings/`,
      method: 'POST',
      data,
    });
  }

  async updateEmailSettings(id: number, data: EmailSettingsProps) {
    return this.request({
      url: `${hosts.userApi()}/user_email_settings/${id}/`,
      method: 'PATCH',
      data,
    });
  }

  async getJobBoardSettings() {
    try {
      return this.request<JobBoardSettingsType[]>({
        url: `${hosts.userApi()}/user_job_board_settings/`,
        method: 'GET',
      });
    } catch (error) {
      return undefined;
    }
  }

  async createJobBoardSettings(data: JobBoardSettingsType) {
    return this.request<JobBoardSettingsType>({
      url: `${hosts.userApi()}/user_job_board_settings/`,
      method: 'POST',
      data,
    });
  }

  async updateJobBoardSettings(id: number, data: JobBoardSettingsType) {
    return this.request<JobBoardSettingsType>({
      url: `${hosts.userApi()}/user_job_board_settings/${id}/`,
      method: 'PATCH',
      data,
    });
  }

  getFeatureToggles: GetFeatureTogglesHandler = (params) => {
    return this.request({
      url: `${hosts.userApi()}/feature_toggles/`,
      method: 'GET',
      params: { ordering: 'id', ...params },
    });
  };

  createFeatureToggles: CreateFeatureTogglesHandler = (data) => {
    return this.request({
      url: `${hosts.userApi()}/feature_toggles/`,
      method: 'POST',
      data: { ...data, value: data.value || null },
    });
  };

  updateFeatureToggles: UpdateFeatureTogglesHandler = (data) => {
    return this.request({
      url: `${hosts.userApi()}/feature_toggles/${data.id}/`,
      method: 'PATCH',
      data: { ...data, value: data.value || null },
    });
  };

  deleteFeatureToggles: DeleteFeatureTogglesHandler = (id) => {
    return this.request({
      url: `${hosts.userApi()}/feature_toggles/${id}/`,
      method: 'DELETE',
    });
  };

  getUserEmailSignature: GetUserEmailSignatureHandler = (id?: number) => {
    return this.request<any>({
      url: `${hosts.userApi()}/user_email_signature/${id ?? ''}`,
      method: 'GET',
    });
  };

  createUserEmailSignature: CreateUserEmailSignatureHandler = (signature) => {
    return this.request({
      url: `${hosts.userApi()}/user_email_signature/`,
      method: 'POST',
      data: { signature_html: signature },
    });
  };

  updateUserEmailSignature: UpdateUserEmailSignatureHandler = (id, signature) => {
    return this.request({
      url: `${hosts.userApi()}/user_email_signature/${id}/`,
      method: 'PUT',
      data: { signature_html: signature },
    });
  };

  deleteUserEmailSignature: DeleteUserEmailSignatureHandler = (id) => {
    return this.request({
      url: `${hosts.userApi()}/user_email_signature/${id}`,
      method: 'DELETE',
    });
  };
}

export const userClient = new UserClient();
