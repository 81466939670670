import type { Filters, FiltersControlStateConfig, WithFiltersWrappedProps } from './types';

import { diff } from 'deep-diff';
import { useCallback, useMemo } from 'react';
import { useContextSelector } from 'use-context-selector';
import { FiltersContext } from './FilterContext';

enum FilterTypes {
  JOB_TITLE = 'job_titles',
  LOCATION = 'locations',
  EDUCATION_DEGREE = 'education_degrees',
}

export const aiSuggestionFilters = [FilterTypes.JOB_TITLE, FilterTypes.LOCATION, FilterTypes.EDUCATION_DEGREE] as const;

export type AiSuggestionFiltersType = (typeof aiSuggestionFilters)[number];
export type TriggerAiSuggestionsFunctionType = (args: {
  key: AiSuggestionFiltersType;
  campaignId: number | string;
  filters: any[];
}) => void;

export const useFiltersContext = <T extends keyof Filters>(
  filterKey: T,
  config?: FiltersControlStateConfig,
): [Filters[T], (value: Filters[T]) => void] => {
  const filters = useContextSelector(FiltersContext, (state) => state.filters);
  const setSearchFilters = useContextSelector(FiltersContext, (state) => state.setSearchFilters);

  const oldFilterValue = useMemo(() => {
    return filters[filterKey];
  }, [filters[filterKey]]);

  const setFilter = useCallback(
    (value: Filters[T]) => {
      if (diff(value, oldFilterValue)) {
        setSearchFilters((f) => ({ ...f, [filterKey]: value }), config);
      }
    },

    [filterKey, oldFilterValue, setSearchFilters, config],
  );

  return [filters[filterKey], setFilter];
};

export const withFiltersState = <T extends keyof Filters>(
  filterKey: T,
  Component: (props: WithFiltersWrappedProps<T>) => React.ReactNode | null,
  config?: FiltersControlStateConfig,
): ((props: any) => React.ReactNode | null) => {
  return (props: any) => {
    const [value, onChange] = useFiltersContext(filterKey, config);
    const stateProps: WithFiltersWrappedProps<T> = { value, onChange };

    return <Component {...{ ...props, ...stateProps }} />;
  };
};

export const pickAiFiltersFromFiltersArray = (filtersArray: Filters): Pick<Filters, AiSuggestionFiltersType> => {
  return aiSuggestionFilters.reduce(
    (acc, key) => ({
      ...acc,
      [key]: filtersArray[key],
    }),
    {} as Pick<Filters, AiSuggestionFiltersType>,
  );
};

export const verifyAiFiltersDiff = (
  newFilters: Pick<Filters, AiSuggestionFiltersType>,
  oldFilters: Pick<Filters, AiSuggestionFiltersType>,
): Record<string, { added: any[]; removed: any[] }> => {
  const differences: Record<string, { added: any[]; removed: any[] }> = {};

  for (const key of aiSuggestionFilters) {
    const newValues = newFilters[key] as Array<{ key: string; isAiCreated?: boolean; isSynonym?: boolean }>;
    const oldValues = oldFilters[key] as Array<{ key: string; isAiCreated?: boolean; isSynonym?: boolean }>;
    const added = [];
    const removed = [];

    const oldValuesMapped = (oldValues as any).reduce((acc: any, val: any) => {
      acc[val.key] = val;
      return acc;
    }, {});

    for (const newValue of newValues) {
      const oldValue = oldValuesMapped[newValue.key];

      if (!oldValue || oldValue.isAiCreated !== newValue.isAiCreated || oldValue.isSynonym !== newValue.isSynonym) {
        added.push(newValue);
      }
    }

    for (const oldValue of oldValues) {
      if (!newValues.find((val) => val.key === oldValue.key)) {
        removed.push(oldValue);
      }
    }

    differences[key] = {
      added,
      removed,
    };
  }

  return differences;
};

const processAddedSuggestions = (
  key: AiSuggestionFiltersType,
  added: any[],
  campaignId: number,
  triggerAiSuggestionsAdded: TriggerAiSuggestionsFunctionType,
) => {
  triggerAiSuggestionsAdded({ key, campaignId, filters: added });
};

const processRemovedSuggestions = (
  key: AiSuggestionFiltersType,
  removed: any[],
  campaignId: number,
  triggerAISuggestionsRemoved: TriggerAiSuggestionsFunctionType,
) => {
  triggerAISuggestionsRemoved({ key, campaignId, filters: removed });
};

const processAiSuggestionChanges = (
  key: AiSuggestionFiltersType,
  aiFiltersDiff: Record<string, { added: any[]; removed: any[] }>,
  campaignId: number,
  triggerAiSuggestionsAdded: TriggerAiSuggestionsFunctionType,
  triggerAISuggestionsRemoved: TriggerAiSuggestionsFunctionType,
) => {
  if (aiFiltersDiff[key]) {
    const { added, removed } = aiFiltersDiff[key];

    processAddedSuggestions(key, added, campaignId, triggerAiSuggestionsAdded);
    processRemovedSuggestions(key, removed, campaignId, triggerAISuggestionsRemoved);
  }
};

export const processAllAiSuggestionChanges = (
  aiFiltersDiffRef: React.MutableRefObject<Record<string, { added: any[]; removed: any[] }> | null>,
  aiSuggestionFilters: readonly string[],
  campaignId: number,
  triggerAiSuggestionsAdded: TriggerAiSuggestionsFunctionType,
  triggerAISuggestionsRemoved: TriggerAiSuggestionsFunctionType,
) => {
  if (!aiFiltersDiffRef.current) return;

  aiSuggestionFilters.forEach((key) => {
    if (!(key === FilterTypes.JOB_TITLE || key === FilterTypes.LOCATION || key === FilterTypes.EDUCATION_DEGREE))
      return;

    processAiSuggestionChanges(
      key,
      aiFiltersDiffRef.current!,
      campaignId,
      triggerAiSuggestionsAdded,
      triggerAISuggestionsRemoved,
    );
  });
};
