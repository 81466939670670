const camelToSnake = (str: string): string => str.replace(/([A-Z])/g, '_$1').toLowerCase();

export const convertKeysToSnakeCase = (obj: Record<string, any>): Record<string, any> => {
  if (!obj || typeof obj !== 'object') return obj;

  if (Array.isArray(obj)) {
    return obj.map((item) => convertKeysToSnakeCase(item));
  }

  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      camelToSnake(key),
      typeof value === 'object' ? convertKeysToSnakeCase(value) : value,
    ]),
  );
};
