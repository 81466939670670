export enum QueryKey {
  campaignManagerContextUseCampaigns = 'campaignManagerContextUseCampaigns',
  selectedCampaignContextUseCandidates = 'selectedCampaignContextUseCandidates',
  useRatedCandidatesQuery = 'useRatedCandidatesQuery',
  useRatedCandidatesStats = 'useRatedCandidatesStats',
  selectedCampaignContextUseStats = 'selectedCampaignContextUseStats',
  selectedCampaignContextUseFacets = 'selectedCampaignContextUseFacets',
  sharedCandidateContextUseCandidate = 'sharedCandidateContextUseCandidate',
  selectedCampaignContextUseCandidateMatches = 'selectedCampaignContextUseCandidateMatches',
  useCandidateActivityLogQuery = 'useCandidateActivityLogQuery',
  useCandidateHistoryQuery = 'useCandidateHistoryQuery',
  frontend2beCandidate = 'frontend2beCandidate',
  connectToAtsUseAts = 'connectToAtsUseAts',
  campaignManagerContextUseCampaign = 'campaignManagerContextUseCampaign',
  useTenantDistinctedLabelsQuery = 'useTenantDistinctedLabelsQuery',
  useRequestCv = 'useRequestCv',
  sharedPageMatches = 'sharedPageMatches',
  useOutreachTemplateByTypeQuery = 'useOutreachTemplateByTypeQuery',
  useUserEmailSignatureQuery = 'useUserEmailSignatureQuery',
  useCurrentUserQuery = 'useCurrentUserQuery',
  useGetCampaignNewRepliesQuery = 'useGetCampaignNewRepliesQuery',
  useExistsLinkedinInmailConversationWithCandidateQuery = 'useExistsLinkedinInmailConversationWithCandidateQuery',
  useExistsLinkedinConversationWithCandidateQuery = 'useExistsLinkedinConversationWithCandidateQuery',
  useCheckHasLinkedinFreeInviteRemainingQuery = 'useCheckHasLinkedinFreeInviteRemainingQuery',
  searchRatedCandidate = 'searchRatedCandidate',
  useCandidateNotesQuery = 'useCandidateNotesQuery',
  atsStatusesQuery = 'atsStatusesQuery',
  validAtsForRobinResume = 'validAtsForRobinResume',
  useTenantUsersQuery = 'useTenantUsersQuery',
  useTenantUserQuery = 'useTenantUserQuery',
  useIndeedLicensesQuery = 'useIndeedLicensesQuery',
  useBrowserExtensionStatusQuery = 'useBrowserExtensionStatusQuery',
  useIndeedCommonModelQuery = 'useIndeedCommonModelQuery',
  useIndeedProjectListQuery = 'useIndeedProjectListQuery',
  useIndeedJobsListQuery = 'useIndeedJobsListQuery',
  useIndeedSubscriptionsListQuery = 'useIndeedSubscriptionsListQuery',
  useActiveIndeedLicenseQuery = 'useActiveIndeedLicenseQuery',
  useIndeedUsersListQuery = 'useIndeedUsersListQuery',
  useFeatureTogglesQuery = 'useFeatureTogglesQuery',
  useIndeedModalsUpdatedLicenses = 'useIndeedModalsUpdatedLicenses',
  useIndeedModalsAudit = 'useIndeedModalsAudit',
  countUnreadIndeedMessages = 'countUnreadIndeedMessages',
  getNewIndeedMessages = 'getNewIndeedMessages',
  getIndeedContactSummary = 'getIndeedContactSummary',
  getMyCampaigns = 'getMyCampaigns',
  useIndeedJobListQuery = 'useIndeedJobListQuery',
  useLinkedinConfig = 'useLinkedinConfig',
  useSearchJobTitleAutocompleteQuery = 'useSearchJobTitleAutocompleteQuery',
  validateATSConnection = 'validateAtsConnection',
  useGetUser = 'getUser',
  useGetUsers = 'getUsers',
  getConversations = 'getConversations',
  getConversationsPreview = 'getConversationsPreview',
}
