import { useQuery } from '@tanstack/react-query';
import { browserExtensionClient } from 'clients/BrowserExtensionClient/BrowserExtensionClient';
import { notificationsClient } from 'clients/NotificationsClient/NotificationsClient';
import { queryClient } from 'config/queryClient';
import { encodeBase64 } from 'helpers/base64';
import { useEffect, useRef, useState } from 'react';
import { useCurrentUserQuery } from '../useCurrentUserQuery';
import { useLinkedinConfig } from './linkedin/useLinkedinConfig';
import type { LinkedinMessagingResponse } from './types';

const REFETCH_INTERVAL = 600 * 1000; // 10 minutes

export function useUnreadLinkedInMessages() {
  const { data: currentUser } = useCurrentUserQuery();

  const invalidationCountRef = useRef(0);

  const [shouldRunCountUnreadQuery, setShouldRunCountUnreadQuery] = useState(true);
  const { linkedinId, isLoggedIn } = useLinkedinConfig();

  useQuery(
    ['countUnreadLinkedInMessages', linkedinId],
    async () => {
      const response = await browserExtensionClient.sendMessage('proxyHttpRequest', {
        url: `https://www.linkedin.com/voyager/api/voyagerMessagingGraphQL/graphql?queryId=messengerConversations.0fd27f53c2a17c81a3c2370c94ab3068&variables=(categories:List(INBOX),mailboxUrn:urn%3Ali%3Afsd_profile%3A${linkedinId},read:false)`,
        method: 'GET',
        addHeadersFrom: 'linkedInClassic',
        headers: {
          accept: 'application/json',
          'Accept-Encoding': 'gzip, deflate, br, zstd',
        },
      });

      return response;
    },
    {
      enabled: !!currentUser && !!linkedinId && isLoggedIn && shouldRunCountUnreadQuery,
      refetchInterval: REFETCH_INTERVAL,
      refetchIntervalInBackground: true,
      onSuccess: async (data) => {
        if (currentUser) {
          const jsonData: LinkedinMessagingResponse = JSON.parse(data.body);

          if (jsonData.errors?.some((error) => error.extensions.status === 403)) {
            if (invalidationCountRef.current < 3) {
              invalidationCountRef.current += 1;
              queryClient.invalidateQueries(['linkedInFsdProfileId']);
            } else {
              setShouldRunCountUnreadQuery(false);
            }
            throw new Error('403 Forbidden');
          }

          const unreads = jsonData.data.messengerConversationsBySyncToken.elements.filter(
            (element) => !element.read && !element.categories.includes('ARCHIVE'),
          );

          const onlyUnreadsFromCandidates = unreads.filter((unread) =>
            unread.conversationParticipants.every((participant) => participant.hostIdentityUrn.includes('fsd_profile')),
          );

          const messages = onlyUnreadsFromCandidates.map((message) => {
            const currentUserId = `urn:li:fsd_profile:${linkedinId}`;

            const [candidate] = message.conversationParticipants.filter(
              (participant) => participant.hostIdentityUrn !== currentUserId,
            );

            const candidateId = candidate.hostIdentityUrn.split(':fsd_profile:')[1];
            const esPersonId = `cvt-${candidateId}`;

            return {
              es_person_id: esPersonId,
              message_id: String(message.lastActivityAt),
              thread_id: encodeBase64(`${currentUser?.id}-${esPersonId}`),
            };
          });

          notificationsClient.saveLinkedinMessagesNotifications({ messages });
        }
      },
    },
  );

  useEffect(() => {
    if (currentUser) {
      invalidationCountRef.current = 0;
      setShouldRunCountUnreadQuery(true);
    }
  }, [currentUser, linkedinId]);
}
