import { browserExtensionClient } from 'clients/BrowserExtensionClient/BrowserExtensionClient';
import {
  BrowserExtensionInfoStatus,
  useBrowserExtensionStatusQuery,
} from 'hooks/shared/useBrowserExtensionStatusQuery';
import { useCallback, useMemo } from 'react';

export const useBrowserExtensionLocalStorage = () => {
  const browserExtensionStatus = useBrowserExtensionStatusQuery();
  const isExtensionActive = browserExtensionStatus.data.status === BrowserExtensionInfoStatus.Active;

  const get = useCallback(
    async <T>(key: string): Promise<T | null> => {
      if (isExtensionActive) {
        const { value } = await browserExtensionClient.sendMessage('readLocalStorage', { key });

        return value;
      }

      return Promise.resolve(null);
    },
    [isExtensionActive],
  );

  const set = useCallback(
    <T>(key: string, value: T) => {
      if (isExtensionActive) {
        browserExtensionClient.sendMessage('writeLocalStorage', { key, value });
      }
    },
    [isExtensionActive],
  );

  return useMemo(() => ({ get, set }), [get, set]);
};
