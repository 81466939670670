import { Skeleton } from '@recruitrobin/robin-theme/web-components';
import { lazy, Suspense } from 'react';
import { useStyles } from './OutreachPanel.lazy.styles';

const OutreachPanel = lazy(() =>
  import('../OutreachPanel/OutreachPanel').then((module) => ({ default: module.OutreachPanel })),
);

export const OutreachPanelLazy = () => {
  const classes = useStyles();

  return (
    <Suspense
      fallback={
        <div css={classes.skeletonRoot}>
          <div css={classes.skeletonHeader}>
            <Skeleton variant="text" font={(f) => f.heading[1]} width={300} />
            <Skeleton variant="text" font={(f) => f.heading[3]} width={120} />
          </div>
          <div css={classes.skeletonBody}>
            <Skeleton variant="square" height={600} width="100%" />
          </div>
          <div css={classes.skeletonFooter}>
            <Skeleton variant="square" height={38} width={130} />
            <Skeleton variant="square" height={38} width={130} />
          </div>
        </div>
      }>
      <OutreachPanel />
    </Suspense>
  );
};
