import { persistedQueryClient } from 'config/persistedQueryClient';
import { queryClient } from 'config/queryClient';
import { routes } from 'config/routes';
import localforage from 'localforage';

export { routes };

export const hosts = {
  rrLegacyApi: (subDomain = localStorage.getItem('subdomain')) => {
    if ((process.env?.REACT_APP_API_DOMAIN || '').includes('http')) {
      return process.env.REACT_APP_API_DOMAIN;
    }
    return `https://${(subDomain || 'default').toLowerCase()}.${process.env.REACT_APP_API_DOMAIN}`;
  },
  searchApi: () => {
    return process.env?.REACT_APP_SEARCH_API_URL;
  },
  campaignsApi: () => {
    return process.env?.REACT_APP_CAMPAIGNS_API_URL;
  },
  cvApi: () => {
    return process.env?.REACT_APP_CV_API_URL;
  },
  notificationsApi: () => {
    return process.env?.REACT_APP_NOTIFICATIONS_API_URL;
  },
  userApi: () => {
    return process.env?.REACT_APP_USER_API_URL;
  },
  crawlerSocketApi: () => {
    return process.env?.REACT_APP_CRAWLER_API_URL;
  },
  crawlerWebApi: () => {
    return process.env?.REACT_APP_CRAWLER_WEB_API_URL;
  },
  atsGatewayApi: () => {
    return process.env?.REACT_APP_ATS_GATEWAY_API_URL;
  },
  mockApi: (subDomain = localStorage.getItem('subdomain')) => {
    if ((process.env?.REACT_APP_MOCK_API_URL || '').includes('http')) {
      return process.env.REACT_APP_MOCK_API_URL;
    }
    return `https://${(subDomain || 'default').toLowerCase()}.${process.env.REACT_APP_MOCK_API_URL}`;
  },
};

export const tracking = {
  googleAnalyticsId: process.env.REACT_APP_GA_TRACKING_ID || '',
};

export const blacklistedEmailDomains = ['gmail.com', 'hotmail.com'];

export const blockRefreshJobboards = process.env?.REACT_APP_BLOCK_REFRESH_JOBBOARDS?.split(',');

export const indeedSiteKey = '806c1ff3-7426-4d31-8b0c-ddd1a19293d3';

export const shouldProfileBeRefreshed = (crawledAgoInDays: number) => crawledAgoInDays >= 7;

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type WithDataTutorialId<T> = { 'data-tutorial-id'?: string } & T;

// For development purposes
export const shouldRemoveCandidateAfterRefresh =
  process.env.REACT_APP_REMOVE_CANDIDATES_AFTER_REFRESH !== 'off' ||
  (process.env.REACT_APP_REMOVE_CANDIDATES_AFTER_REFRESH === 'off' &&
    localStorage.getItem('REMOVE_CANDIDATES_AFTER_REFRESH') === 'true');

if (process.env.REACT_APP_ENVIRONMENT === 'development') {
  const getCampaignGTMStatsRaw = async (campaignId: number) => {
    const itemId = `USE-CAMPAIGN-AI-GTM-${campaignId}`;
    const data = window.top !== window.self ? await localforage.getItem(itemId) : localStorage.getItem(itemId);

    return data ? JSON.parse(data as string) : 'No data available';
  };

  const stagingUtils = {
    setShouldRefreshCandidates: (value: boolean) => localStorage.setItem('REMOVE_CANDIDATES_AFTER_REFRESH', `${value}`),
    queryClient: queryClient,
    persistedClient: persistedQueryClient,
    getCampaignGTMStatsRaw,
    getCampaignGTMStats: async (campaignId: number) => {
      const data = await getCampaignGTMStatsRaw(campaignId);

      if (typeof data === 'string') return data;
      const { createdAt, added, removed } = data as {
        createdAt?: string;
        added: Array<{ editedAt: string; value?: Record<string, any> }>;
        removed: Array<{ editedAt: string; value?: Record<string, any> }>;
        manual: Array<{ editedAt: string; value?: Record<string, any> }>;
      };

      const addedLength = added.reduce((acc, { value }) => acc + (value?.filters?.length || 0), 0);
      const removedLength = removed.reduce((acc, { value }) => acc + (value?.filters?.length || 0), 0);

      return { createdAt, added: addedLength, removed: removedLength };
    },
  };
  const stagingUtilsKeys = Object.keys(stagingUtils);

  console.info(`[Staging Utils] available with ${stagingUtilsKeys.length} util(s)!`, stagingUtilsKeys);
  window.stagingUtils = stagingUtils;
}

export const allowedAtsAiCreation = process.env?.REACT_APP_ALLOWED_ATS_AI_CREATION?.split(',') ?? [];

export const shouldShowRobinWrapped = () => false;
