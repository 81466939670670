import { browserExtensionClient } from 'clients/BrowserExtensionClient/BrowserExtensionClient';
import { IndeedCandidateAlreadyContactedException } from 'shared/exceptions/IndeedCandidateAlreadyContactedException';
import { useIndeedConfig } from './useIndeedConfig';
import { useMutation } from '@tanstack/react-query';
import type { IndeedJob } from './indeed.types';

type TalentEngagementCreatedResult = {
  additionalInfo: null;
  candidateId: string;
  __typename: 'TalentEngagementCreatedResult';
};

type CreateTalentEngagementError = {
  candidateId: string;
  code: string;
  message: string;
  __typename: 'CreateTalentEngagementError';
};

type SendMessageResponse = {
  createTalentEngagements: {
    results: Array<TalentEngagementCreatedResult | CreateTalentEngagementError>;
    successCount: number;
    __typename: 'CreateTalentEngagementsPayload';
  };
};

type MessageData = {
  candidateId: string;
  jobTitle: string;
  message: string;
  senderName: string;
  senderCompany: string;
  projectKey: string;
  job?: IndeedJob | null;
};

type SendMessageHandler = (params: { headers: Record<string, any>; data: MessageData }) => Promise<SendMessageResponse>;

export const useIndeedSendMessageMutation = () => {
  const indeedConfig = useIndeedConfig();

  return useMutation(async (data: MessageData) => {
    if (!indeedConfig.isLoggedIn) throw Error('[useIndeedSendMessageMutation] Indeed user not logged in!');

    const response = await sendMessage({ data, headers: indeedConfig.headers });

    return response;
  });
};

const createTalentEngagementsGQLMutation = ({
  candidateId,
  senderName,
  company,
  jobTitle,
  message,
  projectKey,
  job,
}: {
  candidateId: string;
  senderName: string;
  company: string;
  jobTitle: string;
  message: string;
  projectKey: string;
  job?: IndeedJob | null;
}) => ({
  operationName: 'CreateTalentEngagements',
  variables: {
    input: {
      candidateIds: [candidateId],
      detailsByType: {
        email: {
          message,
          company,
          senderName,
          jobInfo: {
            jobId: job?.key ?? '',
            jobDescription: job?.description ?? '',
            jobTitle: job?.name ?? jobTitle,
          },
          includeProfileSignature: true,
          reminders: [],
          subject: '',
        },
      },
      metadata: {
        editedAfterPreview: null,
        previewed: null,
        skipProactiveModerationCheck: false,
        source: 'RESUME',
        templateId: '',
      },
      projectKey,
    },
  },
  query:
    'mutation CreateTalentEngagements($input: CreateTalentEngagementsInput!) {\n  createTalentEngagements(input: $input) {\n    results {\n      ... on CreateTalentEngagementError {\n        candidateId\n        code\n        message\n        __typename\n      }\n      ... on TalentEngagementCreatedResult {\n        additionalInfo {\n          ... on CreateTalentEngagementForPhoneDetails {\n            proxyPhoneNumber\n            __typename\n          }\n          __typename\n        }\n        candidateId\n        __typename\n      }\n      __typename\n    }\n    successCount\n    __typename\n  }\n}\n',
});

const sendMessage: SendMessageHandler = async ({ data, headers }) => {
  const mutation = createTalentEngagementsGQLMutation({
    candidateId: data.candidateId,
    company: data.senderCompany,
    jobTitle: data.jobTitle,
    message: data.message,
    senderName: data.senderName,
    projectKey: data.projectKey,
    job: data.job,
  });

  const response = await browserExtensionClient.sendMessage('proxyHttpRequest', {
    url: 'https://apis.indeed.com/graphql',
    method: 'POST',
    body: JSON.stringify(mutation),
    headers,
  });

  const parsedResponse: SendMessageResponse = JSON.parse(response.body).data;
  parsedResponse.createTalentEngagements.results.forEach((el) => {
    if (el.__typename === 'CreateTalentEngagementError' && el.code === 'ALREADY_CONTACTED') {
      throw new IndeedCandidateAlreadyContactedException(el.message);
    }
  });

  return parsedResponse;
};
