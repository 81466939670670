import type { InboxConversationListBodyProps } from '../../CentralInbox.types';

import { useStyles } from './InboxConversationListBody.styles';
import { InboxConversationItem } from '../InboxConversationItem';
import { DotsHorizontal } from '@recruitrobin/robin-theme/web-icons';

export const InboxConversationListBody = ({ conversations }: InboxConversationListBodyProps) => {
  const classes = useStyles();

  return (
    <div css={classes.root}>
      {conversations.map((conversation) => (
        <InboxConversationItem key={conversation.id} conversation={conversation} />
      ))}
      <div css={classes.flexAlignment}>
        <DotsHorizontal size={19} />
      </div>
    </div>
  );
};

export default InboxConversationListBody;
