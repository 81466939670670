import { useQuery, useQueryClient } from '@tanstack/react-query';
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { tenantClient, tenantClientCacheKeys } from 'clients/Admin';
import { browserExtensionClient } from 'clients/BrowserExtensionClient/BrowserExtensionClient';
import { jobBoardsClient } from 'clients/JobBoardsClient/JobBoardsClient';
import {
  LoginHandler,
  UpdateCurrentUserHandler,
  UserResponse,
  ValidateEmailAndPasswordHandler,
  ValidateMfaCodeHandler,
  WerknlLayout,
} from 'clients/UserClient/types';
import { userClient } from 'clients/UserClient/UserClient';
import { WerknlCandidateInfoPresentationDialog } from 'components/ui/molecules/WerknlCandidateInfoPresentationDialog/WerknlCandidateInfoPresentationDialog';
import { routes } from 'config';
import { LOG_CUSTOM_ATTRIBUTE, logger } from 'config/logger';
import { isAxiosError } from 'helpers/clientHelpers';
import { paramBind } from 'helpers/useQueryHelpers';
import { useGTM } from 'hooks/gtm';
import { useLocalStorage } from 'hooks/shared';
import { useTrackingCode } from 'react-hubspot-tracking-code-hook';
import { useNavigate } from 'react-router-dom';
import { useCurrentUserQuery } from 'shared/hooks/useCurrentUserQuery';
import { QueryKey } from 'types/query';
import { useContextSelector } from 'use-context-selector';
import { AuthContext } from '../AuthContext';
import { CustomGTMContext } from '../CustomGTMContext';
import { UserContextProps, UserContextProviderProps, WERKNL_ACTIONS, WERKNL_LAYOUT_CHOSEN } from './types';

const defaultContext: UserContextProps = {
  login: undefined,
  validateEmailAndPassword: undefined,
  validateMfaCode: undefined,
  logout: () => null,
  impersonate: () => new Promise<void>((res) => res()),
  isLoggedIn: false,
  currentUser: undefined,
  availableJobBoardsIsLoading: false,
  tenantCVSources: [],
  tenantCvSourcesIsLoading: false,
  mailSignOff: {},
  isFirstUserLoads: false,
  isInvalidToken: false,
};

export const UserContext = createContext(defaultContext);

export const UserContextProvider = ({ children }: UserContextProviderProps) => {
  const queryClient = useQueryClient();
  const setCustomGTMConfig = useContextSelector(CustomGTMContext, (context) => context.setConfig);
  const gtm = useGTM();
  const { activeToken, setUserToken, setImpersonateToken, disposeAllTokens, isFirstTokenLoads, userEmail } =
    useContext(AuthContext);
  const [currentUser, setCurrentUser] = useState<UserResponse>();
  const [isInvalidToken, setIsInvalidToken] = useState(false);
  const mailSignOff = useMemo(() => {
    return {
      fullName: [currentUser?.first_name, currentUser?.last_name].filter(Boolean).join(' '),
      phoneNumber: currentUser?.phone_number ?? undefined,
    };
  }, [currentUser]);
  const emptyArrayRef = useRef<never[]>([]);
  const navigate = useNavigate();
  const [shouldShowWerknlModal, setShouldShowWerknlModal] = useState(false);

  const { value: werknlActions } = useLocalStorage<number>(`${WERKNL_ACTIONS}-${currentUser?.id}`, 0);
  const { value: werknlLayoutChosen } = useLocalStorage<boolean>(`${WERKNL_LAYOUT_CHOSEN}-${currentUser?.id}`, false);

  const isLoggedIn = useMemo(() => (isFirstTokenLoads ? false : !!activeToken), [isFirstTokenLoads, activeToken]);

  const { isLoading, isFetched, isFetching } = useCurrentUserQuery({
    enabled: isLoggedIn,
    retry: 0,
    onSuccess: (user) => {
      setCurrentUser(user);
    },
    onError: (data) => {
      if (isAxiosError(data) && data.response?.status === 500) {
        setIsInvalidToken(true);
      }
    },
  });

  const isFirstUserLoads = isFetched ? isLoading : isLoggedIn && isFetching;

  useEffect(() => {
    if (currentUser?.werknl_layout === WerknlLayout.New && !werknlLayoutChosen && werknlActions >= 20) {
      setShouldShowWerknlModal(true);
    }
  }, [werknlActions, werknlLayoutChosen, currentUser?.werknl_layout]);

  useEffect(() => {
    if (currentUser?.werknl_layout) {
      queryClient.resetQueries([QueryKey.selectedCampaignContextUseCandidates], { exact: false });
    }
  }, [currentUser?.werknl_layout]);

  useEffect(() => {
    if (currentUser) {
      logger.setCustomAttribute(LOG_CUSTOM_ATTRIBUTE.USER_ID, currentUser.id);
      logger.setCustomAttribute(LOG_CUSTOM_ATTRIBUTE.USER_EMAIL, currentUser.email);
      logger.setCustomAttribute(LOG_CUSTOM_ATTRIBUTE.TENANT_ID, currentUser.tenant.id);
      logger.setCustomAttribute(LOG_CUSTOM_ATTRIBUTE.TENANT_NAME, currentUser.tenant.name);

      setCustomGTMConfig({ user_id: currentUser.id, tenant_id: currentUser.tenant.id });
    }

    if (currentUser && !currentUser.is_active) {
      logout();
    }

    if (currentUser && currentUser.id && !window.location.href.includes('localhost:6006')) {
      const closeWidgetEvent: StonlyWidgetOptionsHandler = ({ closeReason, guideId }) => {
        if (closeReason === 'closeButtonClicked' && guideId === 'yEWJTNT9bt') {
          updateCurrentUser({
            has_tutorial: false,
          }).then(() => {
            localStorage.removeItem('stonly_customer_user_id');
            localStorage.removeItem('stonly_anonymous_id');
          });
        }
      };

      window.StonlyWidget('addEventListener', 'close', closeWidgetEvent);

      userClient.getFeatureToggles().then(({ data }) => {
        const isOutreachEnabled = data.some((feature) => feature.name === 'outreach' && feature.enabled);

        window.StonlyWidget('identify', `${currentUser.id}`, {
          'outreach-enabled': isOutreachEnabled,
          'tutorial-required': currentUser.has_tutorial,
        });
      });

      window.Intercom('boot', {
        app_id: process.env.REACT_APP_INTERCOM_ID,
        email: currentUser.email,
        user_id: currentUser.id,
        name: `${currentUser.first_name} ${currentUser.last_name}`,
      });

      return () => {
        window.StonlyWidget('removeEventListener', 'close', closeWidgetEvent);
      };
    }
  }, [currentUser?.is_active, currentUser?.id]);

  useEffect(() => {
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          const iframe = document.querySelector('iframe.intercom-launcher-frame') as HTMLIFrameElement;
          if (iframe) {
            try {
              const iframeDoc = iframe.contentDocument || iframe.contentWindow!.document;

              const style = iframeDoc.createElement('style');
              style.innerHTML = `
                .intercom-launcher {
                  border-radius: 30px 0 0 30px !important;
                  width: 48px !important;
                  height: 40px !important;
                }

                [data-testid="launcher-default-open-icon"] {
                  width: 48px !important;
                  height: 40px !important;

                  svg {
                    width: 20px !important;
                    height: 20px !important;
                  }
                }
              `;
              iframeDoc.head.appendChild(style);
            } catch (error) {
              console.error('Cannot access iframe content due to cross-origin restrictions:', error);
            }

            observer.disconnect();
          }
        }
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, []);

  const { data: { data: userCredits = undefined } = {} } = useQuery(['userCredits'], userClient.getCredits, {
    enabled: isLoggedIn,
  });

  const {
    data: { data: { jobBoards: availableJobBoards = emptyArrayRef.current } = {} } = {},
    isFetching: availableJobBoardsIsLoading,
  } = useQuery(['getAvailableJobBoards'], jobBoardsClient.getAvailableJobBoards, { enabled: isLoggedIn, retry: 0 });

  const { data: { data: [userEmailSettings] = emptyArrayRef.current } = {}, refetch: refetchUserEmailSettings } =
    useQuery(['getEmailSettings'], userClient.getEmailSettings, { enabled: isLoggedIn });

  const { data: { data: [userJobBoardSettings] = emptyArrayRef.current } = {}, refetch: refetchJobBoardSettings } =
    useQuery(['getJobBoardSettings'], userClient.getJobBoardSettings, { enabled: isLoggedIn });

  const { data: { data: tenantCVSources = emptyArrayRef.current } = {}, isFetching: tenantCvSourcesIsLoading } =
    useQuery(
      [tenantClientCacheKeys.getCvSources, parseInt(`${currentUser?.tenant.id ?? 0}`)],
      paramBind(tenantClient.getCvSources),
      { enabled: Boolean(isLoggedIn && currentUser?.tenant.id) },
    );

  const hasMonsterboardV2License = useMemo(() => {
    if (availableJobBoards && availableJobBoards.length !== 0) {
      return availableJobBoards.some(
        ({ platformId, isActive }: any) => platformId === 'monsterboard_v2' && isActive === true,
      );
    }
    return null;
  }, [availableJobBoards]);

  /// TODO WERKZOEKEN: Remove this after release new jobboard
  // const hasWerkzoekenLicense = useMemo(() => {
  // if (availableJobBoards && availableJobBoards.length !== 0) {
  //   return availableJobBoards.some(
  //     ({ platformId, isActive }: any) => platformId === 'werkzoeken' && isActive === false,
  //   );
  // }
  // return null;

  //   return true;
  // }, [availableJobBoards]);

  const hasWerknlBoardLicense = useMemo(() => {
    if (availableJobBoards && availableJobBoards.length !== 0) {
      return availableJobBoards.some(({ platformId, isActive }: any) => platformId === 'werknl' && isActive === true);
    }
    return null;
  }, [availableJobBoards]);

  const askBeforeSpendingMonsterInventoryCredit =
    userJobBoardSettings?.never_ask_before_spending_monster_inventory_credit ?? false;

  /// TODO WERKZOEKEN: Remove this after release new jobboard
  // const askBeforeSpendingWerkzoekenCredit = userJobBoardSettings?.never_ask_before_spending_werkzoeken_credit ?? false;

  const logout = () => {
    if (currentUser) {
      userClient.logout();
    }

    browserExtensionClient
      .sendMessage('userStateChanged', {
        user: currentUser,
        isLoggedIn: false,
        token: activeToken,
      })
      .finally(() => {
        setCurrentUser(undefined);
        disposeAllTokens();
        queryClient.clear();
      });
  };

  window.logout = logout;

  const impersonate = useCallback(async (token: string) => {
    setImpersonateToken(token);

    const { data: impersonatedUser } = await userClient.getCurrent();
    setCurrentUser(impersonatedUser);

    queryClient.clear();
    navigate(routes.home, { replace: true });
  }, []);

  const login: LoginHandler = async (data) => {
    const response = await userClient.login(data);

    if (response.status === 201) {
      const { access_token, should_change_password } = response.data;

      if (should_change_password) {
        return response;
      }

      setUserToken(access_token);

      await browserExtensionClient
        .sendMessage('userStateChanged', {
          user: currentUser,
          isLoggedIn: true,
          token: access_token,
        })
        .catch((e) => {
          console.error(e);
        });
    }

    return response;
  };

  const validateEmailAndPassword: ValidateEmailAndPasswordHandler = async (data) => {
    const response = await userClient.validateEmailAndPassword(data);

    if (response.status === 201) {
      const { email } = response.data;

      userEmail.current = email;
    }

    return response;
  };

  const validateMfaCode: ValidateMfaCodeHandler = async (data) => {
    const response = await userClient.validateMfaCode(data);

    if (response.status === 201) {
      const { access_token } = response.data;

      setUserToken(access_token);

      await browserExtensionClient
        .sendMessage('userStateChanged', {
          user: currentUser,
          isLoggedIn: true,
          token: access_token,
        })
        .catch((e) => {
          console.error(e);
        });
    }

    return response;
  };

  const updateCurrentUser: UpdateCurrentUserHandler = async (values: any) => {
    const response = await userClient.updateCurrentUser(values);
    setCurrentUser(response.data);
    gtm.editUser();

    return response;
  };

  const updateUserEmailSettingsProfile = useCallback(
    async (values: any, id?: number) => {
      let status;

      if (id) {
        const updateAction = await userClient.updateEmailSettings(id, values);
        status = updateAction.status;
      } else {
        const createAction = await userClient.createEmailSettings(values);
        status = createAction.status;
      }

      if (status === 200) {
        await refetchUserEmailSettings();
        return true;
      }
      return false;
    },
    [refetchUserEmailSettings],
  );

  const updateUserJobBoardSettingsProfile = useCallback(
    async (values: any, id = userJobBoardSettings?.id) => {
      let status;

      if (id) {
        const updateAction = await userClient.updateJobBoardSettings(id, values);
        status = updateAction.status;
      } else {
        const createAction = await userClient.createJobBoardSettings(values);
        status = createAction.status;
      }

      if (status === 200) {
        await refetchJobBoardSettings();
        return true;
      }
      return false;
    },
    [refetchJobBoardSettings, userJobBoardSettings?.id],
  );

  const usedCredits = useMemo(() => {
    return userCredits?.used || 0;
  }, [userCredits]);

  const availableCredits = useMemo(() => {
    return (userCredits?.total || 0) - usedCredits;
  }, [usedCredits, userCredits]);

  //This will identify a visitor on hubspot
  const { setIdentity, setTrackPageView } = useTrackingCode();

  useEffect(() => {
    if (currentUser?.email) {
      setIdentity(currentUser?.email);
      setTrackPageView();
    }
  }, [currentUser, setIdentity, setTrackPageView]);

  return (
    <UserContext.Provider
      value={{
        login,
        validateEmailAndPassword,
        validateMfaCode,
        logout,
        isLoggedIn,
        currentUser,
        impersonate,
        userCredits,
        updateCurrentUser,
        hasWerknlBoardLicense,
        hasMonsterboardV2License,
        /// TODO WERKZOEKEN: Remove this after release new jobboard
        // hasWerkzoekenLicense,
        usedCredits,
        availableCredits,
        userEmailSettings,
        updateUserEmailSettingsProfile,
        userJobBoardSettings,
        updateUserJobBoardSettingsProfile,
        askBeforeSpendingMonsterInventoryCredit,
        /// TODO WERKZOEKEN: Remove this after release new jobboard
        // askBeforeSpendingWerkzoekenCredit,
        availableJobBoardsIsLoading,
        tenantCVSources,
        tenantCvSourcesIsLoading: tenantCvSourcesIsLoading || !(isLoggedIn && currentUser?.tenant.id),
        mailSignOff,
        isFirstUserLoads,
        isInvalidToken,
      }}>
      {children}
      <WerknlCandidateInfoPresentationDialog
        isVisible={shouldShowWerknlModal}
        onConfirm={() => setShouldShowWerknlModal(false)}
      />
    </UserContext.Provider>
  );
};
