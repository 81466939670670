import { createContext } from 'use-context-selector';
import type { Filters, FiltersContextProps } from './types';

const filtersInitialState: Filters = {
  job_titles: [],
  job_title_type: 'all',
  locations: [],
  has_email: false,
  has_phone: false,
  has_insight: false,
  total_experience: { max_experience_months: 1200, min_experience_months: 0 },
  skills: [],
  languages: [],
  updated_at: { key: 'any' },
  employment_type_preference: 'any',
  companies: [],
  industries: [],
  graduation_year: { max_months: 0, min_months: 0 },
  schools: [],
  education_degrees: [],
  education_fields: [],
  sources: [],
  sort_by: 'updated_at',
  default_location: { lat: 0, lon: 0 },
  hide_candidates_statuses: [],
  hide_candidates_period: 7,
};

export const initialState: FiltersContextProps = {
  isLoading: true,
  isLoadingSaveFilters: true,
  filters: filtersInitialState,
  searchFiltersControlState: {
    hasUnsavedChanges: false,
    loaded: false,
    lastSavedState: filtersInitialState,
    lastSavedAppliedFiltersLength: 0,
    isUsingHiddenFilters: false,
  },
  appliedFiltersLength: 0,
  searchesAsStringArray: [],
  setSearchFilters: () => undefined,
  setSearchFiltersControlState: () => undefined,
  saveSearchFilters: () => Promise.resolve(),
  getAppliedFiltersLength: () => 0,
  asteriskFieldsReached: false,
};

export const FiltersContext = createContext<FiltersContextProps>(initialState);
