import { useQuery } from '@tanstack/react-query';
import { IndeedMessagesListResponse, IndeedUnreadConversationCountResponse } from './indeed.types';
import { fetchIndeedGraphQL } from './utils';
import { useIndeedConfig } from './useIndeedConfig';
import {
  countUnreadIndeedMessagesPayload,
  getContactSummaryPayload,
  getNewIndeedMessagesPayload,
} from './indeed.payloads';
import { QueryKey } from 'types/query';

const INTERVAL = 1000 * 600;
const INDEED_NEW_MESSAGES_TAKE_DEFAULT_VALUE = 50;

export const useCountUnreadIneedMessages = () => {
  const indeedConfig = useIndeedConfig();

  const { data: unreadCount, dataUpdatedAt } = useQuery(
    [QueryKey.countUnreadIndeedMessages],
    async () => {
      const jsonData: IndeedUnreadConversationCountResponse = await fetchIndeedGraphQL(
        countUnreadIndeedMessagesPayload,
        indeedConfig,
      );

      const unreadCount = jsonData.data.getUnreadConversationCount.unreadConversationCount;
      return unreadCount <= 0 ? 0 : unreadCount;
    },
    {
      enabled: indeedConfig.isLoggedIn,
      refetchInterval: INTERVAL,
      refetchIntervalInBackground: true,
      initialData: 0,
      initialDataUpdatedAt: 0,
    },
  );

  return {
    unreadCount,
    dataUpdatedAt,
  };
};

export const useGetNewIndeedMessages = () => {
  const indeedConfig = useIndeedConfig();

  const { unreadCount, dataUpdatedAt } = useCountUnreadIneedMessages();

  const { data: newIndeedMessages } = useQuery(
    [QueryKey.getNewIndeedMessages, { dataUpdatedAt }],
    async () => {
      const jsonData: IndeedMessagesListResponse = await fetchIndeedGraphQL(
        getNewIndeedMessagesPayload(INDEED_NEW_MESSAGES_TAKE_DEFAULT_VALUE),
        indeedConfig,
      );
      const unreadMessages = jsonData.data.findConversations.conversations.filter((e) => {
        return e.userReadsInfo.unreadCount > 0;
      });

      return unreadMessages;
    },
    {
      enabled: indeedConfig.isLoggedIn && !!unreadCount && unreadCount > 0,
      initialData: [],
      initialDataUpdatedAt: 0,
    },
  );

  return {
    newIndeedMessages,
  };
};

export const useGetContactSummary = (esPersonId: string) => {
  const indeedConfig = useIndeedConfig();

  const { data: contactSummary } = useQuery(
    [QueryKey.getIndeedContactSummary, { esPersonId }],
    async () => {
      return await fetchIndeedGraphQL(getContactSummaryPayload(esPersonId), indeedConfig);
    },
    {
      initialData: [],
      initialDataUpdatedAt: 0,
    },
  );

  return {
    contactSummary,
  };
};
