import { css, Styles, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: ({ theme }) => css`
    position: relative;

    &::before {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 8px;
      padding: 4px;
      pointer-events: none;
    }

    &:hover::before {
      background-color: ${theme.colors.neutral.gray[20]};
    }
  `,
  content: () =>
    css({
      cursor: 'pointer',
      gap: 4,
    }),
  floatingPanel: css`
    position: absolute;
    right: -39px;
    top: 45px;

    &::before {
      content: '';
      position: absolute;
      height: 100%;
      width: 100px;
      top: -30px;
      right: 0;
    }
  `,
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
