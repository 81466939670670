import React, { ReactNode } from 'react';

export type Dictionary = {
  certification: string;
  since: string;
  notAvailableAnymore: string;
  workingHours: string;
  commuteDistance: string;
  relocation: string;
  salary: string;
  preferredJobs: string;
  total: string;
  unableToRefresh: string;
  current: string;
  past: string;
  noDataAvailable: string;
  cvAvailable: string;
  hours: string;

  login: string;
  rememberMe: string;
  logout: string;
  registerAccount: string;
  newCompany: string;
  required: string;
  showPassword: string;
  hidePassword: string;
  forgotPassword: string;
  forgotYourPassword: string;
  forgotPasswordDescription: string;
  resetPassword: string;
  multiFactorAuth: string;
  enterMultiFactorAuthCode: string;
  resetPasswordMailSentDescription: string;
  mailSent: string;
  pleaseEnterYourNewPassword: string;
  preferences: string;
  email: string;
  emailNotifications: string;
  sendEmail: string;
  username: string;
  userName: string;
  password: string;
  passwordRepeat: string;
  overview: string;
  new: string;
  viewAll: string;
  statistics: string;
  comingSoon: string;
  administration: string;
  connections: string;
  availableCredits: (left: number, total: number, isPayPerMatch?: boolean) => ReactNode;
  profile: string;
  cv: string;
  emailAddress: string;
  emailAddressForTeams: string;
  phoneNumber: string;
  nickname: string;
  companyName: string;
  companiesWorkedAt: string;
  companiesWorkedAtPlaceholder: string;
  companySize: string;
  companyType: string;
  selectCountry: string;
  selectATS: string;
  whatATSAreYouUsing: string;
  howDidYouHearAboutUs: string;
  pleaseExplain: string;
  save: string;
  submit: string;
  next: string;
  previous: string;
  back: string;
  cancel: string;
  saveChanges: string;
  changePassword: string;
  currentPassword: string;
  newPassword: string;
  repeatNewPassword: string;
  chooseFile: string;
  noFileChosen: string;
  roles: string;
  users: string;
  user: string;
  ats: string;
  atsCompleteName: string;
  addedToAts: string;
  jobBoards: string;
  useRolesToGroupPermissions: string;
  manageUsersAndPermissions: string;
  recruitmentAgency: string;
  corporate: string;
  freelancer: string;
  selectInHouseRecruiters: string;
  cantBeLessThanZeroRecruiters: string;
  thisFieldIsRequired: string;
  validationRequiredEmail: string;
  useYourWorkEmailAddress: string;
  validationPasswordConfirm: string;
  editCriteriaForMoreResults: string;
  newResultsInHours: (hours: number) => ReactNode;
  newResultsOnMonday: string;
  thereAreNewResults: (newResults: number) => string;
  untitledSearch: string;
  newSearch: string;
  searches: string;
  candidates: string;
  teamView: string;
  teamview: string;
  status: string;
  lastAdded: string;
  coWorker: string;
  search: string;
  sortBy: string;
  sortedBy: string;
  mySearches: string;
  teamSearches: string;
  allSearches: string;
  allShortlisted: string;
  allContacted: string;
  allMatched: string;
  allHidden: string;
  searchOverview: string;
  campaignNotificationByEmail: string;
  campaignNotificationDisabled: string;
  campaignNotificationOnce: string;
  campaignNotificationTwice: string;
  campaignNotificationThrice: string;
  sendToNotificationTitle: string;
  sendToNotificationPersonal: string;
  sendToNotificationTeam: string;
  sendToNotificationBoth: string;
  askBeforeSpendingMonsterInventoryCredit: string;
  /// TODO WERKZOEKEN: Remove this after release new jobboard
  // askBeforeSpendingWerkzoekenCredit: string;
  yesAskEveryTime: string;
  noNeverAsk: string;
  browserExtension: string;
  robinExtension: string;
  adminPanel: string;
  intakePlanned: string;

  yes: string;
  no: string;
  areYouSure: string;
  confirmDeleteCampaign: string;
  by: string;
  in: string;
  you: string;
  here: string;

  match: string;
  shortlistCandidateToOtherSearch: string;
  shortlistCandidateNameToOtherSearch: (name: string) => string;
  shortlistCandidateToOtherSearchDescription: string;
  reTriggerATSCandidateSync: string;
  hideFromApproved: string;

  matched: string;
  hidden: string;
  shortlist: string;
  shortlisted: string;
  unsaved: string;
  saved: string;
  connectToAts: string;
  connectToAtsVacancy: string;
  unlocked: string;
  downloadProfiles: string;
  history: string;
  activity: string;
  profileHistoryPlaceholder: string;
  details: string;
  educationLevel: string;
  employer: string;
  travelDistance: string;
  availability: string;
  contactInfo: string;
  Monsterboard: string;
  Nationalevacaturebank: string;
  Werknl: string;
  version: string;
  downloadAllApprovedCandidates: string;
  downloadAllAMatched: string;
  maxOf300CandidatesWillBeDownloaded: string;
  searchForAts: string;

  iAlreadyKnow: string;
  overqualified: string;
  underqualified: string;
  wrongIndustry: string;
  wrongEducation: string;
  wrongExperience: string;
  tooFarAway: string;
  otherReasonNamely: string;
  other: string;
  hide: string;
  check: string;

  contact: string;
  contacted: string;
  positiveResponse: string;
  negativeResponse: string;
  noResponse: string;
  searching: string;
  sentToAts: string;
  interviewScheduled: string;
  interviewPlaneed: string;
  interviewCompleted: string;
  interviewCancelled: string;
  leftVoicemail: string;
  openToWorkNotInterested: string;
  notOpenToWork: string;
  noResponsiveToReachOut: string;

  experience: string;
  courses: string;
  certifications: string;
  education: string;
  skill: string;
  skills: string;
  language: string;
  languages: string;
  VolunteerWork: string;
  industry: string;
  company: string;
  date: string;

  whyRejected: string;
  whatIsTheStatus: string;
  updateStatus: string;

  //FORM
  explanation: string;
  nameSearch: string;
  vacancy: string;
  vacancyPlaceholder: string;
  jobTitle: string;
  jobTitles: string;
  jobTitlesTooltip: React.ReactNode;
  addSuggestions: (number: number) => string;
  jobTitlePlaceholder: string;
  addJobTitlePlaceholder: string;
  jobTitleWarning: string;
  workExperience: string;
  minWorkExperienceInYears: string;
  maxWorkExperienceInYears: string;
  minWorkExperienceInFunction: string;
  screeningCriteria: string;
  screeningCriteriaPlaceholder: string;
  country: string;
  postalCode: string;
  rangeInKm: string;
  startSearch: string;
  editSearch: string;
  eg: string;
  errorDuplicatedJobTitle: string;

  veryWell: string;
  iWillStartLookingForCandidates: (campaignName: string) => ReactNode;
  whatToExpect: string;
  whatToExpect1: string;
  whatToExpect2: string;
  editThisCampaign: string;
  viewCampaigns: string;
  searchForCampaign: string;
  ratioOfApprovedCandidates: (approved: number, total: number) => ReactNode;
  seeResults: string;
  notAbleDeleteSearchAts: string;

  more: string;
  move: string;
  moveTo: string;

  contactInfoSetting: string;
  activeJobSeekersSetting: string;

  //Job Boards
  newJobBoard: string;
  editJobBoard: string;
  invalidCredentials: string;
  validATSCredentials: string;
  invalidMfaCode: string;
  credentialsExpired: string;
  jobBoardCredentialVerificationTitle: string;
  jobBoardCredentialVerificationDescription: string;
  jobBoardInvalidCredentials: string;
  jobBoardDuplicatedCredentials: string;
  jobBoardConnectionActive: string;
  jobBoardConnectionInActive: string;
  confirmDeleteJobBoard: string;
  confirmDisableJobBoard: string;
  jobBoardExplanation: string;
  jobBoardUsernameExplanation: string;
  jobBoardPasswordExplanation: string;
  jobBoardSecret: string;
  jobBoardSecretExplanation: string;
  licenseActive: string;
  licenseInActive: string;
  license: string;
  addLicense: string;

  //ATS
  addNew: string;
  atsVacancy: string;
  viewATSConnections: string;
  unableToConnectToATS: string;
  myConnections: string;
  teamConnections: string;
  usersWithoutConnections: string;
  atsKey: string;
  atsUrl: string;
  atsName: string;
  clientSecret: string;
  requestATSConnection: string;
  atsRequestedMessage: ReactNode;
  checkOverview: string;
  confirmDeleteATS: string;
  confirmSwitchATS: (ats: string) => ReactNode;
  atsExplanation: string;
  atsUsernameExplanation: string;
  atsPasswordExplanation: string;
  atsKeyExplanation: string;
  atsSecret: string;
  atsSecretExplanation: string;
  atsConnectionAltTitle: (id: number, name: string) => string;
  seeDetails: string;
  /// ATS Credentiasl error Modal
  showMoreErrorDetails: string;
  atsCredentialsErrorModalBody: string;

  campaignCompletedTitle: string;
  campaignCompletedMessage: ReactNode;
  campaignGroup1CompletedMessage: ReactNode;
  searchingMoreProfiles: string;
  searchAgain: string;
  reviewAlternativeCandidates: string;

  mustBeLessThanMax: string;
  mustBeMoreThanMin: string;

  // common
  any: string;
  of: string;
  add: string;
  edit: string;
  delete: string;
  activate: string;
  deactivate: string;
  close: string;
  remove: string;
  exclude: string;
  mustHave: string;
  saving: string;
  done: string;
  loading: string;
  thisCouldTakeAFewMinutes: string;
  changesSaved: string;
  invalidUsername: string;
  invalidPassword: string;
  clickHere: string;
  selectAll: string;
  clearAll: string;
  scrollTo: string;
  otherwiseNamely: string;
  somethingWentWrong: string;
  pleaseTryAgainOrContactUs: string;
  notFound: string;
  loggingYouInAutomatically: string;
  couldNotLogYouInAutomatically: string;
  attemptNofX: (current: number, max: number) => string;
  minimumCharacterValue: (value: string | number) => string;
  maximumCharacterValue: (value: string | number) => string;
  companyNameCannotContainSpecialCharacters: string;
  selectAnOption: string;
  and: string;
  or: string;
  expandLess: string;
  expandMore: string;
  downloadProfile: string;
  tutorial: string;
  emailSuccessfullySent: string;
  emailFailedSend: string;
  linkedinSuccessfullySent: string;
  linkedinFailedSend: string;
  linkedinInMailSuccessfullySent: string;
  liknedinInMailFailedSend: string;
  indeedSuccessfullySent: string;
  indeedFailedSend: string;

  //Languages
  english: string;
  dutch: string;

  //Users
  actions: string;
  exportToExcel: string;
  createNewUser: string;
  manageUsers: string;
  userActivationSettings: string;
  isActive: string;
  userUnlock: string;
  lastLoggedIn: string;
  userSince: string;
  emailConfirmed: string;

  userCreatedSuccessfully: string;
  userProfileUpdatedSuccessfully: string;
  userDeactivateConfirmation: string;
  userActivateConfirmation: string;
  userWasUnlockedSuccessfully: string;
  userWasDeletedSuccessfully: string;
  userWasActivatedSucessfully: string;

  editUser: string;
  invalidEmailAddress: string;
  invalidPhoneNumber: string;
  atLeast6Characters: string;
  atLeast12Characters: string;
  passwordMustContainDigit: string;
  passwordMustContainUppercase: string;
  passwordMustContainLowercase: string;

  userPasswordSettings: string;
  setRandomPasswordForUser: string;
  setNewPassword: string;
  shouldChangePasswordOnNextLogin: string;
  sendActivationEmail: string;
  setUserIsActive: string;
  setLockOut: string;
  remindPassword: string;
  incorrectPassword: string;
  passwordsDoNotMatch: string;
  passwordIsTheSame: string;

  //While the user is logged out
  emailActivation: string;
  emailActivationDescription: string;
  emailActivationSentDescription: string;

  invalidResetLink: string;
  invalidResetLinkDescription: string;
  passwordResetComplete: string;
  goToLogin: string;
  goToLoginDescription: string;

  companyNameIsTaken: string;

  //Realtime Search filters
  activeFilters: (num: number) => string;
  filters: string;
  totalExperience: string;
  location: string;
  locations: string;
  locationsPlaceholder: string;
  skillsKeywords: string;
  skillsKeywordsPlaceholder: string;
  mustHaveEmail: string;
  mustHavePhone: string;
  sources: string;
  schoolName: string;
  schoolNamePlaceholder: string;
  educationDegree: string;
  educationDegreePlaceholder: string;
  educationFields: string;
  educationFieldsPlaceholder: string;
  sizeOfBatches: string;
  locationDistanceInKm: string;
  searchResults: string;
  updatedAt: string;
  graduationYear: string;
  employmentType: string;

  //Updated At Filter
  anyTime: string;
  last24Hours: string;
  last3Days: string;
  lastWeek: string;
  lastMonth: string;
  last3Months: string;
  last6Months: string;
  lastYear: string;

  //Employment Type Filter
  employmentTypeFullTime: string;
  employmentTypePartTime: string;
  employmentTypeInternship: string;
  employmentTypeFreelance: string;
  employmentTypeShortTerm: string;
  employmentTypeSeasonal: string;

  //Job Title Type Filter
  jobTitleType: string;
  jobTitleTypeAll: string;
  jobTitleTypeCurrent: string;
  jobTitleTypeRecent: string;
  jobTitleTypePast: string;

  //Realtime Search Results
  candidatePreferences: string;
  interests: string;
  source: string;
  lastUpdated: string;
  lastCrawled: string;
  lastMatched: string;
  lastShortlisted: string;
  lastContacted: string;
  lastHidden: string;
  couldNotRefreshProfile: string;
  profileNotAvailable: string;
  phone: string;
  academicExperience: string;
  addSomeFiltersMessage: string;
  noResultsMessage: string;
  industries: string;
  aboutMe: string;
  licenses: string;
  openToWork: string;
  autoSavedFilters: string;
  credentialsDisabledCallToAction: (platform: string) => string;

  helpCenter: string;

  help: string;

  notifications: string;
  markNotificationAsRead: string;
  markAllAsRead: string;
  seeSearchInList: string;
  year: string;
  years: string;
  month: string;
  months: string;
  inTotal: string;
  inFunction: string;
  viewFullProfile: string;
  pleaseWait: string;
  downloadCV: string;
  downloadCVs: string;
  pageNumOfNum: (current: number | string, total: number | string) => string;
  issued: string;
  resume: string;
  licensesAndCertifications: string;
  jobs: string;
  minWorkHours: string;
  maxWorkHours: string;
  minSalary: string;
  maxSalary: string;
  currency: string;
  unlockContactInformation: (price: number) => string;
  saveAndUnlockContactInformation: (price: number) => string;
  unlockCV: (price: number) => string;
  currentOfTotal: (current: number | string, total: number | string) => string;
  showMore: string;
  showLess: string;
  showXMore: (quantity: number) => string;
  showXLess: (quantity: number) => string;
  showAll: (quantity: number) => string;
  plusMore: (quantity: number) => string;
  requestContactInformation: string;
  youCanRequestContactInformation: string;
  thisProfileWasUpdatedTimeAgo: (timeAgo: string) => string;
  thisProfileWasRefreshedTimeAgo: (timeAgo: string) => string;
  thisProfileWasApprovedTimeAgo: (timeAgo: string) => string;
  loading_ats_synchronization_label: string;
  loading_contact_info_label: string;
  userFormGenericError: string;
  userFormEmailAlreadyTakenError: string;
  errorSectionTitle: string;
  errorSectionMissingSkillOrFunction: string;
  duplicate: string;
  copyOf: string;
  clickHereToUpdateSearch: string;
  orSimplyPress: (params: { isMacOs: boolean }) => ReactNode;

  // Teams
  team: string;
  teams: string;
  teamName: string;
  teamUsers: string;
  manageTeams: string;
  createNewTeam: string;
  createdBy: string;
  createdAt: string;
  editTeam: string;
  teamDeletionConfirmation: string;
  teamWasDeletedSuccessfully: string;
  teamCreatedSuccessfully: string;
  teamUpdatedSuccessfully: string;
  editTenantEmailSettingsBtn: string;
  editTenantEmailSettings: string;
  editTenantEmailSettingsExplanation: string;
  tenantEmailSettingsCreatedSuccessfully: string;
  tenantEmailSettingsUpdatedSuccessfully: string;
  joinTeamSuccessfully: string;
  exitTeamSuccessfully: string;
  join: string;
  leave: string;
  detailsTeam: string;

  updateContactInformation: string;
  contactInfoAvailableFrom: (platform: string) => string;
  requestHere: string;
  refreshHappensAutomatically: string;
  notificationJobbboardNewVersion: (platform: string) => string;
  requestJobboardInformationConfirmationTitle: (platform: string) => string;
  requestJobboardInformationConfirmationDescription: (platform: string) => string;
  requestJobboardInformationConfirmationCancelText: string;
  requestJobboardInformationConfirmationConfirmText: string;

  dontPerformThisCheckNextTime: string;

  candidateNotFoundTitle: string;
  candidateNotFoundSubtitle: string;

  /// Platforms
  whatsApp: string;
  linkedIn: string;
  linkedInInMail: string;
  indeed: string;

  /// ContactModal
  chooseContactTitleModal: string;
  chooseContactDescriptionModal: (name: string) => string;
  writeEmailTo: (name: string) => string;
  writeMessageTo: (name: string) => string;
  sendWithWhatsAppWeb: string;
  sendWithIndeed: string;
  sendLinkedInRequest: string;
  sendMessage: string;
  sendInMail: string;
  sentWhatsAppTo: (name: string) => string;
  sentEmailTo: (name: string) => string;
  sentOutreachMessageTo: (channel: string, candidateName: string) => string;
  emailSent: string;
  emailReceived: string;
  emailReceivedFrom: (name: string) => string;
  reply: string;
  indeedSent: string;
  indeedReceived: string;
  indeedJobDropdownPlaceholder: string;
  indeedNoJob: string;
  indeedNoJobsFound: string;
  indeedProjectDropdownPlaceholder: string;
  whatsappSent: string;
  linkedinConnectionSent: string;
  linkedinInmailSent: string;
  sentIndeedTo: (name: string) => string;
  sentLinkedInTo: (name: string) => string;
  sentLinkedInMailTo: (name: string) => string;
  sentWerknlMailTo: (name: string) => string;
  sentBy: string;
  sendDisabledIndeedTip: string;
  sendDisabledLinkedinTip: string;

  callingWith: (candidateName: string) => string;
  sendEmailTo: (candidateName: string) => string;
  sendWhatsAppMessageTo: (candidateName: string) => string;
  sendIndeedMessageTo: (candidateName: string) => string;
  replyIndeedMessageTo: (candidateName: string) => string;
  sendWerknlMessageTo: (candidateName: string) => string;
  newLinkedinMessageTo: (candidateName: string) => string;
  newLinkedinInMailMessageTo: (candidateName: string) => string;

  /// Connect Phone Modal
  connectYourPhoneTitleModal: string;
  connectYourPhoneDescriptionModal: string;
  showMeHow: string;
  dontShowThisAgain: string;
  imConnected: string;
  downloadTheApp: string;

  /// Browser Extension Modal
  browserExtensionRequired: string;
  browserExtensionRequiredDescriptionModal: string;
  installTheExtension: string;

  /// Confirm Message Modal
  confirmMessageTitleModal: string;
  confirmMessageDescriptionModal: string;
  messageNotSent: string;
  messageSent: string;

  /// Confirm Call Modal
  confirmCallTitleModal: (name: string) => string;
  confirmCallDescriptionModal: string;
  couldntConnect: string;
  connected: string;
  callSaved: string;

  /// Fields tips
  campaignNotificationByEmailTip: string;
  nameSearchTip: string;
  jobTitleTip: string;
  jobTitleTypeTip: string;
  locationDistanceInKmTip: string;
  contactInfoTip: string;
  totalExperienceTip: string;
  languagesTip: string;
  lastUpdatedTip: string;
  graduationYearTip: string;
  schoolNameTip: string;
  educationDegreeTip: string;

  /// Admin
  backToSearchCampaigns: string;
  createNewCompany: string;
  editCompany: string;
  editUsers: string;
  impersonateUser: string;
  creditLimit: string;
  updateUsersSuccessfully: string;
  id: string;
  companyCode: string;
  edition: string;
  active: string;
  inactive: string;
  credits: string;
  created: string;
  updated: string;
  demo: string;
  standard: string;
  tenantHasBeenUpdatedSuccessfully: string;
  tenantHasBeenCreatedSuccessfully: string;
  tenantName: string;
  removeSpaces: string;
  subscriptionEdition: string;
  editTenantUsers: string;
  editTenantUsersSubtitle: string;
  name: string;
  firstName: string;
  lastName: string;
  fullName: string;
  lastPosition: string;
  lastCompany: string;
  recruiterFullName: string;
  nameJobboard: string;
  nameVacancy: string;
  addAnotherUser: string;
  impersonate: string;
  searchCompany: string;
  entriesPerPage: string;

  matchPhase: string;
  none: string;

  website: string;

  typeYourMessage: string;
  typeYourNote: string;
  subject: string;
  signOff: string;
  featureToggles: string;
  addFeatureToggle: string;
  title: string;
  value: string;
  enabled: string;
  emptySubject: string;
  emptyMessage: string;
  emptySignOff: string;
  yearAbbr: string;
  yearsAbbr: string;
  simplified: string;
  addLabels: string;
  labelAdded: string;
  noteAdded: string;
  fresh: string;
  candidateHasXNotes: (labelsCount: number) => string;

  // Candidates Insights
  newCandidate: string;
  updatedInformation: string;
  educationAddedRecently: string;
  educationDatesChanged: string;
  educationEditedRecently: string;
  experienceAddedRecently: string;
  jobRoleChanged: string;
  jobDescriptionChanged: string;
  jobDatesChanged: string;
  skillAddedRecently: string;
  preferredEmploymentTypeChanged: string;
  preferredWorkHoursChanged: string;
  workAvailabilityChanged: string;
  locationChangedRecently: string;
  phoneAddedRecently: string;
  phoneNumberChanged: string;
  emailAddedRecently: string;
  emailChanged: string;

  latestActions: string;

  titlePlaceholder: string;

  refineTheSearchBelow: string;

  indeedNoCredits: string;

  createNewTemplate: string;
  nameYourTemplate: string;
  blankTemplate: string;
  saveTemplate: string;
  selectTemplateToUse: (type: string) => string;
  templateSavedSuccessfully: string;
  templateTokens: string;
  lastActioned: string;
  oldestActioned: string;
  haveSeenItAll: string;
  sharedByFromSearch: (params: { userName: React.ReactNode; searchName: React.ReactNode }) => React.ReactNode;
  copyLinkCandidateProfile: string;
  linkCopySuccess: string;
  linkCopyFail: string;
  youNotHaveAccessProfile: string;
  tryResendAts: string;
  emailAndPhoneAvailableUponApproval: (email: boolean, phone: boolean) => string;
  cvAvailableUponApproval: string;

  changeOwner: string;
  currentOwner: string;
  makeUserSearchOwner: (userName: string) => string;
  makeUserSearchOwnerDescription: (userName: string) => string;
  goAhead: string;
  currentSearch: string;
  labelsChanged: string;
  notes: string;
  deleteNote: string;
  editNote: string;
  startTypingYourNotesHere: string;
  moreInfoSent: string;
  notResponding: string;
  notInterested: string;
  interested: string;
  declined: string;
  positiveOutcomes: string;
  neutralOutcomes: string;
  negativeOutcomes: string;
  customLabels: string;
  createCustomLabel: string;
  removed: string;
  paymentMethod: string;
  triggerToSendCandidateToAts: string;
  chooseAnOption: string;
  selectATemplateToUse: (type: SelectAnTemplateToUseTypes) => string;
  templates: string;
  templateName: string;
  nameTheTemplate: string;
  newOutreach: string;
  content: string;
  signature: string;
  signatureSave: string;
  signaturePlaceholder: string;
  signatureDescription: string;
  signatureImageHelper: string;
  signatureSaving: string;
  signatureSaveError: string;
  signatureSaveErrorImageConversion: string;
  signatureSaveSuccess: string;
  calling: string;
  candidateNotes: string;
  successfull: string;
  unsuccessfull: string;
  findMoreGreatCandidates: string;
  tryAnotherOutreachMethod: string;
  outreachPhoneSuccessfullHeaderText: string;
  outreachPhoneUnsuccessfullHeaderText: string;
  filterByLabel: string;
  outreachResponseNoticeEmail: React.ReactNode;
  outreachResponseNoticeWhatsApp: string;
  outreachResponseNoticeInMail: string;
  outreachResponseNoticeLinkedIn: string;
  outreachResponseNoticeIndeed: string;
  outreachResponseNoticeWerknl: React.ReactNode;
  jobboardRestrictionMessage: (days: number, platform: string) => string;
  unavailableOnThisContactMethod: string;
  browserExtensionNotInstalled: string;
  needsLoginOnJobboard: string;
  needsConnectMobileApp: string;
  notesModificationHistory: string;
  lastEditedBy: string;
  seeFullHistory: string;
  emailCandidate: string;
  callCandidate: string;
  workingOnIt: string;
  call: string;
  callMobileByPushNotification: string;
  searchNameOrOwnerOfTheSearch: string;
  candidateNoLongerAvailable: string;
  canceledByUser: string;
  callingIsAutomaticDescription: string;
  makeActive: string;
  makeInactive: string;
  filterBy: string;
  lastContactedBy: (params: { when: string }) => string;
  noTemplateAvailable: string;
  createNewSearch: string;
  creatingNewSearch: string;

  consumerKey: string;
  consumerSecret: string;

  atsConnectedSuccessfully: (ats: string) => string;
  weAreConnectingToYourATS: string;
  atsCandidateStatusChanged: string;
  atsNoteAdded: string;
  atsEmailSent: string;
  noCreditsAvailable: string;
  selectAProject: string;
  emailOrPhone: string;
  candidateSuccessfullyShortlistedToAnotherSearch: string;

  noCreditsAvailableForMonsterboardTitle: string;
  noCreditsAvailableForMonsterboardDescription: () => React.ReactNode;
  errorRetrievingDepartments: string;
  noDepartmentsFound: string;
  activateBeforeSearchDepartments: string;
  candidateAlreadyInteracted: string;
  newVersionAvailable: string;
  newVersionAvailableDescription: () => React.ReactNode;
  continue: string;
  languageSelection: string;
  googleTranslateMessage: () => React.ReactNode;
  maxAsteriskReached: string;
  maxAsteriskReachedModalDescription: string;
  maxAsteriskReachedModalClickHere: (params: { href: string }) => React.ReactNode;
  hideCandidatesThat: string;
  forPeriodOf: string;
  aiSuggestions: string;
  searchInputPlaceholder: string;
  findCandidates: string;
  lastSearches: string;
  noSearchesFound: string;
  didntFindJobTitleLocation: string;
  robinYearWrapped: (params: { year: number }) => string;
  robinWrapped: string;
  downloadYearWrapped: (params: { year: number }) => string;
  wrappedYear: (params: { year: number }) => string;
  wrappedSummary: (params: { tenantName: string }) => string;
  matchedInYear: (params: { year: number }) => string;
  shortlistedInYear: (params: { year: number }) => string;
  contactedInYear: (params: { year: number }) => string;
  topJobboard: string;
  mostActiveUser: string;
  totalAction: string;
  lengthLimitReached: string;
  getConnectedInstantly: string;
  downloadOurMobileApp: string;
  downloadOurMobileAppDescription: string;
  yourCallIsJustATapAway: string;
  checkYourPhoneForADirectCall: string;
  downloadRobinMobileApp: string;
  selectYourDevicePlatform: string;
  tapToCallCandidate: (candidateName: string) => string;
  youCanCopyTheLinkPressingCtrlC: string;
  info: string;
  success: string;
  error: string;
  warning: string;
  welcomeBack: string;

  dashboard: string;

  summary: string;
  summaryOfActions: string;
  addSummary: string;
  removeSummary: string;
  allUsers: string;
  allCampaigns: string;

  overviewOfActions: string;
  onlyLastThreeMonths: string;

  leaderboard: string;
  summaryOfActivityForUsers: string;
  mostUsedJobboardsForMatched: string;
  allActions: string;
  campaignCreated: string;
  campaignListEmptyStateMessage: string;

  exportInPDF: string;
  noMoreResults: string;
  usersSelected: string;
  campaignsSelected: string;
  clickHereToLoginBackIn: string;
  loginSuccessfully: string;
  browserExtensionIsDisabledOrNotInstalled: string;
  clickHereToRefreshThisPage: string;
  toDisconnectClickTheButton: string;

  shouldInstallBrowserExtensionTitle: string;
  shouldInstallBrowserExtensionModal: React.ReactNode;
  shouldCallWerknlOutreachTitle: string;
  shouldCallWerknlOutreachModal: React.ReactNode;
  linkedinRunOutCreditsMessage: string;
  linkedinRunOutCreditsSendMessage: string;
  linkedinRunOutCreditsSendingMessage: (params: { onClick: () => void }) => React.ReactNode;
  werknlMessageTo: (name: string) => string;
  werknlInputEmail: string;
  werknlInputPhone: string;

  settings: string;
  emailSettings: string;
  connectEmailAccount: string;
  reconnectEmailAccount: string;
  theConnectionWasASuccess: string;
  theConnectionWasASuccessDescription: string;
  theConnectionFailed: string;
  theConnectionFailedDescription: string;
  backToRobin: string;
  disconnectEmailAccount: string;
  emailNotConnectedDescription: React.ReactNode;
  emailConnectedDescription: string;
  emailConnections: string;
  disconnectEmailAccountModalDescription: string;
  connectEmailModalDescription: string;
  connectEmailDescription: React.ReactNode;
  remindToConnectEmail: (name: string) => React.ReactNode;
  remindToReconnectEmail: (name: string) => React.ReactNode;
  werknlProfiles: string;
  waitForTheCampaignToFullyLoad: string;
  werknlIsCurrentlyDisabled: string;
  loadingCandidateCv: string;
  noShortlistedCandidate: (params: { onClick: () => void }) => React.ReactNode;
  noOtherStatusCandidate: (status: string) => string;
  werknlCandidateInfoPresentation: React.ReactNode;
  werknlCandidateInfoPresentationDescription: string;
  werknlCandidateInfoPresentationNote: string;
  old: string;
  confirmSelection: string;
  werknlLayout: string;
  cvFormatIssues: string;
  disconnectVacancy: string;
  clickToActivatePlatform: (name: string) => string;
  fromTo: (from: string, to: string) => string;
  from: string;
  newCandidateAdded: string;
  for: string;
  called: string;
  confirmDeleteNote: string;
  robinResume: string;
  robinResumeDescription: (atsName?: string) => React.ReactNode;
  confirmStatus: string;
  statusSavedWithSuccess: string;
  syncingYourAtsStatuses: string;
  statusConfirmed: string;
  readMore: string;
  loginToIndeed: string;
  indeedAssignedMessageInfo: (email: ReactNode) => ReactNode;
  indeedUnassignedMessageInfo: ReactNode;
  indeedLicenseRequired: ReactNode;
  indeedLicense: string;
  isAdmin: string;
  indeedLicensesCanBeAssigned: string;
  indeedSubscriptionConnectedTitle: string;
  indeedSubscriptionConnectedDescription: (email: string) => React.ReactNode;
  noIndeedSubscriptionConnectedTitle: string;
  noIndeedSubscriptionConnectedDescription: React.ReactNode;
  indeedSubscriptionAvailableTitle: string;
  indeedSubscriptionAvailableDescription: (emails: string[]) => React.ReactNode;
  noIndeedSubscriptionAvailableTitle: string;
  noIndeedSubscriptionAvailableDescription: React.ReactNode;
  indeedSubscriptionGrantedTitle: string;
  indeedSubscriptionGrantedDescription: (email: string) => React.ReactNode;
  indeedSubscriptionWithdrawnTitle: string;
  indeedSubscriptionWithdrawnDescription: (email: string) => React.ReactNode;
  indeedSubscriptionWithdrawnSubscriptionAvailableTitle: string;
  indeedSubscriptionWithdrawnSubscriptionAvailableDescription: (email: string, emails: string[]) => React.ReactNode;
  indeedSubscriptionExpiredWithAvailableTitle: string;
  indeedSubscriptionExpiredWithAvailableDescription: (emails: string[]) => React.ReactNode;
  indeedSubscriptionExpiredTitle: string;
  indeedSubscriptionExpiredDescription: React.ReactNode;
  indeedSubscriptionConnectedAdminTitle: string;
  indeedSubscriptionConnectedAdminDescription: (email: string) => React.ReactNode;
  noIndeedSubscriptionConnectedAdminTitle: string;
  noIndeedSubscriptionConnectedAdminDescription: React.ReactNode;
  noIndeedActiveSubscriptionWithAvailableTitle: string;
  noIndeedActiveSubscriptionWithAvailableDescription: (email: string, emails: string[]) => React.ReactNode;
  noIndeedActiveSubscriptionTitle: string;
  noIndeedActiveSubscriptionDescription: (email: string) => React.ReactNode;
  unassignLicense: string;
  statusNeedToBeConfirmedForThisTenant: string;
  robinResumeDashboardDescription: string;
  totalNumberOfCvs: string;
  startsWith: string;
  contains: string;
  endsWith: string;

  // Central Inbox
  inbox: string;
  emptyConversations: string;
};

export type SupportedLanguage = 'en' | 'nl';

export type SupportedLocale = 'en-US' | 'nl-NL';

export type ContextProps = {
  language: SupportedLanguage;
  dictionary: Dictionary;
  locale: SupportedLocale;
  setLanguage: any;
};

export type LocalizationContextProviderProps = {
  children: React.ReactNode;
};

export type MetaExperience = {
  first?: number;
  last?: number;
  gap?: number;
};

type SelectAnTemplateToUseTypes = 'whatsapp' | 'email' | 'phone' | 'linkedin' | 'indeed' | 'werknl';

export const DICTIONARY_LINKS = {
  ROBIN_EXTENSION: 'https://help.recruitrobin.com/nl/articles/10342349-hoe-werk-ik-met-indeed',
  EXISTING_SUBSCRIPTION: 'https://help.recruitrobin.com/nl/articles/10342349-hoe-werk-ik-met-indeed',
  BUY_NEW_SUBSCRIPTION: 'https://help.recruitrobin.com/nl/articles/10342349-hoe-werk-ik-met-indeed',
  INDEED_KNOW_MORE: 'https://help.recruitrobin.com/nl/articles/10342349-hoe-werk-ik-met-indeed',
};
