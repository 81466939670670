import type { AxiosResponse } from 'axios';
import type {
  AutoCompleteHandler,
  CandidateRemoveMatchHandler,
  GetCandidateByIdHandler,
  GetJobTitleSuggestionsHandler,
  LocationResponse,
  SaveCandidateContactInfoHandler,
  SearchRatedCandidateHandler,
} from './SearchClient.types';

import { BaseClient } from 'clients/BaseClient/BaseClient';
import { hosts } from 'config';

export class SearchClient extends BaseClient {
  constructor() {
    super();
    this.getLocationDetails = this.getLocationDetails.bind(this);
    this.getMatchCandidates = this.getMatchCandidates.bind(this);
  }

  getSearchRatedCandidates: SearchRatedCandidateHandler = ({
    search = '',
    limit = 5,
    offset = 0,
    status,
    sort,
    labelsFilter,
    team = undefined,
  }) => {
    return this.request({
      url: `${hosts.campaignsApi()}/campaigns/candidates/`,
      method: 'GET',
      params: {
        search,
        limit,
        offset,
        status,
        reasons: labelsFilter,
        team: team,

        ...(sort && { ordering: `${sort.order === 'DESC' ? '-' : ''}${sort.by}` }),
      },
    });
  };

  autoComplete: AutoCompleteHandler = ({ prefix, field }) => {
    return this.request({
      url: `${hosts.searchApi()}/autocomplete`,
      method: 'POST',
      data: {
        field,
        prefix,
      },
    });
  };

  async suggestions(title: string, TakenJobTitles: string[]): Promise<AxiosResponse<{ suggestions: string[] }>> {
    return this.request({
      url: `${hosts.searchApi()}/suggestions`,
      method: 'POST',
      data: {
        title,
        exclude: TakenJobTitles,
      },
    });
  }

  getJobTitleSuggestions: GetJobTitleSuggestionsHandler = ({ title, exclude }) => {
    return this.request({
      url: `${hosts.searchApi()}/job_title_suggestions`,
      method: 'POST',
      data: { title, exclude },
    });
  };

  async getLocationDetails(placeId: string) {
    return this.request<LocationResponse>({
      url: `${hosts.searchApi()}/location`,
      method: 'POST',
      data: {
        place_id: placeId,
      },
    });
  }

  async getMatchCandidates(candidates: any) {
    return this.request({
      url: `${hosts.searchApi()}/match_candidates`,
      method: 'POST',
      data: {
        candidates: candidates,
      },
    });
  }

  getCandidateById: GetCandidateByIdHandler = (candidateId) => {
    return this.request({
      url: `${hosts.searchApi()}/profiles`,
      method: 'POST',
      data: {
        profiles: [candidateId],
      },
    });
  };

  candidateRemoveMatch: CandidateRemoveMatchHandler = ({ es_person_id }) => {
    return this.request({
      url: `${hosts.searchApi()}/match_candidates/${es_person_id}/`,
      method: 'DELETE',
    });
  };

  saveCandidateContactInfo: SaveCandidateContactInfoHandler = ({ phoneNumber, email, esPersonId }) => {
    return this.request({
      url: `${hosts.searchApi()}/candidate_contact_info`,
      method: 'POST',
      data: {
        phone_number: phoneNumber,
        email: email,
        es_person_id: esPersonId,
      },
    });
  };
}

export const searchClient = new SearchClient();
