import type { CandidateActivityLogResponse } from 'clients/CampaignsClient/CampaignsClient.types';
import type { default as Candidate } from 'model/Candidate/Candidate';
import type { CandidateHistory } from './useCandidateHistoryQuery.types';

import { useQuery } from '@tanstack/react-query';
import { campaignsClient } from 'clients/CampaignsClient/CampaignsClient';
import { useContext } from 'react';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { QueryKey } from 'types/query';
import { sanitizeEmailHtml } from 'utils/sanitizeEmailHtml';
import { extractActivityLogFromResponse } from './useCandidateActivityLogQuery';

export const useCandidateHistoryQuery = (
  candidate?: Candidate | null,
  options: { enabled?: boolean } = { enabled: true },
) => {
  const { language } = useContext(LocalizationContext);

  return useQuery<CandidateHistory[]>(
    [QueryKey.useCandidateHistoryQuery, { candidateEsPersonId: candidate?.es_person_id, status: candidate?.status }],
    async () => {
      const { data } = await campaignsClient.getCandidateHistory({
        candidate_id: candidate?.es_person_id,
      });

      return data
        .map((history) => {
          const historyAsActivityLog: CandidateActivityLogResponse = {
            ...history,
            created_at: history.date,
          };
          const activityLog = extractActivityLogFromResponse(historyAsActivityLog, language);

          if (!activityLog) return;

          const hasContent =
            (typeof history.candidate_notification === 'object' &&
              Object.keys(history.candidate_notification).length > 0) ||
            history.content.length > 0;

          return {
            ...activityLog,
            ...(hasContent && {
              content: {
                subject: sanitizeEmailHtml(history.subject || history.candidate_notification?.subject || ''),
                message: sanitizeEmailHtml(history.content || history.candidate_notification?.message || ''),
                signoff: sanitizeEmailHtml(history.candidate_notification?.sign_off || ''),
              },
            }),
            ats: history.ats,
          } satisfies CandidateHistory;
        })
        .filter(Boolean) as CandidateHistory[];
    },
    { enabled: !!candidate && options.enabled, initialData: [], initialDataUpdatedAt: 0 },
  );
};
