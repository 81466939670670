import { OutreachTokenKey } from '@recruitrobin/robin-theme/web-components';
import { jobBoardLabel } from 'helpers/jobBoards';
import { useBrowserExtensionStatusQuery } from 'hooks/shared/useBrowserExtensionStatusQuery';
import { Candidate } from 'model';
import { useContext, useMemo } from 'react';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { useFiltersContext } from 'shared/contexts/SearchContext/FiltersContext/helpers';
import { SelectedCampaignContext } from 'shared/contexts/SelectedCampaignContext';
import { UserContext } from 'shared/contexts/UserContext/UserContext';
import { useIndeedConfig } from 'shared/hooks/useBrowserExtension/indeed/useIndeedConfig';
import { useLinkedinConfig } from 'shared/hooks/useBrowserExtension/linkedin/useLinkedinConfig';
import { useLinkedinInMailConfig } from 'shared/hooks/useBrowserExtension/linkedinInMail/useLinkedinInMailConfig';
import useCvSources from 'shared/hooks/useCvSources';
import { useContextSelector } from 'use-context-selector';
import { useIsWerknlActive } from '../../../../hooks/search/useIsWerknlActive';
import {
  CandidateContextProps,
  OutreachInformationWhyNotLabel,
  OutreachInformationWhyNotLabels,
  OutreachMethods,
} from '../types';
import { DISABLED_OUTREACH_ERROR } from './errors';

export const useOutreach: UseOutreachHook = (candidate) => {
  const { dictionary } = useContext(LocalizationContext);
  const browserExtensionStatusQuery = useBrowserExtensionStatusQuery();
  const { status: linkedinStatus, isLoggedIn: isLinkedinLoggedIn } = useLinkedinConfig();
  const { status: linkedinInMailStatus, isLoggedIn: isLinkedinInMailLoggedIn } = useLinkedinInMailConfig();
  const { status: indeedStatus, isLoggedIn: isIndeedLoggedIn } = useIndeedConfig();
  const selectedCampaign = useContextSelector(SelectedCampaignContext, (state) => state.campaign);
  const { currentUser } = useContext(UserContext);
  const [locations] = useFiltersContext('locations');
  const isWerknlActive = useIsWerknlActive();
  const { enabledCvSources } = useCvSources();

  const disabledMessage: OutreachInformationWhyNotLabels = useMemo(() => {
    return {
      unavailableOnThisContactMethod: {
        label: dictionary.unavailableOnThisContactMethod,
      },
      browserExtensionNotInstalled: {
        label: dictionary.browserExtensionNotInstalled,
      },
      needsLoginOnJobboard: {
        label: dictionary.needsLoginOnJobboard,
      },
    };
  }, [dictionary]);

  const outreachInformationWhyNotEnabled: CandidateContextProps['outreachInformationWhyNotEnabled'] = useMemo(() => {
    const disabledError: CandidateContextProps['outreachInformationWhyNotEnabled']['disabledError'] = {
      linkedin: undefined,
      linkedinPro: undefined,
      indeed: undefined,
      email: undefined,
      whatsapp: undefined,
      call: undefined,
      werknl: undefined,
    };
    let whyLinkedinNotEnabled = undefined;
    let whyLinkedinProNotEnabled = undefined;
    let whyIndeedNotEnabled = undefined;
    let whyEmailNotEnabled = undefined;
    let whyWhatsappNotEnabled = undefined;
    let whyCallNotEnabled = undefined;
    let whyWerknlNotEnabled = undefined;

    // Check browser extensions
    if (Object.keys(browserExtensionStatusQuery.data).length === 0) {
      disabledError.linkedin = DISABLED_OUTREACH_ERROR.BROWSER_EXTENSION_NOT_INSTALLED;
      disabledError.indeed = DISABLED_OUTREACH_ERROR.BROWSER_EXTENSION_NOT_INSTALLED;

      whyLinkedinNotEnabled = disabledMessage.browserExtensionNotInstalled;
      whyIndeedNotEnabled = disabledMessage.browserExtensionNotInstalled;
    }

    // Needs login
    if (linkedinStatus === 'Logged Out') {
      disabledError.linkedin = DISABLED_OUTREACH_ERROR.LOGGED_OUT;
      whyLinkedinNotEnabled = { label: dictionary.clickToActivatePlatform(dictionary.linkedIn) };
    }

    if (linkedinInMailStatus === 'Logged Out') {
      disabledError.linkedin = DISABLED_OUTREACH_ERROR.LOGGED_OUT;
      whyLinkedinProNotEnabled = { label: dictionary.clickToActivatePlatform(dictionary.linkedInInMail) };
    }

    if (indeedStatus === 'Logged Out') {
      disabledError.indeed = DISABLED_OUTREACH_ERROR.LOGGED_OUT;
      whyIndeedNotEnabled = { label: dictionary.clickToActivatePlatform(dictionary.indeed) };
    }

    if (candidate?.activeJobBoard !== 'werknl' || !isWerknlActive) {
      disabledError.werknl = DISABLED_OUTREACH_ERROR.UNAVAILABLE;
      whyWerknlNotEnabled = { label: dictionary.clickToActivatePlatform(dictionary.Werknl) };
    }

    // Unavailable on this contact method
    if (!candidate?.contact_info.email) {
      disabledError.email = DISABLED_OUTREACH_ERROR.UNAVAILABLE;
      whyEmailNotEnabled = disabledMessage.unavailableOnThisContactMethod;
    }

    if (!candidate?.contact_info.phone) {
      disabledError.whatsapp = DISABLED_OUTREACH_ERROR.UNAVAILABLE;
      whyWhatsappNotEnabled = disabledMessage.unavailableOnThisContactMethod;
    }

    if (candidate?.activeJobBoard !== 'cvt') {
      disabledError.linkedin = DISABLED_OUTREACH_ERROR.UNAVAILABLE;
      whyLinkedinNotEnabled = disabledMessage.unavailableOnThisContactMethod;
    }

    if (candidate?.activeJobBoard !== 'indeed') {
      disabledError.indeed = DISABLED_OUTREACH_ERROR.UNAVAILABLE;
      whyIndeedNotEnabled = disabledMessage.unavailableOnThisContactMethod;
    }

    if (!candidate?.contact_info.phone) {
      disabledError.call = DISABLED_OUTREACH_ERROR.UNAVAILABLE;
      whyCallNotEnabled = disabledMessage.unavailableOnThisContactMethod;
    }

    return {
      disabledError,
      whyEmailNotEnabled,
      whyWhatsappNotEnabled,
      whyLinkedinNotEnabled,
      whyLinkedinProNotEnabled,
      whyIndeedNotEnabled,
      whyCallNotEnabled,
      whyWerknlNotEnabled,
    };
  }, [
    browserExtensionStatusQuery.data,
    linkedinStatus,
    linkedinInMailStatus,
    indeedStatus,
    candidate?.contact_info.email,
    candidate?.contact_info.phone,
    candidate?.activeJobBoard,
    dictionary,
  ]);

  const outreachMethods: CandidateContextProps['outreachMethods'] = useMemo(() => {
    return {
      shouldEnableCall: !!candidate?.contact_info.phone,
      shouldEnableEmail: !!candidate?.contact_info.email,
      shouldEnableIndeed: candidate?.activeJobBoard === 'indeed' && enabledCvSources?.includes('indeed'),
      shouldEnableWhatsapp: !!candidate?.contact_info.phone,
      shouldEnableLinkedin:
        candidate?.activeJobBoard === 'cvt' &&
        (enabledCvSources?.includes('linkedin') || enabledCvSources?.includes('cvt')),
      shouldEnableLinkedinPro: linkedinInMailStatus === 'Logged In' && candidate?.activeJobBoard === 'cvt',
      shouldEnableWerknl: candidate?.activeJobBoard === 'werknl' && enabledCvSources?.includes('werknl'),
    };
  }, [
    candidate?.contact_info.email,
    candidate?.contact_info.phone,
    candidate?.activeJobBoard,
    indeedStatus,
    linkedinStatus,
    isWerknlActive,
    enabledCvSources,
  ]);

  const isOutreachMethodConnected: CandidateContextProps['outreachMethods'] = useMemo(() => {
    return {
      ...outreachMethods,
      shouldEnableIndeed: isIndeedLoggedIn,
      shouldEnableLinkedin: isLinkedinLoggedIn,
      shouldEnableLinkedinPro: isLinkedinInMailLoggedIn,
      shouldEnableWerknl: isWerknlActive,
    };
  }, [outreachMethods, isIndeedLoggedIn, isLinkedinLoggedIn, isLinkedinInMailLoggedIn, isWerknlActive]);

  const availableOutreachMethods: Array<keyof OutreachMethods> = useMemo(() => {
    const availableMethods = Object.entries(outreachMethods)
      .filter(([, value]) => value === true)
      .map(([key]) => key as keyof OutreachMethods)
      .sort((a, b) => (isOutreachMethodConnected[b] ? 1 : 0) - (isOutreachMethodConnected[a] ? 1 : 0));

    return availableMethods;
  }, [outreachMethods, isOutreachMethodConnected]);

  const personalizationTokens: CandidateContextProps['personalizationTokens'] = useMemo(
    () => ({
      '{firstName}': candidate?.firstName || '',
      '{lastName}': candidate?.lastName || '',
      '{fullName}': [candidate?.firstName, candidate?.lastName].filter(Boolean).join(' ') || '',
      '{lastCompany}': candidate?.lastThreePositions?.[0]?.companyName || '',
      '{lastPosition}': candidate?.lastThreePositions?.[0]?.positionName || '',
      '{recruiterFullName}': [currentUser?.first_name, currentUser?.last_name].filter(Boolean).join(' '),
      '{nameJobboard}': candidate?.activeJobBoard ? jobBoardLabel(candidate.activeJobBoard) : '',
      '{nameVacancy}': selectedCampaign?.name || '',
      '{location}': locations.filter(Boolean) ? locations?.map((value) => value.key).join(', ') : '',
    }),
    [
      candidate?.firstName,
      candidate?.lastName,
      candidate?.lastThreePositions?.[0],
      currentUser?.first_name,
      currentUser?.last_name,
    ],
  );

  return useMemo(
    () => ({
      outreachMethods,
      personalizationTokens,
      outreachInformationWhyNotEnabled,
      availableOutreachMethods,
    }),
    [
      JSON.stringify(outreachMethods),
      JSON.stringify(availableOutreachMethods),
      JSON.stringify(personalizationTokens),
      JSON.stringify(outreachInformationWhyNotEnabled),
    ],
  );
};

type UseOutreachHook = (candidate: Candidate | undefined) => {
  outreachMethods: OutreachMethods;
  availableOutreachMethods: Array<keyof OutreachMethods>;
  personalizationTokens: Record<OutreachTokenKey, string>;
  outreachInformationWhyNotEnabled: {
    whyLinkedinNotEnabled: OutreachInformationWhyNotLabel | undefined;
    whyLinkedinProNotEnabled: OutreachInformationWhyNotLabel | undefined;
    whyIndeedNotEnabled: OutreachInformationWhyNotLabel | undefined;
    whyEmailNotEnabled: OutreachInformationWhyNotLabel | undefined;
    whyWhatsappNotEnabled: OutreachInformationWhyNotLabel | undefined;
    whyCallNotEnabled: OutreachInformationWhyNotLabel | undefined;
    whyWerknlNotEnabled: OutreachInformationWhyNotLabel | undefined;
    disabledError: CandidateContextProps['outreachInformationWhyNotEnabled']['disabledError'];
  };
};
