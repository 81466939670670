import { Pill } from '@recruitrobin/robin-theme/web-components';
import { useStyles } from './InboxConversationListFilters.styles';
import { InboxFilters } from '../../CentralInbox.types';
import { useInboxStore } from 'stores/inbox/inbox.store';

export const InboxConversationListFilters = () => {
  const classes = useStyles();
  const selectedFilter = useInboxStore((state) => state.selectedFilter);
  const setSelectedFilter = useInboxStore((state) => state.setSelectedFilter);

  return (
    <div css={classes.root}>
      <Pill
        style="icon-removable"
        padding="text"
        label="All"
        type={selectedFilter === InboxFilters.ALL ? 'default' : 'subtle-lighter'}
        onClick={() => setSelectedFilter(InboxFilters.ALL)}
      />
      <Pill
        style="icon-removable"
        padding="text"
        label="Unread"
        type={selectedFilter === InboxFilters.UNREAD ? 'default' : 'subtle-lighter'}
        onClick={() => setSelectedFilter(InboxFilters.UNREAD)}
      />
      <Pill
        style="icon-removable"
        padding="text"
        label="Sent"
        type={selectedFilter === InboxFilters.SENT ? 'default' : 'subtle-lighter'}
        onClick={() => setSelectedFilter(InboxFilters.SENT)}
      />
    </div>
  );
};

export default InboxConversationListFilters;
